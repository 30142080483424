<template>
  <div class="customer main-content" v-if="selectedCustomer">
    <el-row :gutter="40">
      <el-col :span="18">
        <h2>
          {{ selectedCustomer.firstName }} {{ selectedCustomer.lastName }}
        </h2>
      </el-col>
      <el-col :span="6">
        <span>ID {{ selectedCustomer._id }}</span>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="Details">
        <CustomerDetails />
      </el-tab-pane>
      <el-tab-pane label="Anfragen">
        <CustomerRequests />
      </el-tab-pane>
      <el-tab-pane label="Fahrzeuge">
        <CustomerCars />
      </el-tab-pane>
      <el-tab-pane label="Passwort zurücksetzen">
        <CustomerPassword />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CustomerDetails from "./CustomerDetails.vue";
import CustomerCars from "./CustomerCars.vue";
import CustomerPassword from "./CustomerPassword.vue";
import CustomerRequests from "./CustomerRequests.vue";

export default {
  name: "User",
  components: {
    CustomerDetails,
    CustomerCars,
    CustomerPassword,
    CustomerRequests,
  },
  data() {
    return {
      activeTab: "",
      editCustomer: null,
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
  },
  computed: {
    ...mapGetters({
      customers: "customers/customers",
      selectedCustomer: "customers/selectedCustomer",
    }),
  },
  mounted() {
    console.log("CUSTOMER MOUNTED");
    console.log(this.$route.params.id);
    this.$store.commit(
      "customers/SET_SELECTED_CUSTOMER",
      this.customers.find((o) => o._id === this.$route.params.id)
    );
    this.$store.dispatch("requests/getByCustomer", this.$route.params.id);
  },
};
</script>
