<template>
  <div class="requests main-content">
    <el-row align="bottom">
      <el-col :span="18">
        <h2>Poolanfragen Marktplatz</h2>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="Suchen..." v-model="searchString">
          <template #suffix>
            <i class="el-input__icon el-icon-search"></i>
          </template>
        </el-input>
      </el-col>
    </el-row>
    <el-table
      :data="poolRequestsByStatus('REQUESTED')"
      @row-click="onRowClick">
      <el-table-column
        prop="created"
        label="Datum"
        width="180">
      </el-table-column>
      <el-table-column
        prop="manufacturer"
        label="Hersteller"
        width="160">
      </el-table-column>
      <el-table-column
        prop="car"
        label="Modell"
        width="120">
      </el-table-column>
      <el-table-column
        prop="firstRegistration"
        label="Erstzulassung"
        width="120">
      </el-table-column>
      <el-table-column
        prop="damageLevel"
        label="Schadenstufe"
        width="120">
      </el-table-column>
      <el-table-column
        label="Fotos">
        <template #default="scope">
          <div class="requests-list-item-images">
             <el-popover
              v-for="(img, index) in scope.row.images"
              :key="index"
              placement="bottom"
              :width="500"
              trigger="hover"
              content="test">
              <template #reference>
                <el-image
                  :src="img"
                  fit="cover">
                </el-image>
              </template>
              <template #default>
                <el-image
                  :src="img"
                  fit="cover">
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="Kunden-Überschrift">
      </el-table-column>
      <el-table-column
        prop="customer.name"
        label="Kunde">
      </el-table-column>
      <el-table-column
        prop="fiascoId"
        label="FIASCO Nr."
        width="120">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DirectRequestsInbox',
  data() {
    return {
      test: [],
      searchString: ''
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request', params: { id: row.fiascoId} })
    },
    goDetails (row) {
      console.log(row)
      this.$router.push({name: 'Request', params: { id: row.fiascoId} })
    }
  },
  computed: {
    ...mapGetters({
      requests: 'requests/requests',
      poolRequestsByStatus: 'requests/poolRequestsByStatus',
    }),
  },
}
</script>
