import axios from 'axios'

const state = {
  verificationCodes: []
}

// getters
const getters = {
  verificationCodes: state => state.verificationCodes
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseUrl + 'verification-codes', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('update verification-code')
    return axios.put(context.rootGetters.API.baseURL + 'verification-codes/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update verification-code')
    return axios.patch(context.rootGetters.API.baseURL + 'verification-codes/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL (state, data) {
    state.verificationCodes = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
