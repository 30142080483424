<script>
import { Doughnut } from 'vue3-chart-v2'

export default {
  name: 'DonutChart',
  extends: Doughnut,
  props: {
    chartdata: Object
  },
  mounted () {
    this.renderChart(this.chartdata, {
      elements: {
        arc: {
          borderWidth: 0
        }
      },
      legend: {
        display: false
      },
      segmentShowStroke: false,
      borderColor: '#ff3333',
    })
  }
}
</script>