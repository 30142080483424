<template>
  <div class="requests main-content">
    <el-row align="bottom">
      <el-col :span="18">
        <h2>Anfragen</h2>
      </el-col>
      <el-col :span="6">
        <el-input placeholder="Suchen..." v-model="searchString">
          <template #suffix>
            <i class="el-input__icon el-icon-search"></i>
          </template>
        </el-input>
      </el-col>
    </el-row>
    <el-table
      :data="customerRequests"
      @row-click="onRowClick">
      <el-table-column
        prop="createdAt"
        label="Datum">
        <template #default="scope">
          <div>
            <strong>{{scope.row.title}}</strong>
          </div>
          {{formatDate(scope.row.createdAt)}}
        </template>
      </el-table-column>
      <el-table-column
        prop="car.manufacturer"
        label="Hersteller"
        width="160">
      </el-table-column>
      <el-table-column
        prop="car.model"
        label="Modell"
        width="120">
      </el-table-column>
      <el-table-column
        prop="car.firstRegistration"
        label="Erstzulassung"
        width="120">
      </el-table-column>
      <el-table-column
        prop="status"
        label="Status"
        width="180">
        <template #default="scope">
          {{requestStatusName(scope.row.status)}}
        </template>
      </el-table-column>
      <!--el-table-column
        prop="damageLevel"
        label="Schadenstufe"
        width="120">
      </el-table-column-->
      <el-table-column
        label="Fotos">
        <template #default="scope">
          <div class="requests-list-item-images">
             <el-popover
              v-for="(img, index) in scope.row.images"
              :key="index"
              placement="bottom"
              :width="500"
              trigger="hover"
              content="test">
              <template #reference>
                <el-image
                  :src="API.imageUrl + 'requests/' + img"
                  fit="cover">
                </el-image>
              </template>
              <template #default>
                <el-image
                  :src="API.imageUrl + 'requests/' + img"
                  fit="cover">
                </el-image>
              </template>
            </el-popover>
            <el-popover
              v-for="(img, index) in scope.row.s3Images"
              :key="index"
              placement="bottom"
              :width="320"
              trigger="hover"
              content="test">
              <template #reference>
                <S3Image
                  :image="img"
                  class="request-table-image" />
              </template>
              <template #default>
                <S3Image
                  :image="img"
                  class="request-table-popup-image" />
              </template>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateDisplayMixin from '../mixins/dateDisplay'

export default {
  name: 'DirectRequestsInbox',
  data() {
    return {
      test: [],
      searchString: ''
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request', params: { id: row._id} })
    }
  },
  computed: {
    ...mapGetters({
      API: 'API',
      customerRequests: 'requests/customerRequests',
      requestStatusName: 'requests/requestStatusName',
    }),
  },
}
</script>
