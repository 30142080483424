<template>
  <div class="role main-content" v-if="editRole">
     <el-row :gutter="40"> 
      <el-col :span="18" >
        <h2 >{{editRole.name}}</h2>
      </el-col>
      <el-col :span="6" >
        <span>ID {{editRole.id}}</span>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab">
    
      <el-tab-pane label="Details">
        <el-form class="request-form" ref="form" :model="editRole" label-width="160px">
          <el-form-item label="Name">
            <el-input v-model="editRole.name"></el-input>
          </el-form-item>

          <el-form-item label="FIASCO-Rolle">
              <el-checkbox
                v-model="editRole.isFiasco">
              </el-checkbox>
          </el-form-item>

          <el-form-item label="Direktanfragen">
            <el-checkbox-group v-model="editRole.rights">
              <el-checkbox
                v-for="item in userRightsByGroup('DIRECT_REQUESTS')"
                :key="item.key"
                :label="item.key"
                :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
                @change="onChange()">
                {{item.displayName}}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="Schnellanfragen">
            <el-checkbox-group v-model="editRole.rights">
            <el-checkbox
              v-for="item in userRightsByGroup('QUICK_REQUESTS')" 
              :key="item.key"
              :label="item.key"
              :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
              @change="onChange()">
              {{item.displayName}}
            </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="Reklamationen">
            <el-checkbox-group v-model="editRole.rights">
            <el-checkbox
              v-for="item in userRightsByGroup('COMPLAINTS')" 
              :key="item.key"
              :label="item.key"
              :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
              @change="onChange()">
              {{item.displayName}}
            </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="Verwaltung">
            <el-checkbox-group v-model="editRole.rights">
            <el-checkbox
              v-for="item in userRightsByGroup('ADMINISTRATION')" 
              :key="item.key"
              :label="item.key"
              :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
              @change="onChange()">
              {{item.displayName}}
            </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          
          <el-form-item>
            <el-button type="primary" @click="updateRole()">Speichern</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Role',
  data() {
    return {
      activeTab: '',
      editRole: null
    }
  },
  methods: {
    getSelectedData () {
      if (this.$route.params.id) {
        this.editRole = JSON.parse(JSON.stringify(this.roles.find(o => o._id === this.$route.params.id)))
      }
    },
    updateRole () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('roles/update', this.editRole).then(() => {
        this.$store.dispatch('roles/getAll').then(() => {
          this.$store.commit('SET_BUSY', false)
        })
      })
    },
    onChange () {
      this.updateRole()
    },
  },
  computed: {
    ...mapGetters({
      roles: 'roles/roles',
      userRightsByGroup: 'users/rightsByGroup',
    }),
  },
  mounted () {
    console.log('ROLE MOUNTED')
    // 
    this.getSelectedData()
  }
}
</script>
