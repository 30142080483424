<template>
  <el-dialog
    title="Fahrzeugschein scannen"
    v-model="visible"
    width="600px"
    @close="onClose()"
  >
    <p v-if="scanDisabled">In diesem Status ist kein Scan mehr möglich.</p>
    <el-row v-if="!scanDisabled">
      <el-col :span="6">
        <img class="scan-registration-preview-image" :src="imageUrl" />
      </el-col>
      <el-col :span="18">
        <h3 v-if="scanResult.vin && !errorMsg">Daten übernehmen?</h3>
        <p v-if="errorMsg">{{ errorMsg }}</p>
        <el-button v-if="errorMsg" @click="cancel"> OK </el-button>

        <el-form
          class="request-form"
          ref="form"
          :model="editCar"
          label-width="140px"
          v-if="editCar && scanResult.vin && !errorMsg"
        >
          <el-form-item label="Erstzulassung">
            <el-input
              v-model="scanResult.firstRegistration"
              placeholder="JJJJ-MM-TT"
            ></el-input>
          </el-form-item>
          <el-form-item label="VIN">
            <el-input v-model="scanResult.vin"></el-input>
          </el-form-item>
          <el-form-item label="HSN TSN" v-if="scanResult.hsnTsn">
            <el-input v-model="scanResult.hsnTsn"></el-input>
          </el-form-item>
          <el-form-item label="Nummernschild" v-if="scanResult.numberPlate">
            <el-input v-model="scanResult.numberPlate"></el-input>
          </el-form-item>
          <el-form-item label="Vorname Halter" v-if="scanResult.firstName">
            <el-input v-model="scanResult.firstName"></el-input>
          </el-form-item>
          <el-form-item label="Nachname Halter" v-if="scanResult.lastName">
            <el-input v-model="scanResult.lastName"></el-input>
          </el-form-item>
          <el-form-item label="Adresse" v-if="scanResult.address">
            <el-input v-model="scanResult.address"></el-input>
          </el-form-item>
          <el-form-item label="PLZ" v-if="scanResult.zip">
            <el-input v-model="scanResult.zip"></el-input>
          </el-form-item>
          <el-form-item label="Ort" v-if="scanResult.city">
            <el-input v-model="scanResult.city"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancel"> Abbrechen </el-button>
            <el-button type="primary" @click="update"> Speichern </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "ScanRegistrationPopup",
  props: {
    car: Object,
  },
  data() {
    return {
      email: "",
      phoneNumber: "",
      visible: false,
      editCar: null,
      imageUrl: null,
      base64: null,
      scanResult: {},
    };
  },
  methods: {
    getBlob() {
      this.errorMsg = null;
      this.scanResult = {};
      this.$store.dispatch("getS3Image", this.scanImage).then((res) => {
        this.imageUrl = res;
        this.$store.commit("cars/SET_SCAN_IMAGE", null);
        var that = this;
        fetch(this.imageUrl)
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            var reader = new FileReader();
            reader.onload = (e) => {
              // final result
              that.base64 = e.target.result;
              that.scanK();
            };
            reader.readAsDataURL(blob);
          });
      });
    },
    update() {
      this.$store.commit("SET_BUSY", true);
      if (this.scanResult.firstRegistration) {
        this.editCar.firstRegistration = this.scanResult.firstRegistration;
      }
      if (this.scanResult.numberPlate) {
        this.editCar.numberPlate = this.scanResult.numberPlate;
      }
      if (this.scanResult.vin) {
        this.editCar.vin = this.scanResult.vin;
      }
      if (this.scanResult.hsnTsn) {
        this.editCar.hsnTsn = this.scanResult.hsnTsn;
      }
      if (this.scanResult.firstName) {
        this.editCar.owner.firstName = this.scanResult.firstName;
      }
      if (this.scanResult.lastName) {
        this.editCar.owner.lastName = this.scanResult.lastName;
      }
      if (this.scanResult.address) {
        this.editCar.owner.address = this.scanResult.address;
      }
      if (this.scanResult.zip) {
        this.editCar.owner.zip = this.scanResult.zip;
      }
      if (this.scanResult.city) {
        this.editCar.owner.city = this.scanResult.city;
      }
      this.$store.dispatch("cars/update", this.editCar).then(() => {
        this.getSelectedData();
        this.$store.commit("SET_BUSY", false);
        this.visible = false;
        this.$emit("carupdated");
        this.$store.commit("cars/SET_SCAN_REGISTRATION_VISIBLE", false);
      });
    },
    getSelectedData() {
      if (this.car) {
        console.log(this.car);
        this.editCar = JSON.parse(JSON.stringify(this.car));
      }
    },
    onUploadLogoSuccess(response) {
      console.log(response);
      this.editCar.image = response;
      this.update();
    },
    cancel() {
      this.visible = false;
      this.onClose();
    },
    onClose() {
      this.$store.commit("cars/SET_SCAN_REGISTRATION_VISIBLE", false);
    },
    scanK() {
      this.$store.commit("SET_BUSY", true);
      let fd = new FormData();
      let url = "https://api.fahrzeugschein-digital.de/api/v1/zb1";
      fd.append("image", this.base64.replace("data:image/jpeg;base64,", ""));
      fd.append("api_token", "aL0gDPCRpHIQsGFnrBuznOzpDVhnXzJe");
      axios
        .post(url, fd)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          if (res.data) {
            console.log("SCAN DONE");
            if (res.data.datum_erstzulassung_lang) {
              if (res.data.datum_erstzulassung_lang.length > 0) {
                if (res.data.datum_erstzulassung_lang[0].probability > 0.8) {
                  this.scanResult.firstRegistration =
                    res.data.datum_erstzulassung_lang[0].value;
                  console.log(
                    `scanK: firstRegistrationDate seems plausible: ${this.scanResult.firstRegistration}`
                  );
                }
              }
            }
            if (res.data.fin) {
              if (res.data.fin.length > 0) {
                if (res.data.fin[0].probability > 0.9) {
                  this.scanResult.vin = res.data.fin[0].value;
                  console.log(
                    `scanK: VIN seems plausible: ${this.scanResult.vin}`
                  );
                }
              }
            }
            if (res.data.hsn_tsn) {
              if (res.data.hsn_tsn.length > 0) {
                if (res.data.hsn_tsn[0].probability > 0.9) {
                  this.scanResult.hsnTsn = res.data.hsn_tsn[0].value;
                  console.log(
                    `scanK: hsnTsn seems plausible: ${this.scanResult.hsnTsn}`
                  );
                }
              }
            }
            if (res.data.vorname) {
              if (res.data.vorname.length > 0) {
                if (res.data.vorname[0].probability > 0.9) {
                  this.scanResult.firstName = res.data.vorname[0].value;
                  console.log(
                    `scanK: firstName seems plausible: ${this.scanResult.firstName}`
                  );
                }
              }
            }
            if (res.data.name) {
              if (res.data.name.length > 0) {
                if (res.data.name[0].probability > 0.9) {
                  this.scanResult.lastName = res.data.name[0].value;
                  console.log(
                    `scanK: lastName seems plausible: ${this.scanResult.lastName}`
                  );
                }
              }
            }
            if (res.data.ort) {
              if (res.data.ort.length > 0) {
                if (res.data.ort[0].probability > 0.9) {
                  this.scanResult.city = res.data.ort[0].value;
                  console.log(
                    `scanK: city seems plausible: ${this.scanResult.city}`
                  );
                }
              }
            }
            if (res.data.plz) {
              if (res.data.plz.length > 0) {
                if (res.data.plz[0].probability > 0.9) {
                  this.scanResult.zip = res.data.plz[0].value;
                  console.log(
                    `scanK: zip seems plausible: ${this.scanResult.zip}`
                  );
                }
              }
            }
            if (res.data.strasse) {
              if (res.data.strasse.length > 0) {
                if (res.data.strasse[0].probability > 0.9) {
                  this.scanResult.address = res.data.strasse[0].value;
                  console.log(
                    `scanK: address seems plausible: ${this.scanResult.address}`
                  );
                }
              }
            }
            if (res.data.kennzeichen) {
              if (res.data.kennzeichen.length > 0) {
                if (res.data.kennzeichen[0].probability > 0.8) {
                  let rawNumberPlate = res.data.kennzeichen[0].value;
                  let position = rawNumberPlate.search(/\d/);
                  let formatted =
                    rawNumberPlate.substring(0, position) +
                    " " +
                    rawNumberPlate.substring(position);
                  this.scanResult.numberPlate = formatted; // res.data.kennzeichen[0].value
                  console.log(
                    `scanK: licensePlate seems plausible: ${this.scanResult.numberPlate}`
                  );
                }
              }
            }

            console.log("scanK: scanResult =", this.scanResult);
            if (!this.scanResult.vin || this.scanResult.vin?.length != 17) {
              console.log("scanK: no scanResult");
              this.errorMsg = "Keine Daten erkannt.";
              this.$forceUpdate();
            }
          } else {
            this.errorMsg = "Konnte nicht scannen.";
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("SET_BUSY", false);
          this.errorMsg = "Konnte nicht scannen.";
        });
    },
  },
  computed: {
    scanDisabled() {
      if (
        this.selectedRequest.status === "FIASCO_CALCULATION_FEEDBACK" ||
        !this.selectedRequest.offer
      ) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters({
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      scanRegistrationVisible: "cars/scanRegistrationVisible",
      selectedRequest: "requests/selectedRequest",
      scanImage: "cars/scanImage",
      userStation: "stations/userStation",
      user: "users/user",
      stations: "stations/stations",
      customerCars: "cars/customerCars",
    }),
  },
  watch: {
    scanRegistrationVisible() {
      if (this.scanRegistrationVisible) {
        console.log("ScanRegistrationPopup");
        console.log(this.scanImage);
        this.visible = true;
        if (!this.scanDisabled) {
          this.getSelectedData();
          this.getBlob();
        }
      }
    },
  },
  mounted() {
    console.log("scan K mounted");
  },
};
</script>

