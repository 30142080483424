import axios from 'axios'

const state = {
  userId: null,
  user: null,
  selectedUser: null,
  loginError: null,
  stationUsers: [],
  users: [
    {
      id: '23456789',
      email: 'admin@fiasco.de',
      phone: '0179 1234455667',
      firstName: 'FIASCO',
      lastName: 'Admin',
      role: 'ADMIN',
      rights: [],
      team: 'FIASCO',
      image: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
    },
    {
      id: '114776',
      email: 'chef@werkstatt.de',
      phone: '0711 123345',
      firstName: 'Max',
      lastName: 'Werkstätter',
      role: 'GARAGE_ADMIN',
      rights: [],
      team: 'Autohaus Mustermann',
      image: 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
    }
  ],
  roles: [
    'ADMIN',
    'FIASCO_ADMIN',
    'FIASCO_EDITOR',
    'STATION_ADMIN',
    'STATION_WORKER',
  ],
  rights: []
  /*
  
  
  - Notizen der Fakturierung zur jeweiligen Parteien
  (ob der Kunde bezahlen muss noch oder der Kunde usw.)
  - 
  */
}

// getters
const getters = {
  user: state => state.user,
  users: state => state.users,
  stationUsers: state => state.stationUsers,
  loginError: state => state.loginError,
  userId: state => state.userId,
  selectedUser: state => state.selectedUser,
  colleagues: state => {
    let colleagues = []
    if (state.user.station) {
      colleagues = state.users.filter(o => o.station ? o.station._id === state.user.station : false)
    }
    return colleagues
  },
  rights: state => {
    let rights = []
    if (state.user.isFiasco) {
      rights = state.rights
    } else {
      rights = state.rights.filter(o => !o.fiascoOnly)
    }
    return rights
  },
  roles: state => state.roles,
  rightsByGroup: state => group => {
    let rights = []
    if (state.user.isFiasco) {
      rights = state.rights.filter(o => o.group === group)
    } else {
      rights = state.rights.filter(o => !o.fiascoOnly).filter(o => o.group === group)
    }
    return rights
  }
}

// actions
const actions = {
  getAll(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'user', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  getRights(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'user/rights', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_RIGHTS', response.data))
      .catch((error) => console.log(error))
  },
  getByStation(context, payload) {
    console.log('getByStation')
    return axios.get(context.rootGetters.API.baseUrl + 'user/station/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_STATION_USERS', response.data))
      .catch((error) => console.log(error))
  },
  getLogs(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'user/log')
      .then((response) => context.commit('SET_LOGS', response))
      .catch((error) => console.log(error))
  },
  login(context, payload) {
    return axios.post(context.rootGetters.API.baseUrl + 'user/auth',
      {
        email: payload.email,
        password: payload.password
      }
    ).then(response => {
      console.log('axios login resolved')
      console.log(response.data)
      let payload = {
        user: response.data.user,
        token: response.data.token
      }
      if (!response.data.success) {
        console.log('login error')
        context.commit('SET_LOGIN_ERROR', response.data.message)
      } else {
        console.log('login success')
        context.commit('LOGIN', payload.user)
        context.commit('SET_TOKEN', payload.token, { root: true })
        localStorage.setItem('fiasco-token', payload.token)
      }
      return response
    }).catch(error => {
      console.log(error)
      this.errored = true
    })
  },
  update(context, payload) {
    console.log('update user')
    return axios.patch(context.rootGetters.API.baseURL + 'user/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  updatePassword(context, payload) {
    console.log('updatePassword')
    return axios.patch(context.rootGetters.API.baseURL + 'user/pwd/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  uploadImage(context, payload) {
    console.log('uploadImage')
    return axios.post(
      context.rootGetters.API.baseURL + 'user/upload',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('UPLOADED')
    }).catch(error => {
      console.log(error)
    })
  },
  create(context, payload) {
    console.log('create user')
    console.log(context.rootGetters.API.baseUrl + 'user')
    return axios.put(context.rootGetters.API.baseUrl + 'user', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  delete(context, payload) {
    console.log('delete user')
    console.log(context.rootGetters.API.baseUrl + 'user')
    return axios.delete(context.rootGetters.API.baseUrl + 'user/' + payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  createLog(context, payload) {
    console.log('create log')
    console.log(payload)
    return axios.put(context.rootGetters.API.baseUrl + 'user/log', payload)
      .then((response) => context.dispatch('getLogs', response))
      .catch((error) => console.log(error))
  }
}

// mutations
const mutations = {
  LOGIN(state, payload) {
    console.log('LOGIN')
    state.userId = payload._id
    state.user = payload
    localStorage.setItem('fiasco-user', JSON.stringify(payload))
  },
  LOGOUT(state) {
    console.log('LOGOUT')
    state.userId = null
    state.user = null
    localStorage.removeItem('fiasco-token')
    localStorage.removeItem('fiasco-user')
  },
  SET_LOGIN_ERROR(state, data) {
    state.loginError = data
  },
  SET_USER_ID(state, data) {
    state.userId = data
  },
  SET_ALL(state, data) {
    state.users = data
  },
  SET_RIGHTS(state, data) {
    state.rights = data
  },
  SET_STATION_USERS(state, data) {
    state.stationUsers = data
  },
  SET_USER(state, data) {
    state.user = data
  },
  SET_SELECTED_USER(state, data) {
    state.selectedUser = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
