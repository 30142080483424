<template>
  <div class="chat">
    <!--h2>FIASCO Chat</h2-->
    <el-row class="chat-inner">
      <el-col :span="8" class="chat-topics">
        <perfect-scrollbar>
          <div
            class="chat-topic"
            v-for="item in requests"
            :key="item._id"
            :class="{ active: request ? item._id === request._id : false }"
            @click="onRequestClick(item._id)"
          >
            <div class="chat-topic-marker"></div>
            <div v-if="item.customer">
              <el-avatar
                v-if="!item.customer.s3Image"
                :src="API.imageUrl + 'customers/' + item.customer.image"
              ></el-avatar>
              <S3Avatar
                v-if="item.customer.s3Image"
                :image="item.customer.s3Image"
                :size="40"
              />
            </div>
            <div class="chat-topic-caption">
              <div class="chat-topic-caption-title">
                {{ item.title }}
              </div>
              <div class="chat-topic-caption-name" v-if="item.customer">
                {{ item.customer.firstName }}
              </div>
              <div class="chat-topic-caption-status">
                {{ requestStatusName(item.status) }}
              </div>
              <div class="chat-topic-caption-date" v-if="item.messages.length > 0">
                {{ formatDate(item.messages[item.messages.length - 1].deliveredAt) }}
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </el-col>
      <el-col :span="16" class="chat-history-outer">
        <div class="chat-history" v-if="request">
          <div class="chat-history-messages">
            <div class="chat-options">
              <el-radio v-model="viewMode" label="PUBLIC">Öffentlich</el-radio>
              <el-radio v-model="viewMode" label="INTERNAL">Intern</el-radio>
            </div>
            <perfect-scrollbar>
              <div
                class="chat-line"
                v-for="item in filteredMessages"
                :key="item._id"
                :class="item.type.toLowerCase()"
              >
                <div class="chat-line-user">
                  <span v-if="item.customer">
                    <el-avatar
                      v-if="!item.customer.s3Image"
                      :src="API.imageUrl + 'customers/' + item.customer.image"
                    ></el-avatar>
                    <S3Avatar
                      v-if="item.customer.s3Image"
                      :image="item.customer.s3Image"
                      :size="40"
                    />
                  </span>
                  <span v-if="item.user">
                    <el-avatar
                      v-if="!item.user.s3Image"
                      :src="API.imageUrl + 'users/' + item.user.image"
                    ></el-avatar>
                    <S3Avatar
                      v-if="item.user.s3Image"
                      :image="item.user.s3Image"
                      :size="40"
                    />
                  </span>
                  <span v-if="item.user" class="chat-line-user-name"
                    >{{ item.user.firstName }} {{ item.user.lastName }}</span
                  >
                  <span v-if="item.customer" class="chat-line-user-name"
                    >{{ item.customer.firstName }} {{ item.customer.lastName }}</span
                  >
                </div>
                {{ item.content }}
                <div class="chat-line-time">{{ formatDate(item.deliveredAt) }}</div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="chat-control" v-if="user.rights.includes('WRITE_CHAT')">
            <el-input
              type="textarea"
              placeholder="Nachricht schreiben..."
              v-model="messageInput"
              @keyup.enter.exact="sendMessage()"
            ></el-input>
            <el-button
              type="primary"
              icon="el-icon-s-promotion"
              circle
              @click="sendMessage()"
            ></el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "Chat",
  data() {
    return {
      request: null,
      viewMode: "PUBLIC",
      messageInput: "",
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    onRequestClick(id) {
      this.request = this.requests.find((o) => o._id === id);
    },
    sendMessage() {
      let id = this.request._id;
      let payload = {
        message: this.messageInput,
        type: this.viewMode,
        requestId: id,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/sendMessage", payload).then(() => {
        this.$store.dispatch("requests/getActive").then(() => {
          this.request = this.requests.find((o) => o._id === id);
          this.messageInput = "";
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
  },
  computed: {
    /*
    sortedRequests () {
      let tempRequests = JSON.parse(JSON.stringify(this.requests))
      return tempRequests.sort(function (a, b) {
        let dateA = a.messages(a.messages.length - 1).deliveredAt
        let dateB = b.messages(b.messages.length - 1).deliveredAt
        if (dateA > dateB) return 1
        if (dateA < dateB) return -1
      })
    },
    */
    filteredMessages() {
      if (this.viewMode === "INTERNAL") {
        return this.request.messages;
      }
      if (this.viewMode === "PUBLIC") {
        return this.request.messages.filter((o) => o.type === "PUBLIC");
      }
      return [];
    },
    ...mapGetters({
      API: "API",
      user: "users/user",
      userId: "users/userId",
      requestStatusName: "requests/requestStatusName",
      requests: "requests/requests",
    }),
  },
  watch: {
    requests: {
      deep: true,
      handler: function () {
        this.request = this.requests.find((o) => o._id === this.request._id);
      },
    },
  },
};
</script>
