<template>
  <div class="requests main-content">
    <div class="listview-header">
      <h2>Direktanfragen Eingang</h2>

      <div class="listview-header-tools">
        <el-select
          class="wide"
          v-model="selectedStation"
          v-if="user.isFiasco"
          value-key="_id"
        >
          <el-option class="select-avatars" label="Alle" value="ALL">
            Alle
          </el-option>
          <el-option
            class="select-avatars"
            v-for="item in stations"
            :key="item._id"
            :label="item.name + ' ' + item.city"
            :value="item._id"
          >
            <div class="select-avatars-item">
              <el-avatar
                v-if="!item.s3LogoImage"
                :src="API.imageUrl + 'stations/logos/' + item.logoImage"
              ></el-avatar>
              <S3Avatar
                v-if="item.s3LogoImage"
                :image="item.s3LogoImage"
                :size="40"
              />
              <div>
                <div>{{ item.name }}</div>
                <div class="sub">{{ item.city }}</div>
              </div>
            </div>
          </el-option>
        </el-select>

        <el-input placeholder="Suchen..." v-model="searchString" clearable>
          <template #suffix>
            <i class="el-input__icon el-icon-search"></i>
          </template>
        </el-input>
      </div>
    </div>

    <div class="table-pagination" v-if="searchString.length < 2">
      <el-pagination
        layout="prev, pager, next"
        :page-size="pageSize"
        @current-change="onPageChange"
        :page-count="Math.ceil(filteredRequests.length / pageSize)"
      ></el-pagination>

      <p>
        {{ filteredRequests.length }} insgesamt,
        <el-input-number
          v-model="pageSize"
          size="small"
          controls-position="right"
        />
        pro Seite
      </p>
    </div>

    <el-table
      :data="filteredRequests"
      :row-class-name="tableRowClass"
      @row-click="onRowClick"
    >
      <el-table-column
        prop="car.numberPlate"
        label="VIN u. Kennz."
        width="136"
        sortable
      >
        <template #default="scope">
          <strong v-if="scope.row.car">{{ scope.row.car.numberPlate }}</strong>
          <div v-if="scope.row.car">
            <span class="table-vin">{{ scope.row.car.vin }}</span>
            <span class="table-fiasco-id">{{ scope.row.fiascoId }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Angefragt von">
        <template #default="scope">
          <div v-if="scope.row.customer">
            {{ scope.row.customer.firstName }} {{ scope.row.customer.lastName }}
          </div>
          <div v-if="scope.row.customer" class="small">
            {{ scope.row.customer.email }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Fotos" width="150">
        <template #default="scope">
          <div class="requests-list-item-images" v-if="!busy">
            <el-popover
              v-for="(img, index) in scope.row.images"
              :key="index"
              placement="bottom"
              :width="500"
              trigger="hover"
              content="test"
            >
              <template #reference>
                <el-image :src="API.imageUrl + 'requests/' + img" fit="cover">
                </el-image>
              </template>
              <template #default>
                <el-image :src="API.imageUrl + 'requests/' + img" fit="cover">
                </el-image>
              </template>
            </el-popover>
            <el-popover
              v-for="(img, index) in scope.row.s3Images.slice(0, 2)"
              :key="index"
              placement="bottom"
              :width="320"
              trigger="hover"
              content="test"
            >
              <template #reference>
                <S3Image :image="img" class="request-table-image" />
              </template>
              <template #default>
                <S3Image :image="img" class="request-table-popup-image" />
              </template>
            </el-popover>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="Bauteil(e)">
        <template #default="scope">
          <div
            v-for="(item, index) in scope.row.damages"
            :key="index + 'dmg' + scope.row._id"
          >
            {{ damageLocationName(item.location) }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        v-if="user.isFiasco && selectedStation === 'ALL'"
        prop="customer.selectedStation"
        label="Werkstatt"
        sortable
      >
        <template #default="scope">
          <span v-if="scope.row.customer">
            {{
              stations.find((o) => o._id === scope.row.customer.selectedStation)
                ?.name
            }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="updatedAt" label="Datum" sortable>
        <template #default="scope">
          <div>{{ formatDate(scope.row.createdAt) }}</div>
          <div class="small">akt. {{ formatDate(scope.row.updatedAt) }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "DirectRequestsInbox",
  data() {
    return {
      test: [],
      searchString: "",
      selectedStation: "ALL",
      tablePage: 1,
      pageSize: 12,
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request", params: { id: row._id } });
    },
    tableRowClass({ row, rowIndex }) {
      let className = "";
      if (this.lastSelectedRequest) {
        if (row._id === this.lastSelectedRequest._id) {
          className = "last-selected";
        }
      }
      if (
        rowIndex < this.tablePage * this.pageSize - this.pageSize ||
        rowIndex > this.tablePage * this.pageSize
      ) {
        className += "hidden-row";
      }
      return className;
    },
    onPageChange(page) {
      this.tablePage = page;
    },
  },
  computed: {
    filteredRequests() {
      let filtered;
      let customerFiltered;
      let numberPlateFiltered;

      if (this.selectedStation === "ALL") {
        filtered = this.requestsByStatus("REQUESTED");
      } else {
        filtered = this.requestsByStatus("REQUESTED").filter((o) =>
          o.customer
            ? o.customer.selectedStation === this.selectedStation
            : false
        );
      }
      if (this.searchString.length > 2) {
        customerFiltered = filtered.filter((o) => {
          if (o.customer && o.fiascoId) {
            if (
              o.fiascoId.toLowerCase().includes(this.searchString.toLowerCase())
            ) {
              return true;
            } else {
              let combinedName =
                o.customer.firstName + " " + o.customer.lastName;
              return combinedName
                .toLowerCase()
                .includes(this.searchString.toLowerCase());
            }
          } else {
            return o.fiascoId
              .toLowerCase()
              .includes(this.searchString.toLowerCase());
          }
        });

        numberPlateFiltered = filtered.filter((o) => {
          if (o.car) {
            return (
              o.car.numberPlate
                ?.toLowerCase()
                .includes(this.searchString.toLowerCase()) ||
              o.car.vin.toLowerCase().includes(this.searchString.toLowerCase())
            );
          } else {
            return false;
          }
        });
        return [...numberPlateFiltered, ...customerFiltered];
      }
      return filtered;
    },
    ...mapGetters({
      API: "API",
      busy: "busy",
      user: "users/user",
      stations: "stations/stations",
      requests: "requests/requests",
      lastSelectedRequest: "requests/lastSelectedRequest",
      damageLocationName: "requests/damageLocationName",
      requestsByStatus: "requests/requestsByStatus",
    }),
  },
};
</script>
