<template>
  <div class="stations main-content">
    <el-row :gutter="40"> 
      <el-col :span="18">
        <h2 class="page-title">Werkstätten</h2>
      </el-col>
      <el-col :span="6" class="page-header-tools">
        <div class="page-header-tools">
          <!--el-popover
            placement="bottom-end"
            :width="200"
            trigger="click"
          >
            <template #reference>
              <el-button icon="el-icon-more" circle type="primary"></el-button>
            </template>
            <div class="header-tools-profile-menu">
              <div class="header-tools-profile-item" @click="createDialogVisible = true">
                <span>Neue Werkstatt</span>
              </div>
            </div>
          </el-popover-->
          <el-button icon="el-icon-plus" circle  @click="createDialogVisible = true"></el-button>
        </div>
      </el-col>
    </el-row>
   
    <el-table
      :data="stations"
      @row-click="onRowClick">
      <el-table-column
        label="Logo"
        width="160">
        <template #default="scope">
          <el-image v-if="!scope.row.s3LogoImage" class="stations-list-logo" :src="API.imageUrl + 'stations/logos/' + scope.row.logoImage" fit="contain"></el-image>
          <S3Image v-if="scope.row.s3LogoImage" class="stations-list-logo" :image="scope.row.s3LogoImage" />
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="Name">
      </el-table-column>
      <el-table-column
        prop="zip"
        label="PLZ">
      </el-table-column>
      <el-table-column
        prop="city"
        label="Stadt">
      </el-table-column>
      <el-table-column
        prop="address"
        label="Adresse">
      </el-table-column>
      <el-table-column
        prop="id"
        label="ID"
        width="120">
      </el-table-column>
    </el-table>

    <el-dialog
      title="Neue Werkstatt"
      v-model="createDialogVisible"
      width="560px">
      <el-form class="create-form" ref="form" :model="editItem" label-width="160px">
        <el-form-item label="Name">
          <el-input v-model="editItem.name"></el-input>
        </el-form-item>
        <el-form-item label="Stadt">
          <el-input v-model="editItem.city"></el-input>
        </el-form-item>
        <el-form-item label="PLZ">
          <el-input v-model="editItem.zip"></el-input>
        </el-form-item>
        <el-form-item label="Adresse">
          <el-input v-model="editItem.address"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="create()">Erzeugen</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Stations',
  data() {
    return {
      createDialogVisible: false,
      editItem: {
        name: '',
        city: '',
        zip: '',
        address: ''
      }
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Station', params: {id: row._id}})
    },
    create () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('stations/create', this.editItem).then(() => {
        this.createDialogVisible = false
        this.$store.commit('SET_BUSY', false)
        this.$store.dispatch('stations/getAll')
      })
    }
  },
  computed: {
    ...mapGetters({
      API: 'API',
      stations: 'stations/stations'
    }),
  },
}
</script>
