<template>
  <div class="roles main-content">
    <el-row :gutter="40"> 
      <el-col :span="18">
        <h2 class="page-title">Rollen</h2>
        <p class="page-title-sub">(Vorlagen für Benutzerrechte)</p>
      </el-col>
      <el-col :span="6" class="page-header-tools">
        <div class="page-header-tools">
          <!--el-popover
            placement="bottom-end"
            :width="200"
            trigger="click"
            v-model:visible="actionsMenuVisible"
          >
            <template #reference>
              <el-button icon="el-icon-more" circle type="primary"></el-button>
            </template>
            <div class="header-tools-profile-menu">
              <div class="header-tools-profile-item" @click="createDialogVisible = true">
                <span>Neue Rolle</span>
              </div>
            </div>
          </el-popover-->
          <el-button icon="el-icon-plus" circle  @click="createDialogVisible = true"></el-button>
        </div>
      </el-col>
    </el-row>
    <el-table
      :data="roles"
      @row-click="onRowClick">
      <el-table-column
        prop="name"
        label="Rolle"
        width="220">
      </el-table-column>
      <el-table-column
        label="Anzahl Rechte">
        <template #default="scope">
          <span>{{scope.row.rights.length}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Fiasco-Rolle"
        width="160">
        <template #default="scope">
          <i v-if="scope.row.isFiasco" class="el-icon-check"></i>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      title="Neue Rolle"
      v-model="createDialogVisible"
      width="560px">
      <el-form class="create-form" ref="form" :model="editItem" label-width="160px">
        <el-form-item label="Name">
          <el-input v-model="editItem.name"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="create()">Erzeugen</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Roles',
  data() {
    return {
      actionsMenuVisible: false,
      createDialogVisible: false,
      editItem: {
        name: ''
      }
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Role', params: {id: row._id}})
    },
    create () {
      console.log('create')
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('roles/create', this.editItem).then(() => {
        this.$store.commit('SET_BUSY', false)
        this.createDialogVisible = false
        this.$store.dispatch('roles/getAll')
      })
    }
  },
  computed: {
    ...mapGetters({
      API: 'API',
      roles: 'roles/roles',
    }),
  },
}
</script>
