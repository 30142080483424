<template>
  <div class="request main-content" v-if="selectedRequest">
    <div class="request-header">
      <el-button-group>
        <el-button icon="el-icon-back" @click="goBack()"></el-button>
      </el-button-group>
      <div class="request-header-caption">
        <h2 v-if="selectedRequest.car">
          {{ selectedRequest.car.numberPlate }}
        </h2>

        <div class="request-header-caption-sub">
          <div class="request-header-caption-fiasco-id">
            <span class="request-header-caption-fiasco-id-f">F</span>
            <span class="request-header-caption-fiasco-id-value">
              {{ selectedRequest.fiascoId }}
            </span>
          </div>
          <div
            class="request-header-caption-sub-value"
            v-if="selectedRequest.car"
          >
            {{ selectedRequest.car.vin }}
          </div>
          <div
            class="request-header-caption-sub-value"
            v-if="selectedRequest.customer"
          >
            {{ selectedRequest.customer.firstName }}
            {{ selectedRequest.customer.lastName }}
          </div>
        </div>
      </div>
    </div>

    <RequestStatusSwitch
      :editRequest="selectedRequest"
      @select="onStatusSelect"
      v-if="selectedRequest.status"
    />

    <el-alert v-if="error" :title="error" type="error" show-icon> </el-alert>

    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="Anfrage-Details">
        <br />
        <RequestDetails v-if="true" />
      </el-tab-pane>
      <el-tab-pane label="Kalkulation" v-if="hasCalculation(selectedRequest)">
        <RequestCalculation />
      </el-tab-pane>
      <el-tab-pane label="Angebotdetails" v-if="selectedRequest.offer">
        <br />
        <RequestOffer />
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="Achtung" v-model="alertDialogVisible" width="480px">
      <p>
        {{ alertDialogInfo }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import RequestDetails from "./RequestDetails.vue";
import RequestCalculation from "./RequestCalculation.vue";
import RequestOffer from "./RequestOffer.vue";
import RequestStatusSwitch from "./RequestStatusSwitch.vue";

import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "Request",
  components: {
    RequestDetails,
    RequestCalculation,
    RequestOffer,
    RequestStatusSwitch,
  },
  data() {
    return {
      activeTab: "",
      editRequest: null,
      editOffer: null,
      error: null,
      alertDialogVisible: false,
      alertDialogInfo: "",
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    goBack() {
      this.$router.go(-1);
    },
    onStatusSelect(value) {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.editRequest.status = value;
      this.error = null;

      console.log("onStatusSelect " + value);

      if (value === "RESET") {
        this.resetRequestStatus();
        return;
      }
      if (
        this.editRequest.status === "QUALIFIED" &&
        this.editRequest.type === "DIRECT"
      ) {
        this.createOffer();
        return;
      }
      if (this.editRequest.status === "FIASCO_CALCULATING") {
        this.sendToFiascoAudatex();
        return;
      }
      if (this.editRequest.status === "FIASCO_CALCULATION_SENT") {
        this.getFiascoAudatexCalculation();
        return;
      }
      this.updateRequestStatus(value);
    },
    createOffer() {
      console.log("CREATE OFFER for request");
      console.log(this.user.station);
      let station = this.stations.find(
        (o) => o._id == this.selectedRequest.customer.selectedStation
      );
      let baseCalculation = station.baseCalculation;
      console.log(baseCalculation);

      if (baseCalculation.workPackagesPerHour === 0) {
        baseCalculation.workPackagesPerHour = 12;
      }

      let payload = {
        request: this.editRequest._id,
        station: station._id,
        status: "QUALIFIED",
        calculation: baseCalculation,
        contactPerson: this.user._id,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("offers/create", payload).then((res) => {
        // ATTACH offer if direct request (only one offer possible)
        if (this.editRequest.type === "DIRECT") {
          this.editRequest.offer = res.data;
          this.$store.dispatch("requests/update", this.editRequest).then(() => {
            this.$store.dispatch("offers/getAll").then(() => {
              this.updateRequestStatus(this.editRequest.status);
            });
          });
        } else {
          this.$store.dispatch("offers/getAll").then(() => {
            this.updateRequestStatus(this.editRequest.status);
          });
        }
      });
    },
    updateRequestStatus(status) {
      if (status == "FIASCO_CALCULATION_REQUESTED") {
        if (!this.requestReadyForFiasco(this.selectedRequest)) {
          this.alertDialogVisible = true;
          this.alertDialogInfo =
            "FIASCO benötigt VIN sowie Haltername und -vorname.";
          return;
        }
      }
      if (status == "CALCULATION_DONE") {
        if (
          (!this.editOffer.calculation.useLumpSumPrice &&
            this.editOffer.calculation.totalPrice == 0) ||
          (this.editOffer.calculation.useLumpSumPrice &&
            this.editOffer.calculation.lumpSumPrice == 0)
        ) {
          this.alertDialogVisible = true;
          this.alertDialogInfo =
            "Die Kalkulation hat noch keine Kosten. Erst kalkulieren oder Fixpreis angeben.";
          return;
        }
      }
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.editRequest.status = status;
      let statusHistory = {
        status: this.editRequest.status,
        user: this.userId,
        changedAt: new Date(),
      };
      this.editRequest.statusHistory.unshift(statusHistory);
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/update", this.editRequest).then(() => {
        this.$store.dispatch("requests/getActive").then(() => {
          console.log("GO TO: " + this.requestTargetRoute(status));
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    resetRequestStatus() {
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.editRequest.status = "REQUESTED";
      this.editRequest.statusHistory = [];
      this.editRequest.messages = [];
      this.editRequest.offer = "";
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/update", this.editRequest).then(() => {
        this.$store.dispatch("requests/getActive").then(() => {
          this.$router.push({
            name: this.requestTargetRoute(this.editRequest.status),
          });
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getFiascoAudatexCalculation() {
      console.log("getFiascoAudatexCalculation");
      this.$store.commit("SET_BUSY", true);
      let id = this.editOffer.audatexFiascoTaskId;

      // TODO: Do not send credentials. Send the type of request (fiasco or station) instead.
      let payload = {
        taskId: id,
        user: this.API.fiascoAudatexUser,
        password: this.API.fiascoAudatexPassword,
      };
      console.log(payload);
      this.$store
        .dispatch("requests/getAudatexTaskById", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          if (res.success) {
            if (res.task.calculation) {
              this.editOffer.calculation = {
                ...this.editOffer.calculation,
                ...res.task.calculation,
              };
              console.log(this.editOffer.calculation);
              // this.editOffer.calculation.totalPrice = this.sum(this.editOffer.calculation)
              this.$store.dispatch("offers/update", this.editOffer).then(() => {
                this.$store.dispatch("offers/getAll").then(() => {
                  this.$store.commit("SET_BUSY", false);
                  this.updateRequestStatus(this.editRequest.status);
                  // TODO: chk if async needed
                  this.sendToStationAudatex();
                });
              });
            } else {
              this.error =
                "Konnte nicht importieren. Audatex-Task hat keine Kalkulation.";
              console.log("HAS NO CALCULATION");
              this.$store.commit("SET_BUSY", false);
            }
          } else {
            this.error = "Konnte nicht importieren. Calculation-Bridge-Fehler.";
            console.log(`CALC BRIDGE ERROR: ${res.message}`);
          }
        });
    },
    sendToFiascoAudatex() {
      console.log("sendToFiascoAudatex E");

      this.$store.commit("SET_BUSY", true);
      let payload = {
        user: this.API.fiascoAudatexUser,
        password: this.API.fiascoAudatexPassword,
        task: {
          fiascoId: this.selectedRequest.fiascoId,
          vin: this.selectedRequest.car.vin,
          licensePlate: this.selectedRequest.car.numberPlate
            ? this.selectedRequest.car.numberPlate
            : "unbekannt",
          registrationDate: this.selectedRequest.car.firstRegistration
            ? this.selectedRequest.car.firstRegistration
            : "",
          hsnTsn: this.selectedRequest.car.hsnTsn
            ? this.selectedRequest.car.hsnTsn
            : "",
          location: {
            zipCode: this.selectedRequest.carLocation.zip,
          },
          customer: {
            firstName: this.selectedRequest.customer.firstName,
            lastName: this.selectedRequest.customer.lastName,
            address: this.selectedRequest.customer.address,
            zipCode: this.selectedRequest.customer.zip,
            city: this.selectedRequest.customer.city,
            phone: this.selectedRequest.customer.phone,
          },
          vehicleOwner: {
            firstName: this.selectedRequest.car.owner.firstName,
            lastName: this.selectedRequest.car.owner.lastName,
            address: this.selectedRequest.car.owner.address,
            zipCode: this.selectedRequest.car.owner.zip,
            city: this.selectedRequest.car.owner.city,
          },
          calculation: this.editOffer.calculation,
        },
      };
      /*
      let station = this.stations.find(o => o._id == this.selectedRequest.customer.selectedStation)
      if (station) {
        if (station.baseCalculation) {
          payload.task.calculation = {
            hourlyWageMechanics: station.baseCalculation.hourlyWageMechanics,
            hourlyWageElectronics: station.baseCalculation.hourlyWageElectronics,
            hourlyWageBody: station.baseCalculation.hourlyWageBody,
            hourlyWageHailDamage: station.baseCalculation.hourlyWageHailDamage,
            hourlyWagePaint: station.baseCalculation.hourlyWagePaint
          }
        }
      }
      */

      console.log(payload);
      this.$store
        .dispatch("requests/createAudatexTask", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          if (res.success) {
            this.editOffer.audatexFiascoTaskId = res.taskId;
            this.editOffer.audatexFiascoCaseId = res.caseId;
            this.$store.dispatch("offers/update", this.editOffer).then(() => {
              this.$store.dispatch("offers/getAll").then(() => {
                this.$store.commit("SET_BUSY", false);
                this.openAudatex();
                this.updateRequestStatus(this.editRequest.status);
              });
            });
          } else {
            console.log("CALC BRIDGE ERROR");
          }
        });
    },
    sendToStationAudatex() {
      let station = this.stations.find(
        (o) => o._id == this.selectedRequest.customer.selectedStation
      );
      if (!station) return;
      if (!station.audatexData.orgaId) {
        return;
      }

      this.$store.commit("SET_BUSY", true);
      let payload = {
        taskId: this.editOffer.audatexFiascoTaskId,
        station: station._id,
      };
      this.$store
        .dispatch("requests/audatexFiascoToStation", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          if (res.success) {
            this.editOffer.audatexStationTaskId = res.taskId;
            this.editOffer.audatexStationCaseId = res.caseId;
            this.$store.dispatch("offers/update", this.editOffer).then(() => {
              this.$store.dispatch("offers/getAll").then(() => {
                this.$store.commit("SET_BUSY", false);
              });
            });
          } else {
            console.log("CALC BRIDGE ERROR");
          }
        });
    },
    getEditData() {
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("requests/getById", this.$route.params.id)
        .then(() => {
          if (this.selectedRequest) {
            let offer = this.activeOffers.find(
              (o) => o._id === this.selectedRequest.offer
            );
            if (offer) {
              this.editOffer = JSON.parse(JSON.stringify(offer));
            }
          }
          this.$store.commit("SET_BUSY", false);
        });
    },
    openAudatex() {
      let payload = {
        caseId: this.editOffer.audatexFiascoCaseId,
        taskId: this.editOffer.audatexFiascoTaskId,
      };
      window.open(this.audatexLink(payload), "_blank");
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      user: "users/user",
      userId: "users/userId",
      stations: "stations/stations",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestReadyForFiasco: "requests/readyForFiasco",
      requestsByStatus: "requests/requestsByStatus",
      requestStatusName: "requests/requestStatusName",
      requestTargetRoute: "requests/requestTargetRoute",
      activeOffers: "offers/activeOffers",
      sum: "offers/sum",
      userStation: "stations/userStation",
      audatexLink: "offers/audatexLink",
      hasCalculation: "requests/hasCalculation",
      calculationOpen: "requests/calculationOpen",
    }),
  },
  watch: {
    requests: {
      deep: true,
      handler: function () {
        console.log("WATCH requests");
        this.getEditData();
      },
    },
  },
  mounted() {
    console.log("REQUEST MOUNTED");
    console.log(this.$route.params.id);
    this.getEditData();
    // this.$store.commit('requests/SET_SELECTED_REQUEST', this.requests.find(o => o._id === this.$route.params.id))
  },
  beforeUnmount() {
    this.$store.commit(
      "requests/SET_LAST_SELECTED_REQUEST",
      this.selectedRequest
    );
    this.$store.commit("requests/SET_SELECTED_REQUEST", null);
  },
};
</script>
