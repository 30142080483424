import axios from 'axios'

const state = {
  roles: []
}

// getters
const getters = {
  roles: state => state.roles
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseUrl + 'roles', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('update role')
    return axios.put(context.rootGetters.API.baseURL + 'roles/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update role')
    return axios.patch(context.rootGetters.API.baseURL + 'roles/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL (state, data) {
    state.roles = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
