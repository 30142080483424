<template>
  <div class="customers station-customers main-content">
    <el-row :gutter="40">
      <el-col :span="18">
        <h2 class="page-title">Kunden</h2>
      </el-col>
      <el-col :span="6">
        <div class="page-header-tools">
          <el-button
            icon="el-icon-plus"
            circle
            v-if="user.rights.includes('CREATE_CUSTOMERS')"
            @click="createDialogVisible = true"
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <el-table :data="stationCustomers" @row-click="onRowClick">
      <el-table-column label="Bild" width="120">
        <template #default="scope">
          <el-avatar
            v-if="!scope.row.s3Image"
            :src="API.imageUrl + 'customers/' + scope.row.image"
          ></el-avatar>
          <S3Avatar
            v-if="scope.row.s3Image"
            :image="scope.row.s3Image"
            :size="40"
          />
        </template>
      </el-table-column>
      <el-table-column prop="firstName" label="Vorname" width="180" sortable>
      </el-table-column>
      <el-table-column prop="lastName" label="Nachname" width="160" sortable>
      </el-table-column>
      <el-table-column prop="email" label="E-Mail" sortable> </el-table-column>
      <el-table-column
        v-if="user.isFiasco"
        prop="selectedStation"
        label="Wunschwerkstatt"
        sortable
      >
        <template #default="scope">
          <div v-if="scope.row.selectedStation">
            {{ scope.row.selectedStation.name }}<br />
            {{ scope.row.selectedStation.city }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="lastRequest.type" label="Anfragetyp" width="120">
      </el-table-column>
      <el-table-column prop="id" label="Id" width="100"> </el-table-column>
    </el-table>

    <el-dialog title="Neuer Kunde" v-model="createDialogVisible" width="560px">
      <el-form
        class="create-form"
        ref="form"
        :model="editItem"
        label-width="160px"
      >
        <el-form-item label="Vorname">
          <el-input v-model="editItem.firstName"></el-input>
        </el-form-item>
        <el-form-item label="Nachname">
          <el-input v-model="editItem.lastName"></el-input>
        </el-form-item>
        <el-form-item label="E-Mail">
          <el-input v-model="editItem.email"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="create()">Erzeugen</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Customers",
  data() {
    return {
      actionsMenuVisible: false,
      createDialogVisible: false,
      editItem: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$store.commit(
        "customers/SET_SELECTED_CUSTOMER",
        this.customers.find((o) => o._id === row._id)
      );
      this.$router.push({ name: "Customer", params: { id: row._id } });
    },
    create() {
      console.log("create");
      this.$store.commit("SET_BUSY", true);
      this.editItem.selectedStation = this.user.station;
      this.$store.dispatch("customers/create", this.editItem).then(() => {
        this.$store.commit("SET_BUSY", false);
        this.createDialogVisible = false;
        this.$store.dispatch("customers/getAll");
      });
    },
  },
  computed: {
    stationCustomers() {
      if (this.user.isFiasco) {
        return this.customers
          .filter((o) => o.selectedStation)
          .filter((o) => o.selectedStation._id === this.selectedStation._id);
      } else {
        return this.customers;
      }
    },
    ...mapGetters({
      API: "API",
      user: "users/user",
      selectedStation: "stations/selectedStation",
      customers: "customers/customers",
    }),
  },
};
</script>
