<template>
  <div class="calculations main-content">
    <h2>FIASCO Kalkulationen gesendet</h2>
    <el-table
      :data="requestsByStatus('CALCULATION_REQUESTED')"
      @row-click="onRowClick">
      <el-table-column
        prop="date"
        label="Datum"
        width="180">
      </el-table-column>
      <el-table-column
        prop="type"
        label="Typ"
        width="120">
      </el-table-column>
      <el-table-column
        prop="manufacturer"
        label="Hersteller"
        width="160">
      </el-table-column>
      <el-table-column
        prop="car"
        label="Modell"
        width="120">
      </el-table-column>
      <el-table-column
        prop="firstRegistration"
        label="Erstzulassung"
        width="120">
      </el-table-column>
      <el-table-column
        prop="damageLevel"
        label="Schadenstufe"
        width="120">
      </el-table-column>
      <el-table-column
        label="Fotos">
        <template #default="scope">
          <div class="requests-list-item-images">
             <el-popover
              v-for="(img, index) in scope.row.images"
              :key="index"
              placement="bottom"
              :width="500"
              trigger="hover"
              content="test">
              <template #reference>
                <el-image
                  :src="img"
                  fit="cover">
                </el-image>
              </template>
              <template #default>
                <el-image
                  :src="img"
                  fit="cover">
                </el-image>
              </template>
            </el-popover>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="customer"
        label="Kunde">
      </el-table-column>
      <el-table-column
        prop="fiascoId"
        label="FIASCO Nr."
        width="120">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CalculationsInbox',
  data() {
    return {
      test: []
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request'})
    },
    goDetails (row) {
      console.log(row)
      this.$router.push({name: 'Request'})
    }
  },
  computed: {
    ...mapGetters({
      requests: 'requests/requests',
      requestsByStatus: 'requests/requestsByStatus',
    }),
  },
}
</script>
