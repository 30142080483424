<template>
  <div class="user-password" v-if="editUser">
    <el-form class="details-form" ref="form" :model="editUser" label-position="top" label-width="60px">

      <el-alert
        v-if="passwordSaved"
        title="Passwort gespeichert!"
        type="success"
        show-icon>
      </el-alert>

      <el-form-item label="Neues Passwort">
        <el-input v-model="editPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="Passwort bestätigen">
        <el-input v-model="editPasswordCopy" show-password></el-input>
      </el-form-item>

      <el-button @click="updatePassword()" :disabled="!saveAvailable">Speichern</el-button>

    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserPassword',
  data() {
    return {
      editUser: null,
      passwordSaved: false,
      editPassword: '',
      editPasswordCopy: ''
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request'})
    },
    goDetails (row) {
      console.log(row)
      this.$router.push({name: 'Request'})
    },
    updatePassword () {
      let payload = {
        _id: this.editUser._id,
        password: this.editPassword
      }
      this.passwordSaved = false
      this.$store.dispatch('users/updatePassword', payload).then(() => {
        this.passwordSaved = true
        this.editPassword = ''
        this.editPasswordCopy = ''
      })
    }
  },
  computed: {
    saveAvailable () {
      return this.editPassword.length >= 3 && this.editPassword === this.editPasswordCopy 
    },
    ...mapGetters({
      stations: 'stations/stations',
      selectedUser: 'users/selectedUser',
      userRoles: 'users/roles',
      userRights: 'users/rights',
      userRightsByGroup: 'users/rightsByGroup',
    }),
  },
  mounted () {
    console.log('USER PASSWORD MOUNTED')
    this.editUser = JSON.parse(JSON.stringify(this.selectedUser))
  },
}
</script>
