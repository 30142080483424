import axios from 'axios'

const state = {
  selectedCar: null,
  customerCars: [],
  editCarVisible: false,
  scanRegistrationVisible: false,
  scanImage: null
}

// getters
const getters = {
  customerCars: state => state.customerCars,
  selectedCar: state => state.selectedCar,
  editCarVisible: state => state.editCarVisible,
  scanRegistrationVisible: state => state.scanRegistrationVisible,
  scanImage: state => state.scanImage,
}

// actions
const actions = {
  getByCustomer (context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'cars/customer/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_CUSTOMER_CARS', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('create car')
    return axios.put(context.rootGetters.API.baseURL + 'cars/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update car')
    return axios.patch(context.rootGetters.API.baseURL + 'cars/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}


// mutations
const mutations = {
  SET_CUSTOMER_CARS (state, data) {
    state.customerCars = data
  },
  SET_SELECTED_CAR (state, data) {
    state.selectedCar = data
  },
  SET_EDIT_CAR_VISIBLE (state, data) {
    state.editCarVisible = data
  },
  SET_SCAN_REGISTRATION_VISIBLE (state, data) {
    state.scanRegistrationVisible = data
  },
  SET_SCAN_IMAGE (state, data) {
    state.scanImage = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
