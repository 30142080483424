<template>
  <div class="customer-cars" v-if="customerCars">
    <el-row :gutter="40">
      <el-col :span="18">
        <h3 class="page-title">
          Fahrzeuge von {{ selectedCustomer.firstName }}
          {{ selectedCustomer.lastName }}
        </h3>
      </el-col>
      <el-col :span="6" class="page-header-tools">
        <div class="page-header-tools">
          <el-button
            icon="el-icon-plus"
            circle
            @click="createDialogVisible = true"
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <el-table :data="customerCars" @row-click="onRowClick">
      <el-table-column label="Kennzeichen" width="160">
        <template #default="scope">
          {{ scope.row.numberPlate }}
        </template>
      </el-table-column>
      <el-table-column prop="manufacturer" label="Hersteller" width="180">
      </el-table-column>
      <el-table-column prop="model" label="Modell"> </el-table-column>
      <el-table-column
        prop="firstRegistration"
        label="Erstzulassung"
        width="120"
      >
      </el-table-column>
      <el-table-column prop="vin" label="VIN" width="180"> </el-table-column>
      <el-table-column prop="hsnTsn" label="HSN TSN" width="180">
      </el-table-column>
    </el-table>

    <el-dialog
      title="Neues Fahrzeug"
      v-model="createDialogVisible"
      width="560px"
    >
      <el-form
        class="create-form"
        ref="form"
        :model="editItem"
        label-width="160px"
      >
        <el-form-item label="Hersteller">
          <el-input v-model="editItem.manufacturer"></el-input>
        </el-form-item>
        <el-form-item label="Modell">
          <el-input v-model="editItem.model"></el-input>
        </el-form-item>
        <el-form-item label="Erstzulassung (JJJJ-MM-TT)">
          <el-input v-model="editItem.firstRegistration"></el-input>
        </el-form-item>
        <el-form-item label="VIN">
          <el-input v-model="editItem.vin"></el-input>
        </el-form-item>
        <el-form-item label="HSN TSN">
          <el-input v-model="editItem.hsnTsn"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="create()">Erzeugen</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CustomerDetails",
  data() {
    return {
      editItem: {
        firstRegistration: "",
        manufacturer: "",
        model: "",
        vin: "",
      },
      actionsMenuVisible: false,
      createDialogVisible: false,
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({
        name: "Car",
        params: { customerId: this.selectedCustomer._id, id: row._id },
      });
    },
    create() {
      console.log("create");
      this.editItem.customer = this.selectedCustomer._id;
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("cars/create", this.editItem).then(() => {
        this.$store.commit("SET_BUSY", false);
        this.createDialogVisible = false;
        this.getCars();
      });
    },
    getCars() {
      console.log("getting cars for " + this.selectedCustomer.firstName);
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("cars/getByCustomer", this.selectedCustomer._id)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
        });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      axiosConfig: "axiosConfig",
      customers: "customers/customers",
      selectedCustomer: "customers/selectedCustomer",
      customerCars: "cars/customerCars",
    }),
  },
  mounted() {
    console.log("CARS MOUNTED");
    this.getCars();
  },
};
</script>
