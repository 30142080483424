<template>
  <el-dialog
      title="Fahrzeug bearbeiten"
      v-model="visible"
      width="600px"
      @close="onClose()"
      >
    <el-form class="request-form" ref="form" :model="editCar" label-width="160px" v-if="editCar">
      <el-form-item label="Hersteller">
        <el-input v-model="editCar.manufacturer" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"></el-input>
      </el-form-item>
      <el-form-item label="Modell">
        <el-input v-model="editCar.model" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"></el-input>
      </el-form-item>
      <el-form-item label="Erstzulassung">
        <el-input v-model="editCar.firstRegistration" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
          placeholder="JJJJ-MM-TT"></el-input>
      </el-form-item>
      <el-form-item label="VIN">
        <el-input v-model="editCar.vin" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"></el-input>
      </el-form-item>
      <el-form-item label="HSN TSN">
        <el-input v-model="editCar.hsnTsn" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"></el-input>
      </el-form-item>
      <el-form-item label="Nummernschild">
        <el-input v-model="editCar.numberPlate" :disabled="!user.rights.includes('EDIT_CUSTOMERS')"></el-input>
      </el-form-item>
      <!--el-form-item label="Bild">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-image :src="API.imageUrl + 'cars/' + editCar.image"/>
          </el-col>
          <el-col :span="6" >
            <el-upload
              v-if="user.rights.includes('EDIT_CUSTOMERS')"
              drag
              :action="API.baseUrl + 'cars/upload'"
              :data="{
                id: editCar._id
              }"
              :headers="axiosConfig.headers"
              :on-success="onUploadLogoSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Bild hierhin ziehen oder <em>klicken zum Hochladen</em></div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form-item-->
      <el-form-item>
        <el-button type="primary" @click="update"
         v-if="user.rights.includes('EDIT_CUSTOMERS')">
          Speichern
         </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EditCar',
  emits: ['carupdated'],
  props: {
    car: Object
  },
  data() {
    return {
      email: '',
      phoneNumber: '',
      visible: false,
      editCar: null
    }
  },
  methods: {
    update () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('cars/update', this.editCar).then(() => {
        this.getSelectedData()
        this.$store.commit('SET_BUSY', false)
        this.visible = false
        this.$emit('carupdated')
        this.$store.commit('cars/SET_EDIT_CAR_VISIBLE', false)
      })
    },
    getSelectedData () {
      if (this.car) {
        console.log(this.car)
        this.editCar = JSON.parse(JSON.stringify(this.car))
      }
    },
    onUploadLogoSuccess (response) {
      console.log(response)
      this.editCar.image = response
      this.update()
    },
    onClose () {
      this.$store.commit('cars/SET_EDIT_CAR_VISIBLE', false)
    },
  },
  computed: {
    ...mapGetters({
      CHANGE_TIMEOUT_MS: 'CHANGE_TIMEOUT_MS',
      editCarVisible: 'cars/editCarVisible',
      userStation: 'stations/userStation',
      user: 'users/user',
      stations: 'stations/stations',
      customerCars: 'cars/customerCars'
    })
  },
  watch: {
    editCarVisible () {
      if (this.editCarVisible) {
        this.visible = true
        this.getSelectedData()
      }
    }
  },
  mounted () {
    console.log('editcar mounted')
  }
}
</script>

