<template>
  <div class="user main-content" v-if="selectedUser">
    <el-row :gutter="40">
      <el-col :span="18">
        <h2>{{ selectedUser.firstName }} {{ selectedUser.lastName }}</h2>
      </el-col>
      <el-col :span="6">
        <span>ID {{ selectedUser._id }}</span>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="Details">
        <UserDetails />
      </el-tab-pane>
      <el-tab-pane
        label="Rechte"
        v-if="user.rights.includes('GIVE_RIGHTS_USER')"
      >
        <UserRights />
      </el-tab-pane>
      <el-tab-pane label="Expresslinks" v-if="selectedUser.station">
        <UserActions />
      </el-tab-pane>
      <el-tab-pane label="Passwort" v-if="user.rights.includes('EDIT_USERS')">
        <UserPassword />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetails from "./UserDetails.vue";
import UserRights from "./UserRights.vue";
import UserPassword from "./UserPassword.vue";
import UserActions from "./UserActions.vue";

export default {
  name: "User",
  components: {
    UserDetails,
    UserRights,
    UserPassword,
    UserActions,
  },
  data() {
    return {
      activeTab: "",
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
  },
  computed: {
    ...mapGetters({
      user: "users/user",
      users: "users/users",
      selectedUser: "users/selectedUser",
    }),
  },
  mounted() {
    console.log("USER MOUNTED");
    console.log(this.$route.params.id);
    this.$store.commit(
      "users/SET_SELECTED_USER",
      this.users.find((o) => o._id === this.$route.params.id)
    );
  },
};
</script>
