<template>
  <div class="station main-content" v-if="selectedStation">
    <el-row :gutter="40">
      <el-col :span="18">
        <h2>{{ selectedStation.name }}</h2>
      </el-col>
      <el-col :span="6">
        <span>ID {{ selectedStation.id }}</span>
      </el-col>
    </el-row>
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="Details" name="details">
        <StationDetails />
      </el-tab-pane>
      <el-tab-pane
        label="Kunden"
        name="customers"
        v-if="user.rights.includes('VIEW_CUSTOMERS')"
      >
        <StationCustomers />
      </el-tab-pane>
      <el-tab-pane
        label="Mitarbeiter"
        name="users"
        v-if="user.rights.includes('VIEW_USERS')"
      >
        <StationUsers />
      </el-tab-pane>
      <el-tab-pane label="QR Code für Kunden" name="qr">
        <StationQrCode />
      </el-tab-pane>
      <el-tab-pane
        label="Basiskalkulation"
        name="calculation"
        v-if="user.rights.includes('EDIT_CALCULATION')"
      >
        <StationBaseCalculation />
      </el-tab-pane>
      <el-tab-pane
        label="Mein DMS"
        name="dms"
        v-if="user.rights.includes('EDIT_CALCULATION')"
      >
        <StationDmsData />
      </el-tab-pane>
      <el-tab-pane
        label="FIASCO Paket"
        name="f-package"
        v-if="user.rights.includes('EDIT_STATIONS')"
      >
        <StationFiascoPackage />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StationDetails from "./StationDetails.vue";
import StationCustomers from "./StationCustomers.vue";
import StationUsers from "./StationUsers.vue";
import StationQrCode from "./StationQrCode.vue";
import StationBaseCalculation from "./StationBaseCalculation.vue";
import StationDmsData from "./StationDmsData.vue";
import StationFiascoPackage from "./StationFiascoPackage.vue";
/*
 */

export default {
  name: "Garage",
  components: {
    StationDetails,
    StationCustomers,
    StationUsers,
    StationQrCode,
    StationBaseCalculation,
    StationDmsData,
    StationFiascoPackage,
    /*
     */
  },
  data() {
    return {
      activeTab: "details",
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
    goDetails(row) {
      console.log(row);
      this.$router.push({ name: "Request" });
    },
    getData() {
      let stationId;
      if (this.$route.name === "MyStation") {
        console.log("yes it is my station");
        stationId = this.user.station;
      } else {
        stationId = this.$route.params.id;
      }
      this.$store.commit(
        "stations/SET_SELECTED_STATION",
        this.stations.find((o) => o._id === stationId)
      );
      this.$store.dispatch("users/getByStation", stationId);
    },
  },
  computed: {
    ...mapGetters({
      user: "users/user",
      stations: "stations/stations",
      selectedStation: "stations/selectedStation",
    }),
  },
  watch: {
    $route() {
      this.getData();
    },
  },
  mounted() {
    console.log("STATION MOUNTED");
    this.getData();
  },
};
</script>
