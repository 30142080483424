<template>
  <div class="station-qr" >

      <img class="station-qr-image"
        v-if="qrImageData"
        :src="qrImageData">
        <p>
          {{qrData}}
        </p>
       
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import QRCode from'qrcode' 

export default {
  name: 'StationQrCode',
  data() {
    return {
      changeTimeout: null,
      editStation: null,
      qrImageData: null,
      qrData: ''
    }
  },
  methods: {
    getEditData () {
      console.log('getEditData')
      // let station = this.stations.find(o => o._id === this.$route.params.id)
      this.editStation = JSON.parse(JSON.stringify(this.selectedStation))
      /*
      this.qrData = JSON.stringify({
        system: 'FIASCO',
        stationId: this.editStation._id,
        stationName: this.editStation.name,
        inviteCode: this.editStation.inviteCode
      })
      */
      this.qrData = 'https://werkstatt.fiasco.de/' + this.editStation.inviteCode
      QRCode.toDataURL(
        this.qrData,
        {
          width: 720
        }
      )
        .then(url => {
          console.log(url)
          this.qrImageData = url
        })
        .catch(err => {
          console.error(err)
        })

    }
  },
  computed : {
    ...mapGetters({
      CHANGE_TIMEOUT_MS: 'CHANGE_TIMEOUT_MS',
      selectedStation: 'stations/selectedStation',
      stations: 'stations/stations',
    })
  },
  watch: {
    $route () {
      if (this.selectedStation) {
        this.getEditData()
      }
    }
  },
  mounted () {
    console.log('station QR mounted')
    this.getEditData()
  }
}
</script>

