import axios from 'axios'

const state = {
  stations: [],
  actions: [],
  selectedStation: null,
  userStation: null,
  fiascoPackageLevels: [
    'BASIC',
    'S',
    'M',
    'L',
    'PREMIUM'
  ]
}

// getters
const getters = {
  stations: state => state.stations,
  actions: state => state.actions,
  selectedStation: state => state.selectedStation,
  fiascoPackageLevels: state => state.fiascoPackageLevels,
  userStation: state => state.userStation
}

// actions
const actions = {
  getAll(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'stations', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log('update station')
    return axios.put(context.rootGetters.API.baseURL + 'stations/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log('update station')
    return axios.patch(context.rootGetters.API.baseURL + 'stations/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  deletePoster(context, payload) {
    console.log('deletePoster')
    return axios.patch(
      context.rootGetters.API.baseURL + 'stations/poster/delete',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('DELETED')
    }).catch(error => {
      console.log(error)
    })
  },
  createAction(context, payload) {
    console.log('create action')
    return axios.put(context.rootGetters.API.baseURL + 'stations/actions', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  getActionsByStation(context, payload) {
    return axios.get(context.rootGetters.API.baseUrl + 'stations/actions/' + payload, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ACTIONS', response.data))
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.stations = data
  },
  SET_SELECTED_STATION(state, data) {
    state.selectedStation = data
  },
  SET_USER_STATION(state, data) {
    state.userStation = data
  },
  SET_ACTIONS(state, data) {
    state.actions = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
