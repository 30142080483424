<template>
  <div class="verifications main-content">
    <el-row :gutter="40"> 
      <el-col :span="18">
        <h2 class="page-title">Offene E-Mail Bestätigungen</h2>
      </el-col>
      <el-col :span="6" class="page-header-tools">

      </el-col>
    </el-row>
    <el-table
      :data="verificationCodes"
      @row-click="onRowClick">    
      <el-table-column
        prop="email"
        label="E-Mail"
        sortable>
      </el-table-column>
      <el-table-column
        prop="dateCreated"
        label="Datum"
        sortable>
        <template #default="scope">
          {{formatDate(scope.row.dateCreated)}}
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        label="Code"
        sortable>
      </el-table-column>
    </el-table>


  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dateDisplayMixin from '../mixins/dateDisplay'

export default {
  name: 'Verifications',
  data() {
    return {
      editItem: {
        firstName: '',
        lastName: '',
        email: '' 
      }
    }
  },
  mixins: [dateDisplayMixin],
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$store.commit('customers/SET_SELECTED_CUSTOMER', this.customers.find(o => o._id === row._id))
      this.$router.push({name: 'Customer', params: {id: row._id}})
    },
  },
  computed: {
    ...mapGetters({
      API: 'API',
      user: 'users/user',
      verificationCodes: 'verificationCodes/verificationCodes',
    }),
  },
}
</script>
