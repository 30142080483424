import axios from 'axios'

const state = {
  selectedCustomer: null,
  customers: [],
}

// getters
const getters = {
  customers: state => state.customers,
  selectedCustomer: state => state.selectedCustomer,
}

// actions
const actions = {
  getAll (context) {
    return axios.get(context.rootGetters.API.baseUrl + 'customers', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  create (context, payload) {
    console.log('update customers')
    return axios.put(context.rootGetters.API.baseURL + 'customers/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update (context, payload) {
    console.log('update customer')
    return axios.patch(context.rootGetters.API.baseURL + 'customers/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  updatePassword (context, payload) {
    console.log('updatePassword')
    return axios.patch(context.rootGetters.API.baseURL + 'customers/pwd/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  sendSmsInvitation (context, payload) {
    console.log('sendSmsInvitation')
    return axios.post(context.rootGetters.API.baseURL + 'customers/sms-invite', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  sendEmailInvitation (context, payload) {
    console.log('sendEmailInvitation')
    return axios.post(context.rootGetters.API.baseURL + 'customers/email-invite', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  delete (context, payload) {
    console.log('delete customer')
    return axios.delete(context.rootGetters.API.baseURL + 'customers/' + payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}


// mutations
const mutations = {
  SET_ALL (state, data) {
    state.customers = data
  },
  SET_SELECTED_CUSTOMER (state, data) {
    state.selectedCustomer = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
