<template>
  <el-container v-loading.fullscreen.lock="busy" :class="{ error: loginError }">
    <el-header :class="{ fiasco: user ? user.isFiasco : false }">
      <div class="header-content">
        <div class="header-home" @click="goHome()">
          <i class="el-icon-house"></i>
        </div>
        <img
          class="header-logo"
          src="@/assets/gfx/fiasco-logo.png"
          @click="goHome()"
        />
        <div class="header-tools" v-if="user">
          <div class="header-tools-item header-tools-expresslink">
            <el-button type="primary" @click="showExpressLink()"
              >Expresslink</el-button
            >
          </div>
          <div
            class="header-tools-item header-tools-update"
            :class="{ active: newDataAvailable }"
            @click="loadData()"
          >
            <i class="el-icon-refresh"></i>
          </div>
          <div
            class="header-tools-item header-tools-fiasco"
            v-if="user.isFiasco"
          >
            <img
              class="header-tools-fiasco-logo"
              src="@/assets/gfx/fiasco-logo.png"
            />
            Team
          </div>
          <div
            class="header-tools-item header-tools-organization"
            v-if="userStation"
            @click="goMyStation()"
          >
            <div class="header-tools-organization-caption">
              Werkstatt: <br />
              {{ userStation.name }}
            </div>
            <el-avatar
              v-if="!userStation.s3LogoImage"
              :src="API.imageUrl + 'stations/logos/' + userStation.logoImage"
            ></el-avatar>
            <S3Avatar
              v-if="userStation.s3LogoImage"
              :image="userStation.s3LogoImage"
              :size="40"
            />
          </div>
          <div
            class="header-tools-item header-tools-login-info"
            @click="goProfile()"
          >
            angemeldet als <br />
            {{ user.firstName }} {{ user.lastName }}
            <el-avatar
              v-if="!user.s3Image"
              :src="API.imageUrl + 'users/' + user.image"
            ></el-avatar>
            <S3Avatar v-if="user.s3Image" :image="user.s3Image" :size="40" />
          </div>
          <div
            class="header-tools-item header-tools-logout"
            @click="onLogout()"
          >
            <i class="el-icon-circle-close"></i>
          </div>
          <!--el-popover
            placement="bottom"
            :width="200"
            trigger="click"
          >
            <template #reference>
              <div class="header-tools-item">
                    Funktionen
              </div>
            </template>
            <div class="header-tools-profile-menu">
              <div class="header-tools-profile-item" @click="onLogout()">
                Abmelden
              </div>
            </div>
          </el-popover-->
        </div>
      </div>
    </el-header>
    <el-container v-if="user && dataLoaded">
      <el-aside width="260px">
        <perfect-scrollbar>
          <div class="aside-inner">
            <el-menu
              background-color="#343436"
              text-color="#d2d2d2"
              active-text-color="#0085c5"
              v-if="user"
              :router="true"
              :default-active="activeLink"
            >
              <el-submenu index="1">
                <template #title>
                  <i class="el-icon-box"></i>
                  <span>Anfragen</span>
                </template>
                <el-menu-item
                  index="/direkt-anfragen/eingang"
                  v-if="user.rights.includes('VIEW_INBOX')"
                >
                  <img src="@/assets/gfx/menu/eingang.png" />
                  <span>Eingang</span>
                </el-menu-item>
                <el-menu-item
                  index="/direkt-anfragen/rueckmeldung"
                  v-if="user.rights.includes('VIEW_FEEDBACK')"
                >
                  <img src="@/assets/gfx/menu/rueckfrage.png" />
                  <span>Rückfrage</span>
                </el-menu-item>
                <el-menu-item index="/direkt-anfragen/angebote">
                  <img src="@/assets/gfx/menu/angebot.png" />
                  <span>Angebote</span>
                </el-menu-item>
                <el-menu-item
                  index="/direkt-anfragen/fiasco-kalkulationen"
                  v-if="
                    user.isFiasco &&
                    user.rights.includes('VIEW_FIASCO_CALCULATIONS')
                  "
                >
                  <img src="@/assets/gfx/menu/kalkulationen.png" />
                  <span>FIASCO-Kalkulationen</span>
                </el-menu-item>
                <el-menu-item index="/direkt-anfragen/reparaturen">
                  <img src="@/assets/gfx/menu/reparaturen.png" />
                  <span>Reparaturen</span>
                </el-menu-item>
                <el-menu-item
                  index="/anfragen/neu"
                  v-if="
                    user.isFiasco && user.rights.includes('CREATE_REQUESTS')
                  "
                >
                  <img src="@/assets/gfx/menu/anfrage.png" />
                  <span>Anfrage erzeugen</span>
                </el-menu-item>
              </el-submenu>

              <!--el-submenu index="8">
                <template #title>
                  <i class="el-icon-location"></i>
                  <span>Schnellanfragen</span>
                </template>
                <el-menu-item index="9">
                  <i class="el-icon-menu"></i>
                  <span>Eingang Anfragen</span>
                </el-menu-item>
                <el-menu-item index="10">
                  <i class="el-icon-menu"></i>
                  <span>Gesendete Angebote</span>
                </el-menu-item>
                <el-menu-item index="11">
                  <i class="el-icon-menu"></i>
                  <span>Ausgang Anfragen</span>
                </el-menu-item>
                <el-menu-item index="12">
                  <i class="el-icon-menu"></i>
                  <span>Rückmeldung</span>
                </el-menu-item>
              </el-submenu-->

              <!--el-submenu index="13">
                <template #title>
                  <i class="el-icon-location"></i>
                  <span>FIASCO Pool</span>
                </template>
                <el-menu-item index="14">
                  <i class="el-icon-menu"></i>
                  <span>Marktplatz</span>
                </el-menu-item>
                <el-menu-item index="141">
                  <i class="el-icon-menu"></i>
                  <span>Meine Anfragen</span>
                </el-menu-item>
                <el-menu-item index="15">
                  <i class="el-icon-menu"></i>
                  <span>Gesendete Angebote</span>
                </el-menu-item>
              </el-submenu-->

              <!--el-submenu index="16" v-if="user.role === 'ADMIN'">
                <template #title>
                  <i class="el-icon-location"></i>
                  <span>FIASCO Kalkulationen</span>
                </template>
                <el-menu-item index="17">
                  <i class="el-icon-menu"></i>
                  <span>Eingang</span>
                </el-menu-item>
                <el-menu-item index="18">
                  <i class="el-icon-menu"></i>
                  <span>Rückfrage</span>
                </el-menu-item>
                <el-menu-item index="19">
                  <i class="el-icon-menu"></i>
                  <span>Vollständige Anfragen</span>
                </el-menu-item>
                <el-menu-item index="20">
                  <i class="el-icon-menu"></i>
                  <span>Gesendete Kalkulationen</span>
                </el-menu-item>
              </el-submenu-->

              <!--el-submenu index="/marketing/" v-if="user.station">
                <template #title>
                  <i class="el-icon-s-marketing"></i>
                  <span>Marketing</span>
                </template>
                <el-menu-item index="/marketing/einladen">
                  <i class="el-icon-message"></i>
                  <span>Kunden einladen</span>
                </el-menu-item>
              </el-submenu-->

              <el-submenu index="/verwaltung/">
                <template #title>
                  <i class="el-icon-coordinate"></i>
                  <span>Verwaltung</span>
                </template>
                <el-menu-item
                  index="/verwaltung/werkstaetten"
                  v-if="user.isFiasco && user.rights.includes('VIEW_STATIONS')"
                >
                  <i class="el-icon-house"></i>
                  <span>Werkstätten</span>
                </el-menu-item>
                <el-menu-item
                  index="/verwaltung/benutzer"
                  v-if="user.rights.includes('VIEW_USERS')"
                >
                  <i class="el-icon-user"></i>
                  <span>Mitarbeiter</span>
                </el-menu-item>
                <el-menu-item
                  index="/verwaltung/meine-werkstatt"
                  v-if="userStation"
                >
                  <i class="el-icon-house"></i>
                  <span>Meine Werkstatt</span>
                </el-menu-item>
                <el-menu-item
                  index="/verwaltung/kunden"
                  v-if="user.isFiasco && user.rights.includes('VIEW_CUSTOMERS')"
                >
                  <i class="el-icon-user-solid"></i>
                  <span>Kunden</span>
                </el-menu-item>
                <el-menu-item
                  index="/verwaltung/bestaetigungen"
                  v-if="user.isFiasco && user.rights.includes('VIEW_CUSTOMERS')"
                >
                  <i class="el-icon-message"></i>
                  <span>Unbestätigte E-Mails</span>
                </el-menu-item>
                <el-menu-item
                  index="/verwaltung/rollen"
                  v-if="user.isFiasco && user.rights.includes('EDIT_ROLES')"
                >
                  <i class="el-icon-key"></i>
                  <span>Rollen</span>
                </el-menu-item>
                <el-menu-item index="/verwaltung/status">
                  <i class="el-icon-finished"></i>
                  <span>Statusübersicht</span>
                </el-menu-item>
              </el-submenu>

              <el-menu-item
                index="/chat"
                v-if="user.rights.includes('VIEW_CHAT')"
              >
                <img src="@/assets/gfx/menu/chat.png" />
                <span>FIASCO-Chat</span>
              </el-menu-item>

              <!--el-submenu index="25">
                <template #title>
                  <i class="el-icon-location"></i>
                  <span>Reklamationen</span>
                </template>
                <el-menu-item index="26">
                  <span>fiASCO</span>
                </el-menu-item>
                <el-menu-item index="27">
                  <span>Werkstätten</span>
                </el-menu-item>
              </el-submenu-->
            </el-menu>

            <div class="menu-links">
              <el-link icon="el-icon-phone" href="mailto:support@fiasco.de"
                >FIASCO Support</el-link
              >
              <el-link
                icon="el-icon-s-opportunity"
                href="https://fiasco.de/"
                target="_blank"
                >Über FIASCO</el-link
              >
              <!--el-link icon="el-icon-s-opportunity">Hilfe</el-link>
              <el-link icon="el-icon-s-opportunity">FAQ</el-link-->
              <p class="version">v 2021-12-15</p>
            </div>
          </div>
        </perfect-scrollbar>
      </el-aside>
      <el-main>
        <router-view v-if="dataLoaded"></router-view>
      </el-main>
    </el-container>

    <el-dialog
      title="Login"
      v-model="loginVisible"
      width="320px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        class="login-form"
        ref="form"
        :model="loginForm"
        label-position="top"
      >
        <el-form-item label="E-Mail / Benutzer">
          <el-input v-model="loginForm.email" type="email"></el-input>
        </el-form-item>
        <el-form-item label="Passwort">
          <el-input
            v-model="loginForm.password"
            type="password"
            @keyup.enter="onLogin()"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onLogin()">Start</el-button>
        </el-form-item>
      </el-form>
      <div class="login-error" v-if="loginError">
        {{ loginError }}
      </div>
      <div class="login-legals">
        <el-link href="https://fiasco.de/impressum/" target="_blank"
          >Impressum</el-link
        >
        <el-link href="https://fiasco.de/datenschutzerklarung/" target="_blank"
          >Datenschutz</el-link
        >
      </div>
    </el-dialog>

    <InviteCustomer />
  </el-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";

import InviteCustomer from "@/components/InviteCustomer.vue";

export default {
  name: "App",
  components: {
    InviteCustomer,
  },
  data() {
    return {
      UPDATE_INTERVAL_MILLIS: 18000,
      updateInterval: null,
      profileMenuVisible: false,
      loginVisible: true,
      dataLoaded: false,
      updateAge: null,
      loginForm: {
        email: "",
        password: "",
      },
      activeLink: "",
    };
  },
  methods: {
    onMenuOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    onMenuClose(key, keyPath) {
      console.log(key, keyPath);
    },
    goHome() {
      console.log("go home");
      this.$router.push({ name: "Start" });
    },
    goMyStation() {
      this.$router.push({ name: "MyStation" });
    },
    goProfile() {
      this.$router.push({ name: "User", params: { id: this.user._id } });
    },
    setUser(user) {
      console.log("setUser");
      console.log(user);
      this.profileMenuVisible = false;
      this.$store.commit("users/SET_USER", user);
    },
    onLogin() {
      /*
      this.$store.commit('users/SET_USER', user)
      this.loginVisible = false
      this.$router.push({ name: 'Start' })
      */
      let payload = {
        email: this.loginForm.email,
        password: this.loginForm.password,
      };
      this.$store.commit("users/SET_LOGIN_ERROR", null);
      this.$store.dispatch("users/login", payload).then(() => {
        if (!this.loginError) {
          this.loginVisible = false;
          this.loadData();
        } else {
          this.$store.commit("SET_BUSY", false);
          console.log(this.loginError);
        }
      });
    },
    onLogout() {
      console.log("onLogout");
      this.$store.commit("users/LOGOUT");
      window.clearInterval(this.updateInterval);
      localStorage.removeItem("fiasco-token");
      localStorage.removeItem("fiasco-user");
      this.loginVisible = true;
      this.$store.commit("SET_BUSY", false);
      this.$router.push({ name: "Start" });
      window.Usersnap.hideButton();
    },
    loadData() {
      this.$store.commit("SET_BUSY", true);

      this.$store.dispatch("users/getAll").then(() => {
        this.$store.dispatch("users/getRights").then(() => {
          this.$store.dispatch("verificationCodes/getAll").then(() => {
            this.$store.dispatch("requests/getRequestStates").then(() => {
              this.$store.dispatch("stations/getAll").then(() => {
                this.$store.commit(
                  "stations/SET_USER_STATION",
                  this.stations.find((o) => o._id === this.user.station)
                );
                this.$store.dispatch("requests/getActive").then(() => {
                  this.$store.dispatch("requests/getFixed").then(() => {
                    this.$store
                      .dispatch("users/getByStation", this.user.station)
                      .then(() => {
                        this.$store.dispatch("customers/getAll").then(() => {
                          this.$store.dispatch("roles/getAll").then(() => {
                            this.$store.dispatch("offers/getAll").then(() => {
                              this.$store.commit("SET_BUSY", false);
                              this.$store.commit("RESET_LAST_UPDATE_CALL");
                              this.$store.commit(
                                "SET_NEW_DATA_AVAILABLE",
                                false
                              );
                              let that = this;
                              that.updateInterval = window.setInterval(() => {
                                that.getLastUpdate();
                              }, that.UPDATE_INTERVAL_MILLIS);
                              this.dataLoaded = true;
                              this.updateAge = 0;
                              if (
                                window.Usersnap &&
                                window.Usersnap.showButton
                              ) {
                                window.Usersnap.showButton();
                              }
                            });
                          });
                        });
                      });
                  });
                });
              });
            });
          });
        });
      });
    },
    getLastUpdate() {
      this.$store.dispatch("requests/getLastUpdate").then(() => {
        this.updateAge = moment(this.lastUpdateCall).diff(
          moment(this.lastUpdate),
          "seconds"
        );
        if (this.updateAge < (this.UPDATE_INTERVAL_MILLIS / 1000) * 0.5) {
          this.$store.commit("SET_NEW_DATA_AVAILABLE", true);
        } else {
          this.$store.commit("RESET_LAST_UPDATE_CALL");
        }
      });
    },
    initAuthHandler() {
      let that = this;
      axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (that.loginVisible) {
            if (err.response.status === 401) {
              console.log("401");
              that.$store.commit(
                "users/SET_LOGIN_ERROR",
                "Passwort nicht korrekt"
              );
            }
            if (err.response.status === 404) {
              console.log("404");
              that.$store.commit(
                "users/SET_LOGIN_ERROR",
                "Benutzer nicht gefunden"
              );
            }
          } else {
            if (err.response.status === 401) {
              console.log("401");
              that.onLogout();
            }
          }
          throw err;
        });
      });
    },
    showExpressLink() {
      this.$store.commit("SET_EXPRESS_LINK_VISIBLE", true);
    },
    onOrientationChange() {
      setTimeout(() => {
        console.log(window.innerHeight);
        this.$forceUpdate();
      }, 100);
    },
    onResize() {
      this.$forceUpdate();
    },
  },
  watch: {
    $route() {
      console.log("ROUTE");
      this.activeLink = this.$route.path;
    },
    selectedRequest() {
      console.log(this.$route.path);
      if (this.$route.path.includes("/anfrage/") && this.selectedRequest) {
        this.activeLink = this.menuLinkByRequest(this.selectedRequest);
      }
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      expressLinkVisible: "expressLinkVisible",
      users: "users/users",
      loginError: "users/loginError",
      stationUsers: "users/stationUsers",
      userId: "users/userId",
      user: "users/user",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
      menuLinkByRequest: "requests/menuLinkByRequest",
      stations: "stations/stations",
      selectedRequest: "requests/selectedRequest",
      userStation: "stations/userStation",
    }),
  },
  mounted() {
    window.addEventListener("orientationchange", this.onOrientationChange);
    window.addEventListener("resize", this.onResize);
    this.activeLink = this.$route.path;

    this.initAuthHandler();
    // persistence
    const token = localStorage.getItem("fiasco-token");
    const user = JSON.parse(localStorage.getItem("fiasco-user"));
    if (token && user) {
      this.loginVisible = false;
      this.$store.commit("SET_TOKEN", token);
      this.$store.commit("users/LOGIN", user);
      console.log("local user:");
      console.log(user);
      this.loadData();
    }
  },
  created() {
    // Add the UserSnap plugin.

    window.onUsersnapCXLoad = function (api) {
      // Initially hide the button as the loadData() method above will show it
      // when a user is signed in.
      api.init({
        button: {
          isHidden: true,
        },
      });

      // When opening the feedback widget, fill it with some user data.
      api.on("open", function (event) {
        console.log("Usersnap: open");
        const userData = JSON.parse(localStorage.getItem("fiasco-user"));
        if (userData) {
          event.api.setValue("visitor", userData.email);
        } else {
          event.api.setValue("visitor", "Unknown -- userData not available");
        }
        event.api.setValue("custom", {
          enviroment:
            process.env.NODE_ENV == "production" ? "production" : "development",
        });
      });

      // Usually Usersnap hides the feedback button after a submission. We would like the button
      // to always be visible.
      api.on("close", function () {
        window.Usersnap.showButton();
      });

      // Expose the Usersnap API globally.
      window.Usersnap = api;
    };

    var script = document.createElement("script");
    script.async = 1;
    script.src =
      "https://widget.usersnap.com/load/e056a66c-ba8a-413a-bdd6-dca3658cf82d?onload=onUsersnapCXLoad";
    document.getElementsByTagName("head")[0].appendChild(script);
  },
};
</script>

<style>
@import "./assets/css/fiasco-manager.scss";
</style>
