<template>
  <div class="complaints main-content">
    <h2>Reklamationen bei Werkstätten</h2>
    
  </div>
</template>

<script>
export default {
  name: 'Complaints',
  data() {
    return {
      requests: [
        {
          date: '07.12.2020',
          customer: 'Miranda Car',
          images: [
            'http://cloudwerkstatt.raumstation-endstation.de/examples/3.jpg',
            'http://cloudwerkstatt.raumstation-endstation.de/examples/4.jpg',
            'http://cloudwerkstatt.raumstation-endstation.de/examples/6.jpg'
          ],
          damageLevel: 2,
          car: 'Mercedes Benz C200 Baujahr 2005',
          state: 'Angebot gesendet',
        }
      ]
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request'})
    },
    goDetails (row) {
      console.log(row)
      this.$router.push({name: 'Request'})
    }
  }
}
</script>
