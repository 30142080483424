<template>
  <div class="request-create">

    <h2>Anfrage erzeugen</h2>

    <el-form class="request-form" ref="form" :model="editRequest" label-width="160px">

      <el-form-item label="Bezeichnung">
        <el-input v-model="editRequest.title"></el-input>
      </el-form-item>

      <el-form-item label="Kunde">
        <el-select
          class="wide"
          v-model="editRequest.customer"
          filterable
          remote
          placeholder="Kunde suchen"
          :remote-method="searchCustomer"
          @change="onCustomerSelect"
          >
          <el-option
            class="select-avatars"
            v-for="item in filteredCustomers"
            :key="item._id"
            :label="item.firstName + ' ' + item.lastName"
            :value="item._id">
            <div class="select-avatars-item">
              <el-avatar :src="API.imageUrl + 'customers/' + item.image"></el-avatar>
              <div>
                <div>{{item.firstName + ' ' + item.lastName}}</div>
                <div class="sub">{{item.email}}</div>
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Typ">
        <el-radio v-model="editRequest.type" label="DIRECT">Direktanfrage</el-radio>
        <el-radio v-model="editRequest.type" label="POOL">Poolanfrage</el-radio>
      </el-form-item>

      <el-form-item label="Beschreibung">
        <el-input type="textarea" v-model="editRequest.description"></el-input>
      </el-form-item>

      <el-form-item label="Fahrzeugwahl">
        <el-radio v-model="carMode" label="SELECT">Aus Kundenpool</el-radio>
        <el-radio v-model="carMode" label="CREATE" @click="editRequest.car = ''">Neues Fahrzeug</el-radio>
      </el-form-item>

      <div v-if="carMode === 'SELECT'">
        <el-form-item label="Fahrzeug">
          <el-select
            class="wide"
            v-model="editRequest.car"
            placeholder="Fahrzeugpool"
            >
            <el-option
              class="select-avatars"
              v-for="item in customerCars"
              :key="item._id"
              :label="item.manufacturer + ' ' + item.model"
              :value="item._id">
              <div class="select-avatars-item">
                <el-avatar :src="API.imageUrl + 'cars/' + item.image"></el-avatar>
                <div>
                  <div>{{item.manufacturer + ' ' + item.model}}</div>
                  <div class="sub">{{item.firstRegistration}}</div>
                </div>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <div v-if="carMode === 'CREATE'">
        <el-form ref="car-form" :model="editCar" label-width="160px">
          <el-form-item label="Hersteller">
            <el-input v-model="editCar.manufacturer"></el-input>
          </el-form-item>
          <el-form-item label="Modell">
            <el-input v-model="editCar.model"></el-input>
          </el-form-item>
          <el-form-item label="Erstzulassung">
            <el-input v-model="editCar.firstRegistration"></el-input>
          </el-form-item>
          <el-form-item label="VIN">
            <el-input v-model="editCar.vin"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <el-divider></el-divider>


      <el-form-item label="Status">
        <el-select v-model="editRequest.status" placeholder="Status">
          <el-option
            v-for="item in requestStates"
            :key="item.status"
            :label="requestStatusName(item.status)"
            :value="item.status">
          </el-option>
        </el-select>
      </el-form-item>

      <el-divider></el-divider>

      <el-form-item>
        <el-button type="primary" @click="create()">Senden</el-button>
      </el-form-item>
    </el-form>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: 'RequestCreate',
  data() {
    return {
      editRequest: {
        title: '',
        customer: '',
        car: '',
        status: 'REQUESTED',
        type: 'DIRECT',
        description: '',
        loading: false
      },
      filteredCustomers: [],
      carMode: 'SELECT',
      test: '',
      editCar: {
        firstRegistration: '',
        manufacturer: '',
        model: '',
        vin: '',
        customer: ''
      },
    }
  },
  methods: {
    create () {
      if (this.carMode === 'SELECT') {
        this.$store.commit('SET_BUSY', true)
        this.$store.dispatch('requests/create', this.editRequest).then(() => {
          this.$store.dispatch('requests/getActive').then(() => {
            this.$store.commit('SET_BUSY', false)
          })
        })
      } else {
        this.editCar.customer = this.editRequest.customer
        this.$store.commit('SET_BUSY', true)
        this.$store.dispatch('cars/create', this.editCar).then((res) => {
          this.editRequest.car = res
          this.$store.dispatch('requests/create', this.editRequest).then(() => {
            this.$store.dispatch('requests/getActive').then(() => {
              this.$store.commit('SET_BUSY', false)
            })
          })
        })
      }
    },
    updateRequest () {
      this.$store.commit('requests/UPDATE_REQUEST', this.editRequest)
    },
    searchCustomer (query) {
      if (query) {
        if (query.length > 2) {
          this.filteredCustomers = this.customers.filter(o => (o.firstName + ' ' + o.lastName).toLowerCase().includes(query))
        }
      } else {
        this.filteredCustomers = []
      }
    },
    onCustomerSelect () {
      this.editRequest.car = ''
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('cars/getByCustomer', this.editRequest.customer).then(() => {
        this.$store.commit('SET_BUSY', false)
      })
    }
  },
  computed: {
    ...mapGetters({
      API: 'API',
      requests: 'requests/requests',
      customers: 'customers/customers',
      selectedRequest: 'requests/selectedRequest',
      requestsByStatus: 'requests/requestsByStatus',
      requestStates: 'requests/requestStates',
      requestStatusName: 'requests/requestStatusName',
      customerCars: 'cars/customerCars',
    }),
  }
}
</script>

