<template>
  <div class="station-fiasco-package" v-if="editStation">
    <el-form
      class="request-form"
      ref="form"
      :model="editStation"
      label-width="260px"
    >
      <el-form-item label="FIASCO Leistungspaket">
        <el-select
          v-model="editStation.fiascoPackage.package"
          placeholder="Wählen"
          :disabled="!user.isFiasco"
        >
          <el-option
            v-for="item in fiascoPackageLevels"
            :key="item"
            :label="item"
            :value="item"
          >
            <div>{{ item }}</div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="FIASCO Kalkulationen">
        <div class="station-chart">
          <BarChart
            :chartdata="fiascoCalculationsYearChartData"
            :max="fiascoCalculationsChartMaximum"
          />
        </div>
      </el-form-item>
      <el-form-item label="FIASCO Kalkulationen pro Monat" v-if="user.isFiasco">
        {{
          fiascoCalculationsPerMonth({ station: selectedStation._id })[
            new Date().getMonth()
          ]
        }}
        / {{ editStation.fiascoPackage.monthlyCalculations }}
        <br />
        <el-input-number
          v-model="editStation.fiascoPackage.monthlyCalculations"
          :precision="2"
          :min="0"
          :max="200"
          :v-if="user.isFiasco && user.rights.includes('EDIT_STATIONS')"
        />
      </el-form-item>
      <el-form-item label="Kunden-SMS pro Tag" v-if="user.isFiasco">
        <br />
        <el-input-number
          v-model="editStation.fiascoPackage.dailySms"
          :precision="2"
          :min="0"
          :max="200"
          :v-if="user.isFiasco && user.rights.includes('EDIT_STATIONS')"
        />
      </el-form-item>
      <el-form-item label="Kontakt">
        <el-radio v-model="separateBillingAddress" :label="false"
          >Anschrift ist Rechnungsadresse</el-radio
        >
        <el-radio v-model="separateBillingAddress" :label="true"
          >Abweichende Rechnungsadresse</el-radio
        >
      </el-form-item>
      <div v-if="separateBillingAddress">
        <el-form-item label="Firma">
          <el-input
            v-model="editStation.billingData.company"
            :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
          />
        </el-form-item>
        <el-form-item label="Adresse">
          <el-input
            v-model="editStation.billingData.address"
            :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
          />
        </el-form-item>
        <el-form-item label="PLZ">
          <el-input
            v-model="editStation.billingData.zip"
            :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
          />
        </el-form-item>
        <el-form-item label="Ort">
          <el-input
            v-model="editStation.billingData.city"
            :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
          />
        </el-form-item>
      </div>
      <div v-if="!separateBillingAddress">
        <el-form-item label="Firma">
          <span>{{ editStation.name }}</span>
        </el-form-item>
        <el-form-item label="Adresse">
          <span>{{ editStation.address }}</span>
        </el-form-item>
        <el-form-item label="PLZ">
          <span>{{ editStation.zip }}</span>
        </el-form-item>
        <el-form-item label="Ort">
          <span>{{ editStation.city }}</span>
        </el-form-item>
      </div>
      <el-form-item label="E-Mail für Rechnungen">
        <el-input
          v-model="editStation.billingData.email"
          :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
        />
      </el-form-item>
      <el-form-item label="UmSt. ID">
        <el-input
          v-model="editStation.billingData.vatId"
          :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
        />
      </el-form-item>
      <el-form-item label="Steuer Nr.">
        <el-input
          v-model="editStation.billingData.taxNumber"
          :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')"
        />
      </el-form-item>

      <el-form-item v-if="user.rights.includes('EDIT_STATIONS')">
        <el-button type="primary" @click="update">Speichern</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BarChart from "./BarChart.vue";

export default {
  name: "StationFiascoPackage",
  data() {
    return {
      changeTimeout: null,
      message: "",
      editStation: null,
      separateBillingAddress: false,
    };
  },
  components: {
    BarChart,
  },
  methods: {
    update() {
      this.$store.commit("SET_BUSY", true);
      if (!this.separateBillingAddress) {
        this.editStation.billingData.company = this.editStation.name;
        this.editStation.billingData.city = this.editStation.city;
        this.editStation.billingData.address = this.editStation.address;
        this.editStation.billingData.zip = this.editStation.zip;
      }
      this.$store.dispatch("stations/update", this.editStation).then(() => {
        this.$store.dispatch("stations/getAll").then(() => {
          this.$store.commit(
            "stations/SET_SELECTED_STATION",
            this.stations.find((o) => o._id === this.editStation._id)
          );
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getEditData() {
      console.log("getEditData");
      this.editStation = JSON.parse(JSON.stringify(this.selectedStation));
      this.getStationActions();
      if (!this.editStation.audatexData) {
        this.editStation.audatexData = {
          orgaId: "",
          user: "",
          password: "",
        };
      }
      if (!this.editStation.billingData) {
        this.editStation.billingData = {};
      }
    },
    getStationActions() {
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("stations/getActionsByStation", this.editStation._id)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
        });
    },
  },
  computed: {
    fiascoCalculationsYearChartData() {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mrz",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez",
        ],
        datasets: [
          {
            backgroundColor: "#3985c5",
            data: this.fiascoCalculationsPerMonth({
              station: this.selectedStation._id,
            }),
          },
        ],
      };
    },
    fiascoCalculationsChartMaximum() {
      let payload = {
        station: this.selectedStation._id,
      };
      let max = this.fiascoCalculationsMaxByYear(payload);
      let monthlyFree = this.stations.find(
        (o) => o._id === this.selectedStation._id
      ).fiascoPackage.monthlyCalculations;
      if (max > monthlyFree) {
        return max;
      } else {
        return monthlyFree;
      }
    },
    ...mapGetters({
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      user: "users/user",
      selectedStation: "stations/selectedStation",
      stations: "stations/stations",
      stationActions: "stations/actions",
      fiascoCalculationsPerMonth: "offers/fiascoCalculationsPerMonth",
      fiascoCalculationsMaxByYear: "offers/fiascoCalculationsMaxByYear",
      fiascoPackageLevels: "stations/fiascoPackageLevels",
    }),
  },
  watch: {
    $route() {
      if (this.selectedStation) {
        this.getEditData();
      }
    },
  },
  mounted() {
    console.log("audatex data mounted");
    this.getEditData();
  },
};
</script>

