<template>
  <div class="car-details" v-if="editCar">
    <el-form
      class="request-form"
      ref="form"
      :model="editCar"
      label-width="160px"
    >
      <el-form-item label="Hersteller">
        <el-input
          v-model="editCar.manufacturer"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Modell">
        <el-input
          v-model="editCar.model"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Erstzulassung">
        <el-input
          v-model="editCar.firstRegistration"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="VIN">
        <el-input
          v-model="editCar.vin"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="HSN TSN">
        <el-input
          v-model="editCar.hsnTsn"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Nummernschild">
        <el-input
          v-model="editCar.numberPlate"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <!--el-form-item label="Bild">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-image :src="API.imageUrl + 'cars/' + editCar.image"/>
          </el-col>
          <el-col :span="6" >
            <el-upload
              v-if="user.rights.includes('EDIT_CUSTOMERS')"
              drag
              :action="API.baseUrl + 'cars/upload'"
              :data="{
                id: editCar._id
              }"
              :headers="axiosConfig.headers"
              :on-success="onUploadLogoSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Bild hierhin ziehen oder <em>klicken zum Hochladen</em></div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form-item-->
      <el-form-item>
        <el-button
          type="primary"
          @click="update"
          v-if="user.rights.includes('EDIT_CUSTOMERS')"
        >
          Speichern
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Car",
  data() {
    return {
      editCar: null,
    };
  },
  methods: {
    update() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("cars/update", this.editCar).then(() => {
        this.$store
          .dispatch("cars/getByCustomer", this.selectedCustomer._id)
          .then(() => {
            this.getSelectedData();
            this.$store.commit("SET_BUSY", false);
          });
      });
    },
    getSelectedData() {
      if (this.$route.params.id) {
        this.editCar = JSON.parse(
          JSON.stringify(
            this.customerCars.find((o) => o._id === this.$route.params.id)
          )
        );
      }
    },
    onUploadLogoSuccess(response) {
      console.log(response);
      this.editCar.image = response;
      this.update();
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      axiosConfig: "axiosConfig",
      user: "users/user",
      stations: "stations/stations",
      selectedCustomer: "customers/selectedCustomer",
      customerCars: "cars/customerCars",
    }),
  },
  mounted() {
    console.log("CAR DETAILS MOUNTED");
    this.getSelectedData();
  },
};
</script>
