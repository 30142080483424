<template>
  <div class="station-audatex-data" v-if="editStation">
    <el-form
      class="request-form"
      ref="form"
      :model="editStation"
      label-width="160px"
    >
      <el-form-item label="Verwendetes DMS">
        <el-select
          v-model="editStation.dms"
          placeholder="Wählen"
          :disabled="!user.rights.includes('EDIT_STATIONS')"
        >
          <el-option
            v-for="item in dmsSystems"
            :key="item"
            :label="item"
            :value="item"
          >
            <div>{{ item }}</div>
          </el-option>
        </el-select>
      </el-form-item>
      <div v-if="editStation.dms === 'AUDATEX'">
        <el-form-item label="">
          <strong>Audatex</strong>
        </el-form-item>
        <el-form-item label="OrgaId">
          <el-input
            v-model="editStation.audatexData.orgaId"
            :disabled="!user.rights.includes('EDIT_STATIONS')"
          ></el-input>
        </el-form-item>
        <el-form-item label="Benutzer">
          <el-input
            v-model="editStation.audatexData.user"
            :disabled="!user.rights.includes('EDIT_STATIONS')"
          ></el-input>
        </el-form-item>
        <el-form-item label="Passwort">
          <el-input
            v-model="editStation.audatexData.password"
            :disabled="!user.rights.includes('EDIT_STATIONS')"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item v-if="user.rights.includes('EDIT_STATIONS')">
        <el-button type="primary" @click="update">Speichern</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StationDmsData",
  data() {
    return {
      changeTimeout: null,
      message: "",
      editStation: null,
      dmsSystems: ["NONE", "AUDATEX", "DAT", "KSR_AUDATEX", "KSR_DAT"],
    };
  },
  methods: {
    update() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("stations/update", this.editStation).then(() => {
        this.$store.dispatch("stations/getAll").then(() => {
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getEditData() {
      console.log("getEditData");
      let station = this.stations.find(
        (o) => o._id === this.selectedStation._id
      );
      // let station = this.selectedStation
      this.editStation = JSON.parse(JSON.stringify(station));
      if (!this.editStation.audatexData) {
        this.editStation.audatexData = {
          orgaId: "",
          user: "",
          password: "",
        };
      }
    },
  },
  computed: {
    ...mapGetters({
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      user: "users/user",
      selectedStation: "stations/selectedStation",
      stations: "stations/stations",
    }),
  },
  watch: {
    $route() {
      if (this.selectedStation) {
        this.getEditData();
      }
    },
  },
  mounted() {
    console.log("audatex data mounted");
    this.getEditData();
  },
};
</script>

