<template>
  <div class="station-details" v-if="editStation">
    <el-form class="request-form" ref="form" :model="editStation" label-width="160px">
      <el-form-item label="Name">
        <el-input v-model="editStation.name" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Stadt">
        <el-input v-model="editStation.city" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="PLZ">
        <el-input v-model="editStation.zip" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Adresse">
        <el-input v-model="editStation.address" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Telefon">
        <el-input v-model="editStation.phone" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="E-Mail">
        <el-input v-model="editStation.email" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Web">
        <el-input v-model="editStation.web" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Facebook">
        <el-input v-model="editStation.facebook" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Instagram">
        <el-input v-model="editStation.instagram" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Twitter">
        <el-input v-model="editStation.twitter" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Einladungs-Code">
        <el-input v-model="editStation.inviteCode" :disabled="!user.rights.includes('EDIT_STATIONS')"></el-input>
      </el-form-item>
      <el-form-item label="Ansprechpartner">
        <el-select 
        v-model="editStation.contactPerson" class="wide" placeholder="Wählen" :disabled="!user.rights.includes('EDIT_STATIONS')">
          <el-option
            v-for="item in users"
            :key="item._id"
            :label="item.firstName + ' ' + item.lastName"
            :value="item._id">
            <div>{{item.firstName + ' ' + item.lastName}}</div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Services">
        <el-checkbox-group v-model="editStation.services" :disabled="!user.rights.includes('EDIT_STATIONS')">
          <el-checkbox label="PICKUP" name="contact_type">Hol- und Bring</el-checkbox>
          <el-checkbox label="CLEANING" name="contact_type">Innen- und Außenreinigung</el-checkbox>
          <el-checkbox label="FREE_RENTAL_CAR" name="contact_type">Mietwagen kostenfrei</el-checkbox>
          <el-checkbox label="WARRANTY_3_YEARS" name="contact_type">3 Jahre Garantie</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="Zertifikate">
        <el-checkbox-group v-model="editStation.certificates" :disabled="!user.isFiasco || !user.rights.includes('EDIT_STATIONS')">
          <el-checkbox label="FIASCO" name="contact_type">FIASCO</el-checkbox>
          <el-checkbox label="DEKRA" name="contact_type">Dekra</el-checkbox>
          <el-checkbox label="ISO" name="contact_type">ISO</el-checkbox>
          <el-checkbox label="EUROGARANT" name="contact_type">Eurogarant</el-checkbox>
          <el-checkbox label="ZKF" name="contact_type">ZKF</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="Logo">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-image :src="API.imageUrl + 'stations/logos/' + editStation.logoImage" v-if="!editStation.s3LogoImage"/>
            <S3Image class="request-details-image" :image="editStation.s3LogoImage" v-if="editStation.s3LogoImage"/>
          </el-col>
          <el-col :span="6" >
            <el-upload
              v-if="user.rights.includes('EDIT_STATIONS')"
              drag
              :action="API.baseUrl + 'uploads/image-s3'"
              :data="{
                key: 'images/stations/' + editStation._id + '-logo'
              }"
              :headers="axiosConfig.headers"
              :on-success="onUploadLogoSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Bild hierhin ziehen oder <em>klicken zum Hochladen</em></div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="Posterbilder">
        <el-row :gutter="20">
          <el-col v-for="(item, index) in editStation.posterImages" :key="index" :span="6">
            <el-image :src="API.imageUrl + 'stations/posters/' + item"/>
            <el-button size="mini" icon="el-icon-delete" circle @click="deletePoster($event, item)"></el-button>
          </el-col>
          <el-col v-for="(item, index) in editStation.s3PosterImages" :key="index" :span="6">
            <S3Image class="request-details-image" :image="item"/>
            <el-button size="mini" icon="el-icon-delete" circle @click="deletePoster($event, item)"></el-button>
          </el-col>
          <el-col :span="6">
            <el-upload
              v-if="(editStation.posterImages.length + editStation.s3PosterImages.length) < 3 && user.rights.includes('EDIT_STATIONS')"
              drag
              :action="API.baseUrl + 'uploads/image-s3'"
              :data="{
                key: 'images/stations/' + editStation._id + '-' + editStation.s3PosterImages.length
              }"
              :headers="axiosConfig.headers"
              :on-success="onUploadPosterSuccess"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Bild hierhin ziehen oder <em>klicken zum Hochladen</em></div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item v-if="user.rights.includes('EDIT_STATIONS')">
        <el-button type="primary" @click="update">Speichern</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'Station',
  data() {
    return {
      editStation: null
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'Request'})
    },
    goDetails (row) {
      console.log(row)
      this.$router.push({name: 'Request'})
    },
    update () {
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('stations/update', this.editStation).then(() => {
        this.$store.dispatch('stations/getAll').then(() => {
          this.$store.commit('stations/SET_SELECTED_STATION', this.editStation)
          this.getSelectedData()
          this.$store.commit('SET_BUSY', false)
        })
      })
    },
    getSelectedData () {
      /*
      if (this.$route.params.id) {
        this.editStation = JSON.parse(JSON.stringify(this.stations.find(o => o._id === this.$route.params.id)))
      }
      */
      console.log('STATION DETAILS getSelectedData')
      this.editStation = JSON.parse(JSON.stringify(this.selectedStation))
    },
    onUploadLogoSuccess (response) {
      console.log(response)
      this.editStation.s3LogoImage = response
      this.update()
    },
    onUploadPosterSuccess (response) {
      console.log(response)
      this.editStation.s3PosterImages.push(response)
      this.update()
    },
    deletePoster (event, item) {
      console.log(event)
      console.log(item)
      this.$store.commit('SET_BUSY', true)
      this.editStation.posterImages = this.editStation.posterImages.filter(o => o !== item)
      this.editStation.s3PosterImages = this.editStation.s3PosterImages.filter(o => o !== item)
      this.$store.dispatch('stations/update', this.editStation).then(() => {
        this.$store.dispatch('stations/getAll').then(() => {
          this.getSelectedData()
          this.$store.commit('SET_BUSY', false)
        })
      })
      /*
      let payload = {
        file: item
      } 
      this.$store.dispatch('stations/deletePoster', payload).then((res) => { 
        console.log(res)
      })
      */
    },
    fiascoCalculationsPerMonth (month) {
      return this.fiascoCalculationsByStation({station: this.editStation._id})
        .filter(o => o.request.statusHistory
        .find(o => moment(o.changedAt).isSame('2021-' + month + '-01', 'month'))).length
      // return moment(date).isSame('2021-06-01', 'month')
    }
  },
  computed: {
    stationUsers () {
      return this.users.filter(o => o.station === this.selectedStation._id)
    },
    ...mapGetters({
      API: 'API',
      user: 'users/user',
      users: 'users/users',
      axiosConfig: 'axiosConfig',
      stations: 'stations/stations',
      colleagues: 'users/colleagues',
      offers: 'offers/activeOffers',
      fiascoCalculationsByStation: 'offers/fiascoCalculationsByStation',
      selectedStation: 'stations/selectedStation'
    }),
  },
  watch: {
    $route () {
      if (this.selectedStation) {
        this.getSelectedData()
      }
    }
  },
  mounted () {
    console.log('STATION DETAILS MOUNTED')
    this.getSelectedData()
  },
}
</script>
