import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import elementLocale from 'element-plus/lib/locale/lang/de'

import moment from 'moment';
require('moment/locale/de');
moment.locale('de')

import 'element-plus/lib/theme-chalk/index.css'
// import './assets/css/element-variables.scss'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

import S3Avatar from './components/S3Avatar'
import S3Image from './components/S3Image'
import EditCarPopup from './components/EditCarPopup'

const app = createApp(App)
  .use(ElementPlus, { elementLocale })
  .use(router)
  .use(store)
  .use(PerfectScrollbar)
  .component('S3Avatar', S3Avatar)
  .component('S3Image', S3Image)
  .component('EditCarPopup', EditCarPopup)

app.config.globalProperties.$moment = moment

// Temporarily disable console warnings.
// app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

app.mount('#app')
