<template>
  <div class="request-details" v-if="editOffer">
    <el-form
      class="request-form"
      ref="form"
      :model="editRequest"
      label-width="160px"
    >
      <el-form-item label="Ansprechpartner">
        <!--div class="avatar-item" v-if="selectedContactPerson">
          <el-avatar size="large" :src="API.imageUrl + 'users/' + selectedContactPerson.image"></el-avatar>
          <span>{{selectedContactPerson.firstName}} {{selectedContactPerson.lastName}}</span>
        </div-->
        <el-select
          class="wide"
          v-model="editOffer.contactPerson"
          placeholder="Ansprechpartner wählen"
        >
          <el-option
            class="select-avatars"
            v-for="item in colleagues"
            :key="item._id"
            :label="item.firstName + ' ' + item.lastName"
            :value="item._id"
          >
            <div class="select-avatars-item">
              <el-avatar
                :src="API.imageUrl + 'users/' + item.image"
              ></el-avatar>
              <div>
                <div>{{ item.firstName + " " + item.lastName }}</div>
                <div class="sub">{{ item.email }}</div>
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Kalkulationsstufe">
        <el-select
          class="wide"
          v-model="editOffer.calculationLevel"
          placeholder="Zuverlässigkeit wählen"
        >
          <el-option
            class="select-avatars"
            v-for="item in ['A', 'B', 'C']"
            :key="item"
            :label="calculationLevelCaption(item)"
            :value="item"
          >
            {{ calculationLevelCaption(item) }}
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Services">
        <el-checkbox-group
          v-model="editOffer.services"
          :disabled="!user.rights.includes('SET_SERVICE')"
        >
          <el-checkbox label="PICKUP" name="service"
            >Hol- und Bring</el-checkbox
          >
          <el-checkbox label="CLEANING" name="service"
            >Innen- und Außenreinigung</el-checkbox
          >
          <el-checkbox label="FREE_RENTAL_CAR" name="service"
            >Mietwagen kostenfrei</el-checkbox
          >
          <el-checkbox label="WARRANTY_3_YEARS" name="service"
            >3 Jahre Garantie</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="updateOffer()"
          >Änderungen speichern</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "RequestOffer",
  data() {
    return {
      editRequest: null,
      editOffer: null,
      selectedContactPerson: null,
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    goBack() {
      this.$router.push({ name: "Requests" });
    },
    updateOffer() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("offers/update", this.editOffer).then(() => {
        this.$store.dispatch("offers/getAll").then(() => {
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getEditData() {
      console.log("getEditData");
      // let request = this.requests.find(o => o._id === this.$route.params.id)
      // console.log(request)
      // this.$store.commit('requests/SET_SELECTED_REQUEST', request)
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.editOffer = JSON.parse(
        JSON.stringify(
          this.activeOffers.find((o) => o._id === this.editRequest.offer)
        )
      );
      this.selectedContactPerson = this.colleagues.find(
        (o) => o._id == this.editOffer.contactPerson._id
      );
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      axiosConfig: "axiosConfig",
      user: "users/user",
      users: "users/users",
      colleagues: "users/colleagues",
      userId: "users/userId",
      activeOffers: "offers/activeOffers",
      calculationLevelCaption: "offers/calculationLevelCaption",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestsByStatus: "requests/requestsByStatus",
      requestStates: "requests/requestStates",
      requestStatusName: "requests/requestStatusName",
      damageLocations: "requests/damageLocations",
      damageTypes: "requests/damageTypes",
      damageTypeName: "requests/damageTypeName",
      damageLocationName: "requests/damageLocationName",
    }),
  },
  watch: {
    /*
    requests: {
      handler: function (newVal, oldVal) {
        console.log('WATCH selectedRequest')
        console.log(newVal)
        console.log(oldVal)
        this.editRequest = JSON.parse(JSON.stringify(newVal))
      },
      deep: true
    }
    */
  },
  mounted() {
    console.log("REQUEST DETAILS MOUNTED");
    this.getEditData();
  },
};
</script>

