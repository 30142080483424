import axios from 'axios'

import { createStore } from "vuex"
import users from './modules/users'
import requests from './modules/requests'
import stations from './modules/stations'
import customers from './modules/customers'
import cars from './modules/cars'
import offers from './modules/offers'
import roles from './modules/roles'
import verificationCodes from './modules/verificationCodes'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  state: {
    API: {
      // baseUrl: 'http://localhost:3004/',
      // baseURL: 'http://localhost:3004/',
      // imageUrl: 'http://localhost:3004/images/',
      //fiascoAudatexPassword: 'demo',
      //calcBridgeUrl: 'http://localhost:3006/v1/',

      fiascoAudatexUser: 'Admin.Fiasco@audatex.de',
      fiascoAudatexPassword: 'XZDqD3xw', // TODO: This must be saved in an environment variable.
      baseUrl: 'https://api.fiasco.de/',
      baseURL: 'https://api.fiasco.de/',
      imageUrl: 'https://api.fiasco.de/images/',
    },
    CHANGE_TIMEOUT_MS: 300,
    axiosConfig: {},
    busy: false,
    expressLinkVisible: false,
    lastUpdateCall: null,
    newDataAvailable: false,
  },
  getters: {
    API: state => state.API,
    axiosConfig: state => state.axiosConfig,
    busy: state => state.busy,
    expressLinkVisible: state => state.expressLinkVisible,
    lastUpdateCall: state => state.lastUpdateCall,
    newDataAvailable: state => state.newDataAvailable,
    CHANGE_TIMEOUT_MS: state => state.CHANGE_TIMEOUT_MS,
  },
  actions: {
    getS3Image(context, payload) {
      return axios.get(context.rootGetters.API.baseUrl + 'uploads/image-s3/' + encodeURIComponent(payload), context.rootGetters.axiosConfig)
        .then((res) => {
          return res.data
        })
        .catch((error) => console.log(error))
    }
  },
  mutations: {
    SET_BUSY(state, data) {
      state.busy = data
    },
    SET_TOKEN(state, data) {
      state.axiosConfig = {
        headers: { 'Authorization': 'Bearer ' + data }
      }
      console.log(state.axiosConfig)
    },
    SET_STEP(state, data) {
      state.step = data
    },
    SET_EXPRESS_LINK_VISIBLE(state, data) {
      state.expressLinkVisible = data
    },
    RESET_LAST_UPDATE_CALL(state) {
      state.lastUpdateCall = new Date()
    },
    SET_NEW_DATA_AVAILABLE(state, data) {
      state.newDataAvailable = data
    }
  },
  modules: {
    users,
    requests,
    stations,
    customers,
    cars,
    offers,
    roles,
    verificationCodes
  },
  strict: debug
})

export default store
