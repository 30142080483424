<template>
  <div
    class="request-status-switch"
    v-if="editRequest"
    :class="{ fiasco: user.isFiasco }"
  >
    <el-collapse v-model="collapseSections" v-if="user.isFiasco">
      <el-collapse-item
        :title="'Status: ' + requestStatusName(editRequest.status)"
        name="status"
      >
        <el-timeline>
          <el-timeline-item
            v-for="item in editRequest.statusHistory"
            :key="item._id"
            :timestamp="formatDate(item.changedAt)"
          >
            <span v-if="item.status"
              >{{ requestStatusName(item.status) }}
            </span>
            <div v-if="item.user">
              ({{ item.user.firstName + " " + item.user.lastName }})
            </div>
            <div v-if="item.customer">
              ({{ item.customer.firstName + " " + item.customer.lastName }})
            </div>
          </el-timeline-item>
        </el-timeline>
      </el-collapse-item>
    </el-collapse>

    <div v-if="this.nextSteps(editRequest.status)">
      <div
        class="
          request-status-switch-actions request-status-switch-actions-station
        "
        v-if="stationStepsAvailable"
      >
        <el-tooltip
          effect="dark"
          :content="item.tooltip ? item.tooltip : ''"
          :disabled="!item.tooltip"
          placement="top"
          v-for="(item, index) in stationSteps(editRequest.status)"
          :key="index + '-tt'"
        >
          <el-button type="primary" @click="onStatusSelect(item.status)">
            {{ item.actionName }}
          </el-button>
        </el-tooltip>
      </div>

      <div class="request-status-switch-actions" v-if="fiascoStepsAvailable">
        <span class="request-status-switch-label">FIASCO-Aktionen: &nbsp;</span>
        <el-button
          type="primary"
          v-for="(item, index) in fiascoSteps(editRequest.status)"
          :key="index"
          @click="onStatusSelect(item.status)"
        >
          {{ item.actionName }}
        </el-button>
        &nbsp;
        <el-popconfirm
          confirmButtonText="OK"
          cancelButtonText="Abbrechen"
          title="Anfrage zurücksetzen?"
          :hideIcon="true"
          @confirm="onStatusSelect('RESET')"
        >
          <template #reference>
            <el-button size="small">Reset</el-button>
          </template>
        </el-popconfirm>
      </div>

      <div class="request-status-switch-actions" v-if="customerStepsAvailable">
        <span class="request-status-switch-label">Kunden-Aktionen: &nbsp;</span>
        <el-button
          type="primary"
          v-for="(item, index) in customerSteps(editRequest.status)"
          :key="index"
          @click="onStatusSelect(item.status)"
        >
          {{ item.actionName }}
        </el-button>
      </div>
    </div>

    <el-dialog
      title="Angebot senden"
      v-model="offerDialogVisible"
      width="560px"
    >
      <el-form
        class="request-form"
        ref="form"
        :model="editRequest"
        label-width="160px"
        v-if="editOffer"
      >
        <el-form-item label="Ansprechpartner">
          <el-select
            class="wide"
            v-model="editOffer.contactPerson"
            placeholder="Ansprechpartner wählen"
          >
            <el-option
              class="select-avatars"
              v-for="item in colleagues"
              :key="item._id"
              :label="item.firstName + ' ' + item.lastName"
              :value="item._id"
            >
              <div class="select-avatars-item">
                <el-avatar
                  :src="API.imageUrl + 'users/' + item.image"
                ></el-avatar>
                <div>
                  <div>{{ item.firstName + " " + item.lastName }}</div>
                  <div class="sub">{{ item.email }}</div>
                </div>
              </div>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Kalkulationsstufe">
          <el-select
            class="wide"
            v-model="editOffer.calculation.calculationLevel"
            placeholder="Zuverlässigkeit wählen"
            v-if="!editOffer.calculation.useLumpSumPrice"
          >
            <el-option
              class="select-avatars"
              v-for="item in ['A', 'B', 'C']"
              :key="item"
              :label="calculationLevelCaption(item)"
              :value="item"
            >
              {{ calculationLevelCaption(item) }}
            </el-option>
          </el-select>
          <span v-if="editOffer.calculation.useLumpSumPrice"> Fixpreis </span>
        </el-form-item>

        <el-form-item label="Services">
          <el-checkbox-group
            v-model="editOffer.services"
            :disabled="!user.rights.includes('SET_SERVICE')"
          >
            <el-checkbox label="PICKUP" name="service"
              >Hol- und Bring</el-checkbox
            >
            <el-checkbox label="CLEANING" name="service"
              >Innen- und Außenreinigung</el-checkbox
            >
            <el-checkbox label="FREE_RENTAL_CAR" name="service"
              >Mietwagen kostenfrei</el-checkbox
            >
            <el-checkbox label="WARRANTY_3_YEARS" name="service"
              >3 Jahre Garantie</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="offerDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="commitOffer()"
            >An Kunden senden</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog title="Achtung" v-model="warningDialogVisible" width="560px">
      <p>{{ warning }}</p>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="warningDialogVisible = false">
            OK
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "RequestStatusSwitch",
  data() {
    return {
      collapseSections: [],
      targetStatus: "",
      offerDialogVisible: false,
      editOffer: null,
      selectedContactPerson: null,
      warning: "",
      warningDialogVisible: false,
    };
  },
  props: {
    editRequest: Object,
  },
  mixins: [dateDisplayMixin],
  methods: {
    onStatusSelect(value) {
      console.log("onStatusSelect: " + value);
      switch (value) {
        case "OFFERED":
          this.targetStatus = value;
          this.offerDialogVisible = true;
          break;
        case "FIASCO_CALCULATION_REQUESTED":
          if (this.editRequest.car?.vin?.length != 17) {
            this.warning =
              "FIASCO benötigt die VIN (17 Stellen) des Fahrzeugs.";
            this.warningDialogVisible = true;
          } else {
            this.$emit("select", value);
          }
          break;
        default:
          this.$emit("select", value);
      }
    },
    commitOffer() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("offers/update", this.editOffer).then(() => {
        this.$store.dispatch("offers/getAll").then(() => {
          this.$emit("select", this.targetStatus);
          this.targetStatus = "";
          this.offerDialogVisible = false;
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    steps(status) {
      let next = [];
      this.nextSteps(status).forEach((step) => {
        next.push(this.requestStates.find((o) => o.status === step));
      });
      return next;
    },
    fiascoSteps(status) {
      let next = [];
      this.nextSteps(status).forEach((step) => {
        next.push(this.requestStates.find((o) => o.status === step));
      });
      return next.filter((o) => o.fiascoAction);
    },
    stationSteps(status) {
      let next = [];
      this.nextSteps(status).forEach((step) => {
        next.push(this.requestStates.find((o) => o.status === step));
      });
      return next.filter((o) => o.stationAction);
    },
    customerSteps(status) {
      let next = [];
      this.nextSteps(status).forEach((step) => {
        next.push(this.requestStates.find((o) => o.status === step));
      });
      return next.filter((o) => o.customerAction);
    },
    getEditData() {
      if (this.activeOffers && this.editRequest.offer) {
        this.editOffer = JSON.parse(
          JSON.stringify(
            this.activeOffers.find((o) => o._id === this.editRequest.offer)
          )
        );
        if (this.colleagues) {
          this.selectedContactPerson = this.colleagues.find(
            (o) => o._id == this.editOffer.contactPerson._id
          );
        }
      }
    },
  },
  computed: {
    stationStepsAvailable() {
      let available = false;
      if (!this.user.isFiasco) available = true;
      if (this.user.isFiasco)
        available = this.user.rights.some(
          (o) => o === "EXECUTE_STATION_ACTIONS"
        );
      return available;
    },
    customerStepsAvailable() {
      let available = false;
      if (!this.user.isFiasco) available = false;
      if (this.user.isFiasco)
        available = this.user.rights.some(
          (o) => o === "EXECUTE_CUSTOMER_ACTIONS"
        );
      return available;
    },
    fiascoStepsAvailable() {
      let available = false;
      if (!this.user.isFiasco) available = false;
      if (this.user.isFiasco) available = true;
      return available;
    },
    ...mapGetters({
      API: "API",
      user: "users/user",
      colleagues: "users/colleagues",
      activeOffers: "offers/activeOffers",
      calculationLevelCaption: "offers/calculationLevelCaption",
      selectedRequest: "requests/selectedRequest",
      nextSteps: "requests/nextSteps",
      requestStates: "requests/requestStates",
      requestStatusName: "requests/requestStatusName",
      requestStatusActionName: "requests/requestStatusActionName",
    }),
  },
  mounted() {
    this.getEditData();
  },
};
</script>
