import { createWebHistory, createRouter } from "vue-router"
import Start from "@/components/Start.vue"
import DirectRequestsInbox from "@/components/DirectRequestsInbox.vue"
import DirectRequestsFeedback from "@/components/DirectRequestsFeedback.vue"
import DirectRequestsOffers from "@/components/DirectRequestsOffers.vue"
import DirectRequestsFiascoCalculations from "@/components/DirectRequestsFiascoCalculations.vue"
import DirectRequestsRepairs from "@/components/DirectRequestsRepairs.vue"
import PoolRequestsInbox from "@/components/PoolRequestsInbox.vue"
import PoolRequestsOffered from "@/components/PoolRequestsOffered.vue"
import PoolRequestsRepairs from "@/components/PoolRequestsRepairs.vue"
import CalculationsInbox from "@/components/CalculationsInbox.vue"
import CalculationsFeedback from "@/components/CalculationsFeedback.vue"
import CalculationsQualified from "@/components/CalculationsQualified.vue"
import CalculationsSent from "@/components/CalculationsSent.vue"
import Placeholder from "@/components/Placeholder.vue"
import Request from "@/components/Request.vue"
import RequestCreate from "@/components/RequestCreate.vue"
import Customers from "@/components/Customers.vue"
import Customer from "@/components/Customer.vue"
import CarDetails from "@/components/CarDetails.vue"
import Users from "@/components/Users.vue"
import User from "@/components/User.vue"
import Stations from "@/components/Stations.vue"
import Station from "@/components/Station.vue"
import Chat from "@/components/Chat.vue"
import Complaints from "@/components/Complaints.vue"
import AdministrationStates from "@/components/AdministrationStates.vue"
import Roles from "@/components/Roles.vue"
import Role from "@/components/Role.vue"
import Verifications from "@/components/Verifications.vue"
import InviteCustomer from "@/components/InviteCustomer.vue"

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
  },
  {
    path: "/direkt-anfragen/eingang",
    name: "DirectRequestsInbox",
    component: DirectRequestsInbox,
  },
  {
    path: "/direkt-anfragen/rueckmeldung",
    name: "DirectRequestsFeedback",
    component: DirectRequestsFeedback,
  },
  {
    path: "/direkt-anfragen/angebote",
    name: "DirectRequestsOffers",
    component: DirectRequestsOffers,
  },
  {
    path: "/direkt-anfragen/fiasco-kalkulationen",
    name: "DirectRequestsFiascoCalculations",
    component: DirectRequestsFiascoCalculations,
  },
  {
    path: "/anfragen/neu",
    name: "RequestCreate",
    component: RequestCreate,
  },
  {
    path: "/direkt-anfragen/reparaturen",
    name: "DirectRequestsRepairs",
    component: DirectRequestsRepairs,
  },
  {
    path: "/platzhalter",
    name: "Placeholder",
    component: Placeholder,
  },
  {
    path: "/kalkulationen/eingang",
    name: "CalculationsInbox",
    component: CalculationsInbox,
  },
  {
    path: "/kalkulationen/rueckfrage",
    name: "CalculationsFeedback",
    component: CalculationsFeedback,
  },
  {
    path: "/kalkulationen/vollstaendig",
    name: "CalculationsQualified",
    component: CalculationsQualified,
  },
  {
    path: "/kalkulationen/gesendet",
    name: "CalculationsSent",
    component: CalculationsSent,
  },
  {
    path: "/pool/marktplatz",
    name: "PoolRequestsInbox",
    component: PoolRequestsInbox,
  },
  {
    path: "/pool/angeboten",
    name: "PoolRequestsOffered",
    component: PoolRequestsOffered,
  },
  {
    path: "/pool/reparaturen",
    name: "PoolRequestsRepairs",
    component: PoolRequestsRepairs,
  },
  {
    path: "/anfrage/:id",
    name: "Request",
    component: Request,
  },
  {
    path: "/marketing/einladen",
    name: "InviteCustomer",
    component: InviteCustomer,
  },
  {
    path: "/verwaltung/bestaetigungen",
    name: "Verifications",
    component: Verifications,
  },
  {
    path: "/verwaltung/kunden",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/verwaltung/kunden/:id",
    name: "Customer",
    component: Customer,
  },
  {
    path: "/kunden/:customerId/fahrzeug/:id",
    name: "Car",
    component: CarDetails,
  },
  {
    path: "/verwaltung/werkstaetten",
    name: "Stations",
    component: Stations,
  },
  {
    path: "/verwaltung/meine-werkstatt",
    name: "MyStation",
    component: Station,
  },
  {
    path: "/verwaltung/werkstatten/:id",
    name: "Station",
    component: Station,
  },
  {
    path: "/verwaltung/benutzer",
    name: "Users",
    component: Users,
  },
  {
    path: "/mitarbeiter/:id",
    name: "User",
    component: User,
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
  },
  {
    path: "/reklamationen",
    name: "Complaints",
    component: Complaints,
  },
  {
    path: "/verwaltung/status",
    name: "AdministrationStates",
    component: AdministrationStates,
  },
  {
    path: "/verwaltung/rollen",
    name: "Roles",
    component: Roles,
  },
  {
    path: "/rollen/:id",
    name: "Role",
    component: Role,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router