<template>
  <div class="request-chat" v-if="request">
    <!--div class="request-chat-options">
      <el-radio v-model="viewMode" label="PUBLIC">Öffentlich</el-radio>
      <el-radio v-model="viewMode" label="INTERNAL">Intern</el-radio>
    </div-->
    <img class="request-chat-logo" src="@/assets/gfx/fiasco-chat.png" />
    <div
      class="chat-line"
      v-for="item in filteredMessages"
      :key="item._id"
      :class="item.type.toLowerCase()"
    >
      <div class="chat-line-user" v-if="item.user">
        <el-avatar
          v-if="!item.user.s3Image"
          :size="24"
          :src="API.imageUrl + 'users/' + item.user.image"
        ></el-avatar>
        <S3Avatar v-if="item.user.s3Image" :image="item.user.s3Image" :size="24" />
        <span class="chat-line-user-name"
          >{{ item.user.firstName }} {{ item.user.lastName }}</span
        >
      </div>
      <div class="chat-line-user" v-if="item.customer">
        <el-avatar
          v-if="!item.customer.s3Image"
          :size="24"
          :src="API.imageUrl + 'customers/' + item.customer.image"
        ></el-avatar>
        <S3Avatar
          v-if="item.customer.s3Image"
          :image="item.customer.s3Image"
          :size="24"
        />
        <span class="chat-line-user-name"
          >{{ item.customer.firstName }} {{ item.customer.lastName }}</span
        >
      </div>
      {{ item.content }}
      <div class="chat-line-time">{{ formatDate(item.deliveredAt) }}</div>
    </div>
    <div class="chat-control">
      <el-input
        type="textarea"
        placeholder="Nachricht schreiben..."
        v-model="messageInput"
        @keyup.enter.exact="sendMessage()"
      ></el-input>
      <el-button
        type="primary"
        icon="el-icon-s-promotion"
        circle
        @click="sendMessage()"
      ></el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "RequestChat",
  data() {
    return {
      request: null,
      viewMode: "PUBLIC",
      messageInput: "",
    };
  },
  props: {
    requestId: String,
  },
  methods: {
    sendMessage() {
      let payload = {
        message: this.messageInput,
        requestId: this.requestId,
        type: this.viewMode,
      };
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/sendMessage", payload).then(() => {
        this.$store.dispatch("requests/getActive").then(() => {
          this.getEditData();
          this.messageInput = "";
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getEditData() {
      this.request = this.requests.find((o) => o._id === this.requestId);
    },
    formatDate(date) {
      moment.locale("de");
      return moment(date).format("lll");
    },
  },
  computed: {
    filteredMessages() {
      if (this.viewMode === "INTERNAL") {
        return this.request.messages;
      }
      if (this.viewMode === "PUBLIC") {
        return this.request.messages.filter((o) => o.type === "PUBLIC");
      }
      return [];
    },
    ...mapGetters({
      API: "API",
      userId: "users/userId",
      requests: "requests/requests",
    }),
  },
  watch: {
    requests: {
      deep: true,
      handler: function () {
        this.getEditData();
      },
    },
  },
  mounted() {
    this.getEditData();
  },
};
</script>
