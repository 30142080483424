<template>
  <div class="request-calculation" v-if="editRequest && editOffer">
    <div
      class="request-calculation-inner"
      v-if="editRequest.status == 'FIASCO_CALCULATING' && !user.isFiasco"
    >
      <p>FIASCO kalkuliert, bitte warten...</p>
    </div>

    <div
      class="request-calculation-inner"
      v-if="editRequest.status != 'FIASCO_CALCULATING' || user.isFiasco"
    >
      <!--el-alert
        v-if="!calculationOpen(editRequest)"
        title="Die Kalkulation wurde schon versendet und kann nicht mehr geändert werden."
        type="warning"
        show-icon>
      </el-alert-->

      <el-alert
        v-if="error"
        title="Fehler beim Datentransfer"
        type="error"
        show-icon
      >
      </el-alert>

      <div class="request-calculation-audatex">
        <img
          v-if="
            user.isFiasco ||
            (userStation.dms == 'AUDATEX' &&
              userStation.audatexData?.orgaId &&
              userStation.audatexData?.user &&
              userStation.audatexData?.password) ||
            userStation.dms == 'KSR_AUDATEX'
          "
          class="request-calculation-audatex-logo"
          src="@/assets/gfx/audatex-logo.png"
        />
        <div class="request-calculation-actions">
          <el-button
            type="primary"
            @click="onSendAudatex()"
            v-if="
              !user.isFiasco &&
              !editOffer.audatexStationTaskId &&
              ((userStation.dms == 'AUDATEX' &&
                userStation.audatexData?.orgaId &&
                userStation.audatexData?.user &&
                userStation.audatexData?.password) ||
                userStation.dms == 'KSR_AUDATEX')
            "
          >
            An Audatex senden
          </el-button>
          <el-button
            type="primary"
            @click="goAudatex()"
            v-if="
              (user.isFiasco && editOffer.audatexFiascoTaskId) ||
              (!user.isFiasco && editOffer.audatexStationTaskId)
            "
          >
            In Audatex öffnen
          </el-button>

          <el-tooltip
            effect="dark"
            content="Link kopieren"
            placement="top"
            v-if="
              (user.isFiasco && editOffer.audatexFiascoTaskId) ||
              (!user.isFiasco && editOffer.audatexStationTaskId)
            "
          >
            <el-button @click="copyAudatexLink()" icon="el-icon-document-copy">
            </el-button>
          </el-tooltip>

          <el-button
            type="primary"
            v-if="calculation.text"
            @click="audatexTextVisible = true"
          >
            Kalkulation in Textform
          </el-button>

          <el-button
            type="primary"
            v-if="calculation.text"
            @click="generatePdf()"
          >
            Kalkulation als PDF
          </el-button>
        </div>

        <div v-if="user.isFiasco">
          <div
            class="request-calculation-audatex-id"
            v-if="user.isFiasco && editOffer.audatexFiascoTaskId"
          >
            Fiasco Task Id: {{ editOffer.audatexFiascoTaskId }}
          </div>
          <div
            class="request-calculation-audatex-id"
            v-if="editOffer.audatexStationTaskId"
          >
            Werkstatt Task Id: {{ editOffer.audatexStationTaskId }}
          </div>
        </div>

        <div class="request-calculation-meta">
          <div class="timestamp" v-if="calculationLastImportedAt">
            Zuletzt importiert am:
            {{ $moment(calculationLastImportedAt).format("LLL") }}
          </div>
        </div>
      </div>
      <br />

      <div class="calculation-form" v-if="calculation">
        <div class="calculation-form-positions">
          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>AW pro Stunde</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                :precision="2"
                v-model="calculation.workPackagesPerHour"
                disabled
              >
              </el-input-number>
            </div>
          </div>

          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              <strong>Arbeitslohn</strong>
            </div>
            <div class="calculation-form-position-value">
              <strong>AWs</strong>
            </div>
            <div class="calculation-form-position-sum">
              <strong>Preis</strong>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Mechanik</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                :precision="2"
                v-model="calculation.workloadMechanics"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.workloadMechanics *
                    calculation.hourlyWageMechanics) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.discountMechanics / 100)
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Elektronik</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.workloadElectronics"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.workloadElectronics *
                    calculation.hourlyWageElectronics) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.discountElectronics / 100)
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Karosserie</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.workloadBody"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.workloadBody * calculation.hourlyWageBody) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.discountBody / 100)
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Hagelarbeiten</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.workloadHailDamage"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.workloadHailDamage *
                    calculation.hourlyWageHailDamage) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.hourlyWageHailDamage / 100)
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              <strong>Lackierung</strong>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Lackierarbeiten</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.workloadPaint"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.workloadPaint * calculation.hourlyWagePaint) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.discountPaint / 100)
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Materialkosten</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="50"
                controls-position="right"
                v-model="calculation.materialPaint"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{ formattedPrice(calculation.materialPaint) }}</span>
            </div>
          </div>

          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              <strong>Material</strong>
            </div>
            <div class="calculation-form-position-value"></div>
            <div class="calculation-form-position-sum"></div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Ersatzteile</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="100"
                controls-position="right"
                v-model="calculation.materialParts"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{ formattedPrice(calculation.materialParts) }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Schwemmmaterial</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="10"
                controls-position="right"
                v-model="calculation.additionalCosts.alluvialMaterial"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(calculation.additionalCosts.alluvialMaterial)
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              <strong>Nebenkosten</strong>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Unterbodenschutz</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="10"
                controls-position="right"
                v-model="calculation.additionalCosts.underBodySealant"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(calculation.additionalCosts.underBodySealant)
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Klebematerial</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="10"
                controls-position="right"
                v-model="calculation.additionalCosts.adhesiveSealMaterial"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(calculation.additionalCosts.adhesiveSealMaterial)
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Hohlraumversiegelung, Material</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="10"
                controls-position="right"
                v-model="
                  calculation.additionalCosts.hollowCavitySealantMaterial
                "
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  calculation.additionalCosts.hollowCavitySealantMaterial
                )
              }}</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Hohlraumversiegelung, AW</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="1"
                controls-position="right"
                v-model="calculation.additionalCosts.hollowCavityWorkload"
                :precision="2"
                disabled
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span>{{
                formattedPrice(
                  ((calculation.additionalCosts.hollowCavityWorkload *
                    calculation.hourlyWageBody) /
                    calculation.workPackagesPerHour) *
                    (1 - calculation.discountBody / 100)
                )
              }}</span>
            </div>
          </div>

          <div
            class="calculation-form-position title total"
            :class="{ inactive: calculation.useLumpSumPrice }"
          >
            <div class="calculation-form-position-label">
              <strong>Summe (netto zzgl. UmSt. 19%)</strong>
            </div>
            <div class="calculation-form-position-value"></div>
            <div class="calculation-form-position-sum">
              <strong v-if="calculation.totalPrice">{{
                formattedPrice(calculation.totalPrice)
              }}</strong>
              <strong v-if="!calculation.totalPrice">{{
                formattedPrice(sum(calculation))
              }}</strong>
            </div>
          </div>
          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              oder
              <el-checkbox
                v-model="calculation.useLumpSumPrice"
                @change="onChange()"
              />
              <span :class="{ strong: calculation.useLumpSumPrice }">
                Fixpreis</span
              >
              <span v-if="calculation.useLumpSumPrice"> (netto)</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                :step="50"
                controls-position="right"
                v-if="calculation.useLumpSumPrice"
                v-model="calculation.lumpSumPrice"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
            </div>
            <div class="calculation-form-position-sum">
              <span v-if="calculation.useLumpSumPrice"> €</span>
            </div>
          </div>

          <div class="calculation-form-position title total">
            <div class="calculation-form-position-label">
              <strong>Summe brutto</strong>
            </div>
            <div class="calculation-form-position-value"></div>
            <div
              class="calculation-form-position-sum"
              v-if="!calculation.useLumpSumPrice"
            >
              <strong v-if="calculation.totalPrice">{{
                formattedPrice(calculation.totalPrice * 1.19)
              }}</strong>
              <strong v-if="!calculation.totalPrice">{{
                formattedPrice(sum(calculation) * 1.19)
              }}</strong>
            </div>
            <div
              class="calculation-form-position-sum"
              v-if="calculation.useLumpSumPrice"
            >
              <strong>{{
                formattedPrice(calculation.lumpSumPrice * 1.19)
              }}</strong>
            </div>
          </div>
        </div>
        <div class="calculation-form-fundamentals">
          <div class="calculation-form-position title">
            <div class="calculation-form-position-label">
              <strong>Lohnstufe</strong>
            </div>
            <div class="calculation-form-position-value">
              <strong>Stundensatz</strong>
            </div>
            <div class="calculation-form-position-sum">
              <strong>Rabatt</strong>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Mechanik</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.hourlyWageMechanics"
                precision="2"
                lang="de"
                @change="onChange()"
              >
              </el-input-number>
              <span> €</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.discountMechanics"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Elektronik</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.hourlyWageElectronics"
                precision="2"
                lang="de"
                @change="onChange()"
              >
              </el-input-number>
              <span> €</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.discountElectronics"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Karosserie</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.hourlyWageBody"
                precision="2"
                lang="de"
                @change="onChange()"
              >
              </el-input-number>
              <span> €</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.discountBody"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Hagelarbeiten</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.hourlyWageHailDamage"
                precision="2"
                lang="de"
                @change="onChange()"
              >
              </el-input-number>
              <span> €</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.discountHailDamage"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Lackarbeiten</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.hourlyWagePaint"
                precision="2"
                lang="de"
                @change="onChange()"
              >
              </el-input-number>
              <span> €</span>
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.discountPaint"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <br />

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Ersatzteile</span>
            </div>
            <div class="calculation-form-position-value">
              <el-radio v-model="partsMode" label="DISCOUNT">Rabatt</el-radio>
              <el-radio v-model="partsMode" label="SURCHARGE"
                >Aufschlag</el-radio
              >
            </div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="discountPartsEdit"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Lackiermaterialanteil</span>
            </div>
            <div class="calculation-form-position-value">&nbsp;</div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.additionalCosts.paintMaterialPercentage"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>

          <div class="calculation-form-position">
            <div class="calculation-form-position-label">
              <span>Kleinersatzteile</span>
            </div>
            <div class="calculation-form-position-value">&nbsp;</div>
            <div class="calculation-form-position-value">
              <el-input-number
                controls-position="right"
                v-model="calculation.additionalCosts.smallPartsPercentage"
                :precision="2"
                @change="onChange()"
              >
              </el-input-number>
              <span> %</span>
            </div>
          </div>
        </div>
      </div>
      <br />

      <el-dialog
        title="AUDATEX FIASCO Tasks"
        v-model="audatexFiascoTasksVisible"
        v-if="audatexFiascoTasks"
        width="560px"
      >
        <div
          v-for="item in audatexFiascoTasks"
          :key="item.TaskId"
          @click="getAudatexTaskFromFiascoById(item.TaskId)"
        >
          {{ item.TaskId }}<br />
          {{ item.PlateNumber }}<br />
          {{ formatDate(item.CreationDate) }}
        </div>
      </el-dialog>

      <el-dialog
        title="AUDATEX TEXT"
        v-model="audatexTextVisible"
        width="800px"
      >
        <pre class="calculation-text" v-if="calculation.text">
          {{ calculation.text }}
        </pre>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";
import { jsPDF } from "jspdf";

export default {
  name: "RequestCalculation",
  data() {
    return {
      changeTimeout: null,
      message: "",
      error: false,
      editRequest: null,
      editOffer: null,
      audatexFiascoTasksVisible: false,
      collapseActives: [],
      discountPartsEdit: 0,
      partsMode: "DISCOUNT",
      audatexTextVisible: false,
      calculation: {
        workPackagesPerHour: 12,
        workloadMechanics: 0,
        workloadElectronics: 0,
        workloadBody: 0,
        workloadHailDamage: 0,
        workloadPaint: 0,
        materialParts: 0,
        materialPaint: 0,
        hourlyWageMechanics: 0,
        hourlyWageElectronics: 0,
        hourlyWageBody: 0,
        hourlyWageHailDamage: 0,
        hourlyWagePaint: 0,
        discountMechanics: 0,
        discountElectronics: 0,
        discountBody: 0,
        discountHailDamage: 0,
        discountPaint: 0,
        discountParts: 0,
        additionalCosts: {
          hollowCavitySealantMaterial: 0,
          hollowCavityWorkload: 0,
          adhesiveSealMaterial: 0,
          alluvialMaterial: 0,
          underBodySealant: 0,
          paintMaterialPercentage: 0,
          smallPartsPercentage: 0,
          replacementPartsSurcharge: 0,
          replacementPartsDiscount: 0,
        },
        lumpSumPrice: 0,
        text: "",
      },
      calculationLastImportedAt: null,
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    async generatePdf() {
      this.$store.commit("SET_BUSY", true);
      try {
        // Get the current date as a string "yyyy-mm-dd".
        // (getMonth is 0-based, therefore use "+ 1").
        const now = new Date();
        const currentDate =
          now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate();

        // Build the document title by combining certain strings identifying the request.
        const titleValues = [
          this.editRequest.fiascoId,
          this.editRequest?.car?.numberPlate,
          this.editRequest?.car?.vin,
        ];

        // Some of the values can be null or empty, so let's filter these out.
        // Also, join the remaining values together.
        const titleValuesJoined = titleValues
          .filter((v) => {
            return v != null && v != "" && v != "undefined";
          })
          .join(" - ");

        // Finally, combine the date and title values.
        const docTitle = `${currentDate} - KVA - ${titleValuesJoined}`;
        const docSubject = `KVA - ${titleValuesJoined}`;

        let footer = false;
        let footerLines = [];

        const text = this.calculation.text;
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.getHeight();

        const defaultFontSize = 10;
        const firstLinePos = 7;
        const lineHeight = 4; // TODO: This probably needs to be calcuted based on the font size.

        doc
          .setDocumentProperties({
            title: docTitle,
            subject: docSubject,
            author: "FIASCO GmbH",
            creator: "www.fiasco.de",
          })
          .setDisplayMode("fullpage", "continuous", "UseThumbs")
          .setFont("Courier", "normal")
          .setFontSize(defaultFontSize);

        // Current line position.
        let i = firstLinePos;
        const lines = text.split("\n");
        for await (const line of lines) {
          // Special handling for footer lines.
          if (footer) {
            footerLines.push(
              line.replace("<footer>", "").replace("</footer>", "")
            );
            if (line.trim().endsWith("</footer>")) {
              this.addFooterToPdf(doc, footerLines, i, pageHeight);
              footer = false;
              i = firstLinePos;
            }
            continue;
          }

          // Add a page break automatically when there is too much content on one page.
          if (i > pageHeight) {
            doc.addPage();
            i = firstLinePos;
          }

          // Footer.
          if (line.trim().startsWith("<footer>")) {
            footer = true;
            footerLines.push(
              line.replace("<footer>", "").replace("</footer>", "")
            );
            if (line.trim().endsWith("</footer>")) {
              this.addFooterToPdf(doc, footerLines, i, pageHeight, lineHeight);
              footer = false;
              i = firstLinePos;
              doc.setFontSize(defaultFontSize);
            }
            continue;
          }

          // Manual Page Break.
          if (line.trim() === "---") {
            i = firstLinePos;
            doc.addPage();
            continue;
          }

          // Heading.
          if (line.trim().startsWith("<h1>")) {
            this.addHeaderToPdf(doc, line, i);
            doc.setFontSize(defaultFontSize);
            continue;
          }

          doc.text(line, 20, i);
          i += lineHeight;
        }

        await doc.save(`${docTitle}.pdf`);
      } catch (e) {
        console.log("Error while generating/saving PDF", e);
      }

      this.$store.commit("SET_BUSY", false);
    },
    addHeaderToPdf(doc, line, i) {
      line = line.replace("<h1>", "").replace("</h1>", "");
      doc
        .setFont("Courier", "bold")
        .setFontSize(13)
        .text(line, 10, i)
        .setFont("Courier", "normal");
    },
    addFooterToPdf(doc, footerLines, i, lineHeight) {
      i = 280 - footerLines.length * lineHeight;
      doc.setFontSize(7);
      for (const line of footerLines) {
        doc.text(line, 10, i);
        i += 5;
      }
    },
    onChange() {
      console.log("onChange");
      if (this.changeTimeout) {
        window.clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = window.setTimeout(() => {
        console.log("now we change");
        this.updateCalculation();
        window.clearTimeout(this.changeTimeout);
      }, this.CHANGE_TIMEOUT_MS);
    },
    getEditData() {
      console.log("getEditData");
      // this.editRequest = this.requests.find(o => o._id === this.$route.params.id)
      this.editRequest = this.selectedRequest;
      let offer = this.activeOffers.find(
        (o) => o._id === this.editRequest.offer
      );
      if (offer) {
        this.editOffer = JSON.parse(JSON.stringify(offer));
        // init calculation
        if (this.editOffer.calculation) {
          this.calculation = this.editOffer.calculation;
          if (!this.calculation.additionalCosts) {
            this.calculation.additionalCosts = {
              hollowCavitySealantMaterial: 0,
              hollowCavityWorkload: 0,
              adhesiveSealMaterial: 0,
              alluvialMaterial: 0,
              underBodySealant: 0,
              paintMaterialPercentage: 0,
              smallPartsPercentage: 0,
              replacementPartsSurcharge: 0,
              replacementPartsDiscount: 0,
            };
          }
          if (!this.calculation.additionalCosts.underBodySealant) {
            this.calculation.additionalCosts.underBodySealant = 0;
          }
        } else {
          this.editOffer.calculation = this.calculation;
        }
        if (this.editOffer.calculation.workPackagesPerHour === 0) {
          this.editOffer.calculation.workPackagesPerHour = 12;
        }
        if (this.editOffer.calculationLastImportedAt) {
          this.calculationLastImportedAt =
            this.editOffer.calculationLastImportedAt;
        }
      }
    },
    updateCalculation() {
      this.$store.commit("SET_BUSY", true);
      this.editOffer.calculation = this.calculation;
      console.log("updateCalculation");
      console.log(this.editOffer.calculation);
      if (this.partsMode === "DISCOUNT") {
        this.editOffer.calculation.replacementPartsDiscount =
          this.discountPartsEdit;
      }
      if (this.partsMode === "SURCHARGE") {
        this.editOffer.calculation.replacementPartsSurcharge =
          this.discountPartsEdit;
      }
      this.editOffer.calculation.totalPrice = this.sum(
        this.editOffer.calculation
      );
      this.$store.dispatch("offers/update", this.editOffer).then(() => {
        this.$store.dispatch("offers/getAll").then(() => {
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getFiascoAudatexTasks() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/getFiascoAudatexTasks").then((res) => {
        this.$store.commit("SET_BUSY", false);
        console.log(res);
        this.audatexFiascoTasksVisible = true;
      });
    },
    sendToStationAudatex() {
      let station = this.stations.find(
        (o) => o._id == this.selectedRequest.customer.selectedStation
      );
      if (!station) return;
      if (!station.audatexData.orgaId) {
        return;
      }

      this.$store.commit("SET_BUSY", true);
      let payload = {
        taskId: this.editOffer.audatexFiascoTaskId,
        station: station._id,
      };
      this.$store
        .dispatch("requests/audatexFiascoToStation", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          if (res.success) {
            this.editOffer.audatexStationTaskId = res.taskId;
            this.editOffer.audatexStationCaseId = res.caseId;
            this.$store.dispatch("offers/update", this.editOffer).then(() => {
              this.$store.dispatch("offers/getAll").then(() => {
                this.$store.commit("SET_BUSY", false);
              });
            });
          } else {
            console.log("CALC BRIDGE ERROR");
          }
        });
    },
    getAudatexTaskFromFiascoById(id) {
      this.error = false;
      this.$store.commit("SET_BUSY", true);

      // TODO: Do not send credentials. Send the type of request (fiasco or station) instead.
      let payload = {
        taskId: id,
        user: this.API.fiascoAudatexUser,
        password: this.API.fiascoAudatexPassword,
      };
      this.$store
        .dispatch("requests/getAudatexTaskById", payload)
        .then((res) => {
          console.log(res);
          if (res.success) {
            if (res.task && res.task.calculation) {
              this.editOffer.calculation = {
                ...this.editOffer.calculation,
                ...res.task.calculation,
              };
              console.log(this.editOffer.calculation);
              // this.editOffer.calculation.totalPrice = this.sum(this.editOffer.calculation)
              this.$store.dispatch("offers/update", this.editOffer).then(() => {
                this.$store.dispatch("offers/getAll").then(() => {
                  this.$store.commit("SET_BUSY", false);
                  // TODO: chk if async needed
                  this.sendToStationAudatex();
                });
              });
            } else {
              this.error =
                "Konnte nicht importieren. Audatex-Task hat keine Kalkulation.";
              console.log("HAS NO CALCULATION");
              this.$store.commit("SET_BUSY", false);
            }
          } else {
            this.error =
              "Konnte nicht importieren. Audatex-Task konnte nicht abgerufen werden.";
            console.log(`TASK NOT RETURNED: ${res.message}`);
            this.$store.commit("SET_BUSY", false);
          }
        });
    },
    getAudatexTaskFromStationById(id) {
      this.error = false;
      this.$store.commit("SET_BUSY", true);

      // TODO: Do not send credentials. Send the type of request (fiasco or station) instead.
      let payload = {
        taskId: id,
        user: this.userStation.audatexData.user,
        password: this.userStation.audatexData.password,
      };

      this.$store
        .dispatch("requests/getAudatexTaskById", payload)
        .then((res) => {
          console.log("RESULT FROM getAudatexTaskFromStationById:");
          console.log(res);
          if (res.success) {
            if (res.task && res.task.calculation) {
              this.editOffer.calculation = {
                ...this.editOffer.calculation,
                ...res.task.calculation,
              };
              console.log(this.editOffer.calculation);
              // this.editOffer.calculation.totalPrice = this.sum(this.editOffer.calculation)
              this.$store.dispatch("offers/update", this.editOffer).then(() => {
                this.$store.dispatch("offers/getAll").then(() => {
                  this.$store.commit("SET_BUSY", false);
                });
              });
            } else {
              this.error =
                "Konnte nicht importieren. Audatex-Task hat keine Kalkulation.";
              console.log("HAS NO CALCULATION");
              this.$store.commit("SET_BUSY", false);
            }
          } else {
            this.error =
              "Konnte nicht importieren. Audatex-Task konnte nicht abgerufen werden.";
            console.log(`TASK NOT RETURNED: ${res.message}`);
            this.$store.commit("SET_BUSY", false);
          }
        });
    },
    sendToFiascoAudatex() {
      this.$store.commit("SET_BUSY", true);
      console.log(this.selectedRequest.car);
      let payload = {
        vin: this.selectedRequest.car.vin,
        numberPlate: this.selectedRequest.car.numberPlate,
      };
      this.$store
        .dispatch("requests/sendToFiascoAudatex", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          this.editOffer.audatexFiascoTaskId = res.taskId[0];
          this.updateCalculation();
        });
    },
    sendLocalToStationAudatex() {
      this.$store.commit("SET_BUSY", true);
      let payload = {
        user: this.userStation.audatexData.user,
        password: this.userStation.audatexData.password,
        task: {
          fiascoId: this.selectedRequest.fiascoId,
          vin: this.selectedRequest.car.vin,
          licensePlate: this.selectedRequest.car.numberPlate
            ? this.selectedRequest.car.numberPlate
            : "unbekannt",
          registrationDate: this.selectedRequest.car.firstRegistration
            ? this.selectedRequest.car.firstRegistration + "-01-01"
            : "",
          calculation: this.calculation,
          location: {
            zipCode: this.selectedRequest.carLocation.zip,
          },
          customer: {
            firstName: this.selectedRequest.customer.firstName,
            lastName: this.selectedRequest.customer.lastName,
            address: this.selectedRequest.customer.address,
            zipCode: this.selectedRequest.customer.zip,
            city: this.selectedRequest.customer.city,
          },
          vehicleOwner: {
            firstName: this.selectedRequest.car.owner.firstName,
            lastName: this.selectedRequest.car.owner.lastName,
            address: this.selectedRequest.car.owner.address,
            zipCode: this.selectedRequest.car.owner.zip,
            city: this.selectedRequest.car.owner.city,
          },
        },
      };

      console.log(payload);
      this.$store
        .dispatch("requests/createAudatexTask", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          if (res.success) {
            this.editOffer.audatexStationTaskId = res.taskId;
            this.editOffer.audatexStationCaseId = res.caseId;
            this.$store.dispatch("offers/update", this.editOffer).then(() => {
              this.$store.dispatch("offers/getAll").then(() => {
                this.$store.commit("SET_BUSY", false);
                this.goAudatex();
                this.updateRequestStatus(this.editRequest.status);
              });
            });
          } else {
            console.log("CALC BRIDGE ERROR");
          }
        });
    },
    sendAudatexFiascoToStation() {
      this.$store.commit("SET_BUSY", true);
      let payload = {
        taskId: this.editOffer.audatexFiascoTaskId,
        station: this.userStation._id,
      };
      this.$store
        .dispatch("requests/audatexFiascoToStation", payload)
        .then((res) => {
          this.$store.commit("SET_BUSY", false);
          console.log(res);
          this.saveAudatexAction("SEND_TO_STATION");
        });
    },
    onSendAudatex() {
      this.sendLocalToStationAudatex();
    },
    saveAudatexAction(action) {
      if (!this.editOffer.audatexActions) {
        this.editOffer.audatexActions = [];
      }
      this.editOffer.audatexActions.push({
        action: action,
      });
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("offers/update", this.editOffer).then(() => {
        this.$store.dispatch("offers/getAll").then(() => {
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    goAudatex() {
      window.open(this.audatexLinkComplete, "_blank");
    },
    formattedPrice(price) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
      }).format(price);
    },
    copyAudatexLink() {
      let linkInput = document.querySelector("#linkinput");
      console.log(linkInput);
      linkInput.select();
      linkInput.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
  },
  computed: {
    audatexLinkComplete() {
      let payload = {
        taskId: this.editOffer.audatexFiascoTaskId,
      };
      if (!this.user.isFiasco && this.editOffer.audatexStationTaskId) {
        payload.taskId = this.editOffer.audatexStationTaskId;
      }
      return this.audatexLink(payload);
    },
    ...mapGetters({
      API: "API",
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      user: "users/user",
      requests: "requests/requests",
      stations: "stations/stations",
      selectedRequest: "requests/selectedRequest",
      requestsByStatus: "requests/requestsByStatus",
      requestStates: "requests/requestStates",
      requestStatusName: "requests/requestStatusName",
      calculationOpen: "requests/calculationOpen",
      audatexFiascoTasks: "requests/audatexFiascoTasks",
      activeOffers: "offers/activeOffers",
      audatexLink: "offers/audatexLink",
      userStation: "stations/userStation",
      sum: "offers/sum",
    }),
  },
  mounted() {
    console.log("Calculation mounted");
    this.getEditData();
  },
};
</script>
