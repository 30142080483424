<template>
  <div class="station-base-calculation">
    <h3>Basiskalkulation</h3>

    <div class="calculation-form" v-if="editStation">
      <div class="calculation-form-positions">
        <div class="calculation-form-position title">
          <div class="calculation-form-position-label">
            <strong>Lohnstufe</strong>
          </div>
          <div class="calculation-form-position-value">
            <strong>Stundensatz</strong>
          </div>
          <div class="calculation-form-position-sum">
            <strong>Rabatt</strong>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Mechanik</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.hourlyWageMechanics"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.discountMechanics"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Elektronik</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.hourlyWageElectronics"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.discountElectronics"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Karosserie</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.hourlyWageBody"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.discountBody"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Hagelarbeiten</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.hourlyWageHailDamage"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.discountHailDamage"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Lackarbeiten</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.hourlyWagePaint"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.discountPaint"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <div class="calculation-form-position title">
          <div class="calculation-form-position-label">
            <strong>Zusatzkosten</strong>
          </div>
          <div class="calculation-form-position-value">
            <strong></strong>
          </div>
          <div class="calculation-form-position-sum">
            <strong>Prozent</strong>
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Lackiermaterialanteil</span>
          </div>
          <div class="calculation-form-position-value">&nbsp;</div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.additionalCosts.paintMaterialPercentage"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
            %
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Kleinersatzteile</span>
          </div>
          <div class="calculation-form-position-value">&nbsp;</div>
          <div class="calculation-form-position-sum">
            <el-input-number
              controls-position="right"
              v-model="baseCalculation.additionalCosts.smallPartsPercentage"
              :precision="2"
              @change="onChange()"
            ></el-input-number>
            %
          </div>
        </div>

        <div class="calculation-form-position">
          <div class="calculation-form-position-label">
            <span>Lackierart</span>
          </div>
          <div class="calculation-form-position-value">
            <el-input v-model="paintType" disabled></el-input>
          </div>
          <div class="calculation-form-position-sum"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StationBaseCalculation",
  data() {
    return {
      changeTimeout: null,
      message: "",
      editStation: null,
      baseCalculation: {
        workPackagesPerHour: 12,
        workloadMechanics: 0,
        workloadElectronics: 0,
        workloadBody: 0,
        workloadHailDamage: 0,
        workloadPaint: 0,
        materialParts: 0,
        materialPaint: 0,
        hourlyWageMechanics: 0,
        hourlyWageElectronics: 0,
        hourlyWageBody: 0,
        hourlyWageHailDamage: 0,
        hourlyWagePaint: 0,
        discountMechanics: 0,
        discountElectronics: 0,
        discountBody: 0,
        discountHailDamage: 0,
        discountPaint: 0,
        additionalCosts: {
          paintMaterialPercentage: 0,
          smallPartsPercentage: 0,
        },
      },
      paintType: "Hersteller",
    };
  },
  methods: {
    onChange() {
      console.log("onChange");
      if (this.changeTimeout) {
        window.clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = window.setTimeout(() => {
        console.log("now we change");
        this.updateBaseCalculation();
        window.clearTimeout(this.changeTimeout);
      }, this.CHANGE_TIMEOUT_MS);
    },
    getEditData() {
      console.log("getEditData");
      let station = this.stations.find((o) => o._id === this.selectedStation._id);
      this.editStation = JSON.parse(JSON.stringify(station));
      // init baseCalculation
      if (this.editStation.baseCalculation) {
        this.baseCalculation = this.editStation.baseCalculation;
      } else {
        this.editStation.baseCalculation = this.baseCalculation;
      }
      if (!this.editStation.baseCalculation.additionalCosts) {
        this.editStation.baseCalculation.additionalCosts = {
          paintMaterialPercentage: 0,
          smallPartsPercentage: 0,
        };
      }
    },
    updateBaseCalculation() {
      this.$store.commit("SET_BUSY", true);
      this.editStation.baseCalculation = this.baseCalculation;
      this.$store.dispatch("stations/update", this.editStation).then(() => {
        this.$store.dispatch("stations/getAll").then(() => {
          this.getEditData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      selectedStation: "stations/selectedStation",
      stations: "stations/stations",
    }),
  },
  watch: {
    $route() {
      if (this.selectedStation) {
        this.getEditData();
      }
    },
  },
  mounted() {
    console.log("baseCalculation mounted");
    this.getEditData();
  },
};
</script>
