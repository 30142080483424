<template>
  <img v-if="s3Url" :src="s3Url" />
</template>

<script>
export default {
  name: 'S3Avatar',
  props: {
    image: String
  },
  data() {
    return {
      s3Url: null
    }
  },
  methods: {
    getUrl () {
      this.$store.dispatch('getS3Image', this.image).then((res) => {
        this.s3Url = res
      })
    }
  },
  mounted () {
    this.getUrl()
  }
}
</script>
