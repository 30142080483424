<template>
  <el-dialog
    title="Expresslink senden"
    v-model="visible"
    width="360px"
    @close="onClose()"
  >
    <div class="invite-customer">
      <h2>Kunden einladen</h2>
      <div v-if="!showResponse">
        <el-form
          class="invite-form"
          label-position="top"
          @submit.prevent="sendEmail = false"
        >
          <el-form-item label="Interne Notiz (z.B. Kundenname)">
            <el-input v-model="note"></el-input>
          </el-form-item>
          <el-form-item label="E-Mail">
            <el-input v-model="email" @keydown.enter="sendEmail()"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="sendEmail()"
              >E-Mail senden</el-button
            >
          </el-form-item>
        </el-form>

        <el-form
          class="invite-form"
          label-position="top"
          @submit.prevent="sendSms = false"
        >
          <el-form-item label="Mobilnummer">
            <el-input
              v-model="phoneNumber"
              @keydown.enter="sendSms()"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="sendSms()">SMS senden</el-button>
          </el-form-item>
          <el-button @click="onClose()">Abbrechen</el-button>
        </el-form>
      </div>
      <div v-if="showResponse">
        <p>{{ this.response }}</p>
        <p>
          <el-button type="primary" @click="onClose()">Schließen</el-button>
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InviteCustomer",
  data() {
    return {
      email: "",
      note: "",
      phoneNumber: "",
      visible: false,
      showResponse: false,
      response: "",
    };
  },
  methods: {
    sendEmail() {
      console.log("sendEmail");
      this.$store.commit("SET_BUSY", true);
      let payload = {
        type: "EMAIL",
        target: this.email,
        station: this.userStation._id,
        user: this.user._id,
        note: this.note,
      };
      this.$store.dispatch("stations/createAction", payload).then(() => {
        let payload = {
          email: this.email,
          inviteCode: this.userStation.inviteCode,
          stationName: this.userStation.name,
        };
        this.$store
          .dispatch("customers/sendEmailInvitation", payload)
          .then((res) => {
            console.log(res);
            this.$store.commit("SET_BUSY", false);
          });
      });
      this.showResponse = true;
      this.response = `Der Link wurde erfolgreich an ${this.email} verschickt.`;
    },
    sendSms() {
      console.log("sendSms");
      this.$store.commit("SET_BUSY", true);
      let payload = {
        type: "SMS",
        target: this.phoneNumber,
        station: this.userStation._id,
        user: this.user._id,
        note: this.note,
      };
      this.$store.dispatch("stations/createAction", payload).then(() => {
        let payload = {
          phoneNumber: this.phoneNumber,
          inviteCode: this.userStation.inviteCode,
          stationName: this.userStation.name,
        };
        this.$store
          .dispatch("customers/sendSmsInvitation", payload)
          .then((res) => {
            console.log(res);
            this.$store.commit("SET_BUSY", false);
          });
      });
      this.showResponse = true;
      this.response = `Der Link wurde erfolgreich an ${this.phoneNumber} verschickt.`;
    },
    onClose() {
      console.log("close");
      this.visible = false;
      this.$store.commit("SET_EXPRESS_LINK_VISIBLE", false);
      this.showResponse = false;
      this.resetInputs();
    },
    resetInputs() {
      this.email = "";
      this.phoneNumber = "";
    },
  },
  computed: {
    ...mapGetters({
      CHANGE_TIMEOUT_MS: "CHANGE_TIMEOUT_MS",
      expressLinkVisible: "expressLinkVisible",
      userStation: "stations/userStation",
      user: "users/user",
      stations: "stations/stations",
    }),
  },
  watch: {
    expressLinkVisible() {
      if (this.expressLinkVisible) {
        this.visible = true;
      }
    },
  },
  mounted() {
    console.log("invite mounted");
  },
};
</script>
