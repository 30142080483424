
import axios from 'axios'
import moment from 'moment'

const state = {
  activeOffers: [],
  selectedOffer: null,
}

// getters
const getters = {
  activeOffers: state => state.activeOffers,
  selectedOffer: state => state.selectedOffer,
  fiascoCalculationsByStation: state => payload => {
    let offers = state.activeOffers
      .filter(o => o.station === payload.station).filter(o => o.request)
      .filter(o => o.request.statusHistory.some(o => o.status === 'FIASCO_CALCULATING'))
    return offers
  },
  fiascoCalculationsPerMonth: state => payload => {
    let baseOffers = state.activeOffers
      .filter(o => o.station === payload.station).filter(o => o.request)
      .filter(o => o.request.statusHistory.some(o => o.status === 'FIASCO_CALCULATING'))
    let offers = []
    for (let i = 1; i <= 12; i++) {
      let month = i < 10 ? '0' + i : i
      offers.push(baseOffers.filter(o => o.request.statusHistory.find(o => moment(o.changedAt).isSame('2021-' + month + '-01', 'month'))).length)
    }
    return offers
  },
  fiascoCalculationsMaxByYear: state => payload => {
    let baseOffers = state.activeOffers
      .filter(o => o.station === payload.station).filter(o => o.request)
      .filter(o => o.request.statusHistory.some(o => o.status === 'FIASCO_CALCULATING'))
    let max = 0
    for (let i = 1; i <= 12; i++) {
      let month = i < 10 ? '0' + i : i
      let count = baseOffers.filter(o => o.request.statusHistory.find(o => moment(o.changedAt).isSame('2021-' + month + '-01', 'month'))).length
      if (count > max) {
        max = count
      }
    }
    return max
  },
  calculationLevelCaption: () => level => {
    let caption = 'A: maximal 10% Abweichung'
    if (level == 'B') caption = 'B: maximal 30% Abweichung'
    if (level == 'C') caption = 'C: über 30% Abweichung'
    return caption
  },
  sum: () => calculation => {
    let rawSum =
      calculation.workloadBody * calculation.hourlyWageBody / calculation.workPackagesPerHour * (1 - calculation.discountBody / 100) +
      calculation.workloadMechanics * calculation.hourlyWageMechanics / calculation.workPackagesPerHour * (1 - calculation.discountMechanics / 100) +
      calculation.workloadPaint * calculation.hourlyWagePaint / calculation.workPackagesPerHour * (1 - calculation.discountPaint / 100) +
      calculation.workloadHailDamage * calculation.hourlyWageHailDamage / calculation.workPackagesPerHour * (1 - calculation.discountHailDamage / 100) +
      calculation.workloadElectronics * calculation.hourlyWageElectronics / calculation.workPackagesPerHour * (1 - calculation.discountElectronics / 100) +
      calculation.materialParts + calculation.materialPaint +
      calculation.additionalCosts.alluvialMaterial +
      calculation.additionalCosts.hollowCavitySealantMaterial +
      calculation.additionalCosts.hollowCavityWorkload * calculation.hourlyWageBody / calculation.workPackagesPerHour * (1 - calculation.discountBody / 100) +
      calculation.additionalCosts.adhesiveSealMaterial +
      calculation.additionalCosts.underBodySealant
    return rawSum.toFixed(2)
  },
  audatexLink: () => payload => {
    // TODO: This link is different when running not in the prod enviroment (`www-ct` instead of `www`).
    return 'https://www.audanet.de/bre/work/current?taskId=' + payload.taskId
  }
}

// actions
const actions = {
  getAll(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'offers/', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ACTIVE', response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log('create offer')
    return axios.put(context.rootGetters.API.baseURL + 'offers/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log('update offer')
    return axios.patch(context.rootGetters.API.baseURL + 'offers/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ACTIVE(state, data) {
    state.activeOffers = data
  },
  SET_SELECTED_OFFER(state, data) {
    state.selectedOffer = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
