<template>
  <div class="dashboard">
    <div class="dashboard-section">
      <div class="dashboard-item large">
        <div class="dashboard-item-label">
          FIASCO Kalkulationen für den Monat
          {{
            fiascoCalculationsPerMonth({ station: user.station })[
              new Date().getMonth()
            ].length
          }}
        </div>
        <div class="dashboard-item-chart">
          <DonutChart :chartdata="fiascoCalculationsChartData" />
        </div>
      </div>
      <div class="dashboard-item large wide">
        <div class="dashboard-item-label">FIASCO Kalkulationen dieses Jahr</div>
        <div class="dashboard-item-chart">
          <BarChart
            v-if="user.isFiasco"
            :chartdata="fiascoCalculationsYearChartData"
            :max="fiascoCalculationsChartMaximum"
          />
        </div>
      </div>
    </div>
    <div class="dashboard-section">
      <div class="dashboard-item">
        <div class="dashboard-item-label">Offene Anfragen</div>
        <div class="dashboard-item-value">
          {{ openRequests.length }}
        </div>
      </div>
      <div class="dashboard-item">
        <div class="dashboard-item-label">Erledigte Anfragen</div>
        <div class="dashboard-item-value">
          {{ fixedRequests.length }}
        </div>
      </div>
      <div class="dashboard-item">
        <div class="dashboard-item-label">Abholbereite Reparaturen</div>
        <div class="dashboard-item-value">
          {{ fixedAndReadyRequests.length }}
        </div>
      </div>
      <div class="dashboard-item">
        <div class="dashboard-item-label">Kunden</div>
        <div class="dashboard-item-value">
          {{ customers.length }}
        </div>
      </div>
      <div class="dashboard-item" v-if="user.isFiasco">
        <div class="dashboard-item-label">Werkstätten</div>
        <div class="dashboard-item-value">
          {{ stations.length }}
        </div>
      </div>
      <div class="dashboard-item" v-if="user.isFiasco">
        <div class="dashboard-item-label">Offene FIASCO-Kalkulationen</div>
        <div class="dashboard-item-value">
          {{ directRequestsFiascoCalculations.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";
import DonutChart from "./DonutChart.vue";
import BarChart from "./BarChart.vue";

export default {
  name: "DirectRequestsInbox",
  components: {
    // DougnutChart,
    DonutChart,
    BarChart,
  },
  data() {
    return {
      test: [],
      searchString: "",
      selectedStation: "ALL",
    };
  },
  mixins: [dateDisplayMixin],
  computed: {
    fiascoCalculationsChartData() {
      let currentMonth = new Date().getMonth();
      let res = {};
      if (this.stations.find((o) => o._id === this.user.station)) {
        res = {
          labels: ["verwendet", "noch offen"],
          datasets: [
            {
              backgroundColor: ["#3985c5", "rgba(255,255,255,0.25)"],
              data: [
                this.fiascoCalculationsPerMonth({ station: this.user.station })[
                  currentMonth
                ],
                this.stations.find((o) => o._id === this.user.station)
                  .fiascoPackage.monthlyCalculations,
              ], // this.stations.find(o => o._id === this.user.station).fiascoPackage.monthlyCalculations - this.fiascoCalculationsPerMonth({month: '06', station: this.user.station}).length]
            },
          ],
        };
      }
      return res;
    },
    fiascoCalculationsYearChartData() {
      return {
        labels: [
          "Jan",
          "Feb",
          "Mrz",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez",
        ],
        datasets: [
          {
            backgroundColor: "#3985c5",
            data: this.fiascoCalculationsPerMonth({
              station: this.user.station,
            }), // this.stations.find(o => o._id === this.user.station).fiascoPackage.monthlyCalculations - this.fiascoCalculationsPerMonth({month: '06', station: this.user.station}).length]
          },
        ],
      };
    },
    fiascoCalculationsChartMaximum() {
      let payload = {
        station: this.user.station,
      };
      let max = this.fiascoCalculationsMaxByYear(payload);
      let monthlyFree = this.stations.find((o) => o._id === this.user.station)
        .fiascoPackage.monthlyCalculations;
      if (max > monthlyFree) {
        return max;
      } else {
        return monthlyFree;
      }
    },
    ...mapGetters({
      API: "API",
      user: "users/user",
      stations: "stations/stations",
      customers: "customers/customers",
      requests: "requests/requests",
      openRequests: "requests/openRequests",
      fixedRequests: "requests/fixedRequests",
      fixedAndReadyRequests: "requests/fixedAndReadyRequests",
      fiascoCalculationsByStation: "offers/fiascoCalculationsByStation",
      fiascoCalculationsPerMonth: "offers/fiascoCalculationsPerMonth",
      fiascoCalculationsMaxByYear: "offers/fiascoCalculationsMaxByYear",
      directRequestsFiascoCalculations:
        "requests/directRequestsFiascoCalculations",
      requestsByStatus: "requests/requestsByStatus",
    }),
  },
  methods: {
    mounted() {
      console.log(this.stations);
    },
  },
};
</script>
