<template>
  <div class="user-details" v-if="editUser">
    <el-form
      class="request-form"
      ref="form"
      :model="editUser"
      label-width="160px"
    >
      <el-form-item label="Vorname">
        <el-input
          v-model="editUser.firstName"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Nachname">
        <el-input
          v-model="editUser.lastName"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-Mail">
        <el-input
          v-model="editUser.email"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Telefon">
        <el-input
          v-model="editUser.phone"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Stadt">
        <el-input
          v-model="editUser.city"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="PLZ">
        <el-input
          v-model="editUser.zip"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Adresse">
        <el-input
          v-model="editUser.address"
          :disabled="!user.rights.includes('EDIT_USERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Bild">
        <el-avatar
          v-if="!editUser.s3Image"
          :size="60"
          :src="API.imageUrl + 'users/' + editUser.image"
        ></el-avatar>
        <S3Avatar
          v-if="editUser.s3Image"
          :image="editUser.s3Image"
          :size="60"
        />

        <el-upload
          v-if="user.rights.includes('EDIT_USERS')"
          class="upload-demo"
          drag
          :thumbnail-mode="true"
          :action="API.baseUrl + 'uploads/image-s3'"
          :data="{
            key: 'images/users/' + editUser._id,
          }"
          :headers="axiosConfig.headers"
          :on-success="onUploadSuccess"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Bild hierhin ziehen oder <em>klicken zum Hochladen</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <el-form-item label="FIASCO" v-if="user.isFiasco">
        <el-checkbox
          v-model="editUser.isFiasco"
          :disabled="!user.rights.includes('EDIT_USERS') || !user.isFiasco"
          >FIASCO Mitarbeiter</el-checkbox
        >
      </el-form-item>

      <el-form-item label="Werkstatt">
        <span v-if="!user.isFiasco"
          >{{ userStation.name }} {{ userStation.city }}</span
        >

        <el-select
          class="wide"
          v-model="editUser.station"
          :disabled="!user.rights.includes('EDIT_USERS')"
          value-key="_id"
          v-if="user.isFiasco"
        >
          <el-option
            class="select-avatars"
            v-for="item in stations"
            :key="item._id"
            :label="item.name + ' ' + item.city"
            :value="item._id"
          >
            <div class="select-avatars-item">
              <el-avatar
                :src="API.imageUrl + 'stations/logos/' + item.logoImage"
                v-if="!item.s3LogoImage"
              ></el-avatar>
              <S3Avatar
                :image="item.s3LogoImage"
                :size="30"
                v-if="item.s3LogoImage"
              />
              <div>
                <div>{{ item.name }}</div>
                <div class="sub" v-if="item.city">{{ item.city }}</div>
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="user.rights.includes('EDIT_USERS')">
        <el-button type="primary" @click="updateUser">Speichern</el-button>
      </el-form-item>
      <el-popconfirm
        confirmButtonText="OK"
        cancelButtonText="Abbrechen"
        title="Endgültig löschen?"
        v-if="user.rights.includes('DELETE_USERS')"
        @confirm="deleteUser()"
      >
        <template #reference>
          <el-button icon="el-icon-delete"> Löschen </el-button>
        </template>
      </el-popconfirm>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserDetails",
  data() {
    return {
      editUser: null,
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
    goDetails(row) {
      console.log(row);
      this.$router.push({ name: "Request" });
    },
    updateUser() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("users/update", this.editUser).then(() => {
        this.$store.dispatch("users/getAll").then(() => {
          this.getSelectedUserData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getSelectedUserData() {
      if (this.$route.params.id) {
        this.editUser = JSON.parse(
          JSON.stringify(
            this.users.find((o) => o._id === this.$route.params.id)
          )
        );
        if (this.editUser.station) {
          this.editUser.station = this.editUser.station._id;
        }
      }
    },
    deleteUser() {
      this.$store.commit("SET_BUSY", true);
      console.log(this.editUser._id);
      this.$store.dispatch("users/delete", this.editUser._id).then(() => {
        this.$store.dispatch("users/getAll").then(() => {
          this.$router.push({ name: "Users" });
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    onUploadSuccess(response) {
      console.log("onUploadSuccess");
      console.log(response);
      this.editUser.s3Image = response;
      this.updateUser();
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      user: "users/user",
      axiosConfig: "axiosConfig",
      stations: "stations/stations",
      selectedUser: "users/selectedUser",
      users: "users/users",
      userStation: "stations/userStation",
      userRoles: "users/roles",
    }),
  },
  mounted() {
    console.log("USER DETAILS MOUNTED");
    this.getSelectedUserData();
  },
};
</script>
