<template>
  <div class="administration-states main-content">
    <h2>Statusverlauf von Anfragen</h2>
    <el-timeline>
      <el-timeline-item
        v-for="(item, index) in requestStates"
        :key="index">
        {{item.displayName}} ({{item.status}})
        <div>
          &rarr; <span v-for="(nextItem, index) in nextSteps(item.status)" :key="index + 'nxt'">{{requestStatusName(nextItem)}} / </span>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdministrationStates',
  data() {
    return {
      requests: [
        {
          date: '07.12.2020',
          customer: 'Miranda Car',
          images: [
            'http://cloudwerkstatt.raumstation-endstation.de/examples/3.jpg',
            'http://cloudwerkstatt.raumstation-endstation.de/examples/4.jpg',
            'http://cloudwerkstatt.raumstation-endstation.de/examples/6.jpg'
          ],
          damageLevel: 2,
          car: 'Mercedes Benz C200 Baujahr 2005',
          state: 'Angebot gesendet',
        }
      ]
    }
  },
  methods: {
    onRowClick (row, col) {
      console.log(row)
      console.log(col)
      this.$router.push({name: 'User', params: {id: row.id}})
    },
  },
  computed: {
    ...mapGetters({
      requestStates: 'requests/requestStates',
      nextSteps: 'requests/nextSteps',
      requestStatusName: 'requests/requestStatusName',
    }),
  },
}
</script>
