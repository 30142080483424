<template>
  <div class="user-rights" v-if="editUser">
    <el-row>
      <el-col :span="16">
        <el-form
          class="details-form"
          ref="form"
          label-position="top"
          label-width="60px"
        >
          <el-form-item label="Direktanfragen">
            <el-checkbox-group v-model="editUser.rights">
              <el-checkbox
                v-for="item in userRightsByGroup('DIRECT_REQUESTS')"
                :key="item.key"
                :label="item.key"
                :disabled="item.fiascoOnly && !editUser.isFiasco"
                :class="[
                  { fiascoRight: item.fiascoOnly },
                  { deprecatedRight: item.deprecated },
                ]"
                @change="onChange()"
              >
                {{ item.displayName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <!--el-form-item label="Schnellanfragen">
            <el-checkbox-group v-model="editUser.rights">
            <el-checkbox
              v-for="item in userRightsByGroup('QUICK_REQUESTS')" 
              :key="item.key"
              :label="item.key"
              :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
              @change="onChange()">
              {{item.displayName}}
            </el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="Reklamationen">
            <el-checkbox-group v-model="editUser.rights">
            <el-checkbox
              v-for="item in userRightsByGroup('COMPLAINTS')" 
              :key="item.key"
              :label="item.key"
              :class="[{fiascoRight: item.fiascoOnly}, {deprecatedRight: item.deprecated}]"
              @change="onChange()">
              {{item.displayName}}
            </el-checkbox>
            </el-checkbox-group>
          </el-form-item-->

          <el-form-item label="Verwaltung">
            <el-checkbox-group v-model="editUser.rights">
              <el-checkbox
                v-for="item in userRightsByGroup('ADMINISTRATION')"
                :key="item.key"
                :label="item.key"
                :disabled="item.fiascoOnly && !editUser.isFiasco"
                :class="[
                  { fiascoRight: item.fiascoOnly },
                  { deprecatedRight: item.deprecated },
                ]"
                @change="onChange()"
              >
                {{ item.displayName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="8">
        <el-form v-model="editUser">
          <el-form-item label="Rolle als Vorlage wählen:">
            <el-select
              v-model="selectedRole"
              placeholder="Rolle"
              @change="onRoleSelect"
            >
              <el-option
                v-for="item in roles"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserRights",
  data() {
    return {
      editUser: null,
      selectedRole: null,
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
    goDetails(row) {
      console.log(row);
      this.$router.push({ name: "Request" });
    },
    onChange() {
      this.updateUser();
    },
    updateUser() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("users/update", this.editUser).then(() => {
        this.$store.dispatch("users/getAll").then(() => {
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getSelectedUserData() {
      this.editUser = JSON.parse(
        JSON.stringify(this.users.find((o) => o._id === this.$route.params.id))
      );
    },
    onRoleSelect(val) {
      console.log(val);
      this.editUser.rights = this.roles.find((o) => o._id === val).rights;
      this.updateUser();
    },
  },
  computed: {
    ...mapGetters({
      stations: "stations/stations",
      selectedUser: "users/selectedUser",
      user: "users/user",
      users: "users/users",
      roles: "roles/roles",
      userRights: "users/rights",
      userRightsByGroup: "users/rightsByGroup",
    }),
  },
  mounted() {
    console.log("USER DETAILS MOUNTED");
    this.getSelectedUserData();
  },
};
</script>
