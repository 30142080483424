<template>
  <div class="start" v-if="user">
    <img class="start-logo" src="@/assets/gfx/fiasco-logo-large-white.png" />
    <div class="start-user">
      <el-avatar
        v-if="!user.s3Image"
        :size="90"
        :src="API.imageUrl + 'users/' + user.image"
      ></el-avatar>
      <S3Avatar v-if="user.s3Image" :image="user.s3Image" :size="90" />
      Hallo {{ user.firstName }}!
    </div>
    <Dashboard />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Dashboard from "./Dashboard.vue";

export default {
  name: "Start",
  components: {
    Dashboard,
  },
  computed: {
    ...mapGetters({
      API: "API",
      busy: "busy",
      users: "users/users",
      stationUsers: "users/stationUsers",
      userId: "users/userId",
      user: "users/user",
      newDataAvailable: "newDataAvailable",
      lastUpdateCall: "lastUpdateCall",
      lastUpdate: "requests/lastUpdate",
      requestStates: "requests/requestStates",
      stations: "stations/stations",
      userStation: "stations/userStation",
    }),
  },
};
</script>
