<template>
  <div class="user-actions" v-if="editUser">
    <el-table :data="userActions" :height="tableHeight()">
      <el-table-column prop="createdAt" label="Datum" sortable>
        <template #default="scope">
          <div>{{ formatDate(scope.row.createdAt) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Aktion" sortable />
      <el-table-column prop="target" label="Ziel" sortable />
      <el-table-column prop="note" label="Notiz" sortable />
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "UserPassword",
  data() {
    return {
      editUser: null,
      passwordSaved: false,
      editPassword: "",
      editPasswordCopy: "",
    };
  },
  mixins: [dateDisplayMixin],
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "Request" });
    },
    getStationActions() {
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("stations/getActionsByStation", this.selectedUser.station._id)
        .then(() => {
          this.$store.commit("SET_BUSY", false);
        });
    },
    tableHeight() {
      return window.innerHeight - 220;
    },
  },
  computed: {
    saveAvailable() {
      return (
        this.editPassword.length >= 3 &&
        this.editPassword === this.editPasswordCopy
      );
    },
    userActions() {
      return this.stationActions?.filter((o) => o.user == this.editUser._id);
    },
    ...mapGetters({
      stations: "stations/stations",
      selectedUser: "users/selectedUser",
      userRoles: "users/roles",
      stationActions: "stations/actions",
      userRights: "users/rights",
      userRightsByGroup: "users/rightsByGroup",
    }),
  },
  mounted() {
    console.log("USER PASSWORD MOUNTED");
    this.editUser = JSON.parse(JSON.stringify(this.selectedUser));
    this.getStationActions();
  },
};
</script>
