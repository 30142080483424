<template>
  <div class="customer-details" v-if="editCustomer">
    <el-form
      class="request-form"
      ref="form"
      :model="editCustomer"
      label-width="160px"
    >
      <el-form-item label="Vorname">
        <el-input
          v-model="editCustomer.firstName"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Nachname">
        <el-input
          v-model="editCustomer.lastName"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-Mail">
        <el-input
          v-model="editCustomer.email"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Telefon">
        <el-input
          v-model="editCustomer.phone"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Stadt">
        <el-input
          v-model="editCustomer.city"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="PLZ">
        <el-input
          v-model="editCustomer.zip"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <el-form-item label="Adresse">
        <el-input
          v-model="editCustomer.address"
          :disabled="!user.rights.includes('EDIT_CUSTOMERS')"
        ></el-input>
      </el-form-item>
      <!--el-form-item label="Franchise">
        <el-input v-model="editCustomer.franchise"></el-input>
      </el-form-item-->
      <el-form-item label="Wuschwerkstatt">
        <div class="avatar-item" v-if="editCustomer.selectedStation">
          <el-avatar
            size="large"
            :src="
              API.imageUrl +
              'stations/logos/' +
              editCustomer.selectedStation.logoImage
            "
            v-if="!editCustomer.selectedStation.s3LogoImage"
          ></el-avatar>
          <S3Avatar
            :image="editCustomer.selectedStation.s3LogoImage"
            :size="60"
            v-if="editCustomer.selectedStation.s3LogoImage"
          />
          <span
            >{{ editCustomer.selectedStation.name }}
            {{ editCustomer.selectedStation.city }}</span
          >
        </div>
        <el-select
          class="wide"
          v-model="selectedStation"
          value-key="_id"
          filterable
          placeholder="Werkstatt suchen"
          :remote-method="searchStation"
          @change="onStationSelect"
          v-if="user.rights.includes('EDIT_CUSTOMERS')"
        >
          <el-option
            class="select-avatars"
            v-for="item in stations"
            :key="item._id"
            :label="item.name + ' ' + item.city"
            :value="item._id"
          >
            <div class="select-avatars-item">
              <el-avatar
                :src="API.imageUrl + 'stations/logos/' + item.logoImage"
                v-if="!item.s3LogoImage"
              ></el-avatar>
              <S3Avatar
                :image="item.s3LogoImage"
                :size="30"
                v-if="item.s3LogoImage"
              />
              <div>
                <div>{{ item.name }}</div>
                <div class="sub">{{ item.city }}</div>
              </div>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Bild">
        <el-avatar
          v-if="!editCustomer.s3Image"
          :size="60"
          :src="API.imageUrl + 'customers/' + editCustomer.image"
        ></el-avatar>
        <S3Avatar
          v-if="editCustomer.s3Image"
          :image="editCustomer.s3Image"
          :size="60"
        />
        <el-upload
          drag
          :action="API.baseUrl + 'uploads/image-s3'"
          :data="{
            key: 'images/customers/' + editCustomer._id,
          }"
          :headers="axiosConfig.headers"
          :on-success="onUploadSuccess"
          v-if="user.rights.includes('EDIT_CUSTOMERS')"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            Bild hierhin ziehen oder <em>klicken zum Hochladen</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="E-Mail bestätigt">
        <el-checkbox
          v-model="editCustomer.emailVerified"
          :disabled="!user.rights.includes('EDIT_USERS') || !user.isFiasco"
        ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="update"
          v-if="user.rights.includes('EDIT_CUSTOMERS')"
          >Speichern</el-button
        >
        <el-popconfirm
          confirmButtonText="OK"
          cancelButtonText="Abbrechen"
          title="Endgültig löschen?"
          v-if="user.rights.includes('DELETE_CUSTOMERS')"
          @confirm="deleteCustomer()"
        >
          <template #reference>
            <el-button icon="el-icon-delete"> Löschen </el-button>
          </template>
        </el-popconfirm>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CustomerDetails",

  data() {
    return {
      editCustomer: null,
      selectedStation: null,
      filteredStations: [],
      s3Url: null,
    };
  },
  methods: {
    update() {
      this.$store.commit("SET_BUSY", true);
      if (this.selectedStation) {
        this.editCustomer.selectedStation = this.selectedStation;
        this.selectedStation = null;
      }
      this.$store.dispatch("customers/update", this.editCustomer).then(() => {
        this.$store.dispatch("customers/getAll").then(() => {
          this.getSelectedData();
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    getSelectedData() {
      this.editCustomer = JSON.parse(
        JSON.stringify(
          this.customers.find((o) => o._id === this.$route.params.id)
        )
      );
      this.selectedStation = this.stations.find(
        (o) => o._id === this.editCustomer.selectedStation
      );
    },
    onUploadSuccess(response) {
      console.log("onUploadSuccess");
      console.log(response);
      this.editCustomer.s3Image = response;
      this.update();
    },
    searchStation(query) {
      if (query) {
        if (query.length > 2) {
          this.filteredStations = this.stations.filter((o) =>
            (o.name + " " + o.city).toLowerCase().includes(query)
          );
        }
      } else {
        this.filteredStations = [];
      }
    },
    onStationSelect() {
      console.log(this.selectedStation);
      this.filteredStations = [];
      this.update();
    },
    deleteCustomer() {
      this.$store.commit("SET_BUSY", true);
      this.$store
        .dispatch("customers/delete", this.editCustomer._id)
        .then(() => {
          this.$store.dispatch("customers/getAll").then(() => {
            this.$router.push({ name: "Customers" });
            this.$store.commit("SET_BUSY", false);
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      axiosConfig: "axiosConfig",
      user: "users/user",
      customers: "customers/customers",
      selectedCustomer: "customers/selectedCustomer",
      stations: "stations/stations",
    }),
  },
  mounted() {
    console.log("CUSTOMER DETAILS MOUNTED");
    this.getSelectedData();
  },
};
</script>
