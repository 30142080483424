<script>
import { Bar } from 'vue3-chart-v2'

export default {
  name: 'BarChart',
  extends: Bar,
  props: {
    chartdata: Object,
    max: Number
  },
  mounted () {
    this.renderChart(this.chartdata, {
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          display: true,
          ticks: {
            beginAtZero: true,
            max: this.max
          }
        }]
      },
    })
  }
}
</script>