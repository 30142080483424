<template>
  <div class="request-details" v-if="editRequest">
    <!--div>
      {{editRequest}}
    </div-->

    <el-row :gutter="40">
      <el-col :span="16">
        <br />

        <div class="request-details-boxes">
          <div class="request-details-box request-details-box-car">
            <h3>Fahrzeugdaten</h3>
            <div class="request-details-box-content" v-if="editRequest.car">
              <el-form :model="editRequest" label-width="100px">
                <el-form-item label="VIN">
                  <el-input v-model="vin"></el-input>
                </el-form-item>
                <el-form-item label="Kennzeichen">
                  <el-input v-model="editRequest.car.numberPlate"></el-input>
                </el-form-item>
                <el-form-item class="hsn-tsn" label="HSN / TSN">
                  <el-input class="hsn" v-model="hsn" :maxlength="4"></el-input>
                  <el-input class="tsn" v-model="tsn" :maxlength="3"></el-input>
                </el-form-item>
                <el-form-item label="EZ">
                  <el-date-picker
                    v-model="editRequest.car.firstRegistration"
                    type="date"
                    format="DD.MM.YYYY"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Marke">
                  <el-input v-model="editRequest.car.manufacturer"></el-input>
                </el-form-item>
                <el-form-item label="Modell">
                  <el-input v-model="editRequest.car.model"></el-input>
                </el-form-item>
                <el-form-item label="Kilometerstand">
                  <el-input-number
                    :controls="false"
                    v-model="editRequest.car.kilometers"
                    :precision="2"
                  ></el-input-number>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="request-details-box request-details-box-person">
            <h3>Halterdaten</h3>
            <div class="request-details-box-content">
              <el-form :model="editRequest" label-width="100px">
                <el-form-item label="Vorname">
                  <el-input
                    v-model="editRequest.car.owner.firstName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Nachname">
                  <el-input v-model="editRequest.car.owner.lastName"></el-input>
                </el-form-item>
                <el-form-item label="Straße">
                  <el-input v-model="editRequest.car.owner.address"></el-input>
                </el-form-item>
                <el-form-item label="PLZ">
                  <el-input v-model="editRequest.car.owner.zip"></el-input>
                </el-form-item>
                <el-form-item label="Stadt">
                  <el-input v-model="editRequest.car.owner.city"></el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="request-details-box request-details-box-case">
            <h3>Anfragedetails</h3>
            <div class="request-details-box-content">
              <el-form :model="editRequest" label-width="100px">
                <el-form-item label="Angefragt am">
                  <el-date-picker
                    v-model="editRequest.createdAt"
                    type="date"
                    disabled
                    format="DD.MM.YYYY"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="Kunde">
                  <div>{{ editRequest.customer.email }}</div>
                  <div>
                    {{ editRequest.customer.firstName }}&nbsp;
                    {{ editRequest.customer.lastName }}
                  </div>
                </el-form-item>
                <el-form-item label="Telefon">
                  <div v-if="editRequest.customer.phone">
                    Kunden-Tel.: {{ editRequest.customer.phone }}
                  </div>
                  <div v-if="!editRequest.customer.phone" class="small">
                    Keine Telelefonnr. vom Kunden hinterlegt
                  </div>
                  <el-input
                    v-model="editRequest.phone"
                    placeholder="Rückfragen"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Bezeichnung">
                  <el-input v-model="editRequest.title"></el-input>
                </el-form-item>
                <el-form-item label="Beschreibung">
                  <el-input
                    type="textarea"
                    v-model="editRequest.description"
                  ></el-input>
                </el-form-item>
                <el-form-item label="Standort (PLZ)">
                  <el-input v-model="editRequest.carLocation.zip"></el-input>
                </el-form-item>
                <el-form-item label="Smart Repair">
                  <el-checkbox v-model="editRequest.isSmartRepair"
                    >Schaden kann mit Smart Repair <br />behoben
                    werden</el-checkbox
                  >
                  <el-checkbox v-model="editRequest.smartRepairAccepted"
                    >Kunde akzeptiert Smart Repair</el-checkbox
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <el-form
          class="request-form"
          ref="form"
          :model="editRequest"
          label-width="160px"
        >
          <div class="request-details-images">
            <div
              class="request-details-image"
              v-for="(item, index) in editRequest.images"
              :key="index"
              :span="6"
            >
              <el-image
                :src="API.imageUrl + 'requests/' + item"
                @click="onSmallImageClick()"
              />
              <div class="request-details-image-controls">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  circle
                  @click="deletePhoto($event, item)"
                ></el-button>
              </div>
            </div>
            <div
              class="request-details-image"
              v-for="(item, index) in editRequest.s3Images"
              :key="index"
              :span="6"
            >
              <S3Image :image="item" @click="onSmallImageClick(index)" />
              <div class="request-details-image-controls">
                <el-popconfirm
                  title="Bild löschen?"
                  confirmButtonText="OK"
                  cancelButtonText="Abbrechen"
                  @confirm="deletePhoto($event, item)"
                >
                  <template #reference>
                    <el-button
                      size="mini"
                      icon="el-icon-delete"
                      circle
                    ></el-button>
                  </template>
                </el-popconfirm>
                <el-popconfirm
                  title="Bild als KFZ Schein scannen?"
                  confirmButtonText="OK"
                  cancelButtonText="Abbrechen"
                  @confirm="startRegistrationScan($event, item)"
                >
                  <template #reference>
                    <el-button
                      size="mini"
                      icon="el-icon-postcard"
                      circle
                    ></el-button>
                  </template>
                </el-popconfirm>
              </div>
            </div>
            <div class="request-details-image-upload">
              <el-upload
                v-if="editRequest.images.length < 10"
                drag
                :action="API.baseUrl + 'uploads/image-s3'"
                :data="{
                  key:
                    'images/requests/' +
                    editRequest._id +
                    '-' +
                    editRequest.s3Images.length,
                }"
                :headers="axiosConfig.headers"
                :on-success="onUploadPhotoSuccess"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  Bild hierhin ziehen oder <em>klicken zum Hochladen</em>
                </div>
              </el-upload>
            </div>
          </div>

          <el-form-item label="Bewertung" v-if="editRequest.rating">
            <div class="request-details-ratings">
              <div class="request-details-rating">
                Preis/Leistung:
                <el-rate
                  v-model="editRequest.rating.performance"
                  void-color="#676767"
                  :colors="['#3985c5', '#3985c5', '#3985c5']"
                ></el-rate>
              </div>
              <div class="request-details-rating">
                Kommunikation:
                <el-rate
                  v-model="editRequest.rating.communication"
                  void-color="#676767"
                  :colors="['#3985c5', '#3985c5', '#3985c5']"
                ></el-rate>
              </div>
              <div class="request-details-rating">
                Zufriedenheit:
                <el-rate
                  v-model="editRequest.rating.satisfaction"
                  void-color="#676767"
                  :colors="['#3985c5', '#3985c5', '#3985c5']"
                ></el-rate>
              </div>
            </div>
            Kommentar:
            <el-input
              type="textarea"
              v-model="editRequest.rating.comment"
            ></el-input>
          </el-form-item>

          <el-form-item label="Schaden">
            <el-table
              :data="editRequest.damages"
              @click="damageDialogVisible = true"
            >
              <el-table-column label="Ort" width="180">
                <template #default="scope">
                  <span>{{ damageLocationName(scope.row.location) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Schadensart">
                <template #default="scope">
                  <span
                    class="damage-marker"
                    :class="scope.row.type.toLowerCase()"
                    >&bullet;
                  </span>
                  <span>{{ damageTypeName(scope.row.type) }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>

          <div class="request-details-damage-plan">
            <img
              v-for="item in editRequest.damages"
              :key="item._id"
              :src="'/img/damages/' + item.location + '.png'"
              :class="item.type.toLowerCase()"
            />
            <img src="/img/damages/overview.png" class="overview" />
          </div>

          <el-divider></el-divider>
        </el-form>
      </el-col>

      <el-col class="request-details-chat" :span="8">
        <RequestChat :requestId="editRequest._id" />
      </el-col>
    </el-row>
    <el-affix position="bottom" :offset="20">
      <div class="details-buttons">
        <el-button type="primary" @click="updateRequest()"
          >Änderungen speichern</el-button
        >
        <el-popconfirm
          confirmButtonText="OK"
          cancelButtonText="Abbrechen"
          title="Endgültig löschen?"
          @confirm="deleteRequest()"
        >
          <template #reference>
            <el-button icon="el-icon-delete"> Löschen </el-button>
          </template>
        </el-popconfirm>
      </div>
    </el-affix>

    <el-dialog
      title="Schaden bearbeiten"
      v-model="damageDialogVisible"
      width="800px"
    >
      <el-table :data="editDamages">
        <el-table-column prop="displayName" label="Ort" width="180">
        </el-table-column>
        <el-table-column label="Schadensart">
          <template #default="scope">
            <el-radio
              v-model="scope.row.type"
              v-for="item in damageTypes"
              :key="item.type"
              :label="item.type"
              @change="onDamageSelect"
            >
              {{ item.displayName }}
            </el-radio>
            <el-radio
              v-model="scope.row.type"
              v-if="scope.row.type !== ''"
              label=""
              @change="onDamageSelect"
            >
              Kein Schaden
            </el-radio>
          </template>
        </el-table-column>
      </el-table>
      <template #footer> </template>
    </el-dialog>

    <el-dialog
      :title="selectedRequest.title"
      v-model="imagesDialogVisible"
      @opened="onCarouselOpened"
      width="1280px"
    >
      <el-carousel height="820px" :autoplay="false" ref="carousel">
        <el-carousel-item
          v-for="(item, index) in editRequest.images"
          :key="index + '-carou'"
        >
          <img :src="API.imageUrl + 'requests/' + item" />
        </el-carousel-item>
        <el-carousel-item
          v-for="(item, index) in editRequest.s3Images"
          :key="index + '-carous'"
        >
          <S3Image class="request-details-carousel-image" :image="item" />
        </el-carousel-item>
      </el-carousel>
      <template #footer> </template>
    </el-dialog>

    <EditCarPopup
      v-if="editRequest.car"
      :car="editRequest.car"
      @carupdated="onCarUpdated()"
    />
    <ScanRegistrationPopup
      :car="editRequest.car"
      @carupdated="onCarUpdated()"
    />
  </div>

  <!--el-dialog
    title="Nachfragen"
    v-model="feedbackDialogVisible"
    width="560px">
    <p>Um ein Angebot zu erstellen, benötige ich...</p>
    <el-form>
      <el-form-item prop="needs">
        <el-checkbox-group v-model="feedbackForm.needs">
          <el-space direction="vertical" alignment="start" :size="1">
            <el-checkbox label="Mehr Fotos" name="needs"></el-checkbox>
            <el-checkbox label="Bessere Fotos" name="needs"></el-checkbox>
            <el-checkbox label="Eine Beschreibung" name="needs"></el-checkbox>
            <el-checkbox label="Vollständige Fahrzeuginfos" name="needs"></el-checkbox>
          </el-space >
        </el-checkbox-group>
      </el-form-item>
      <el-form-item prop="message" label="Nachricht">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Konkrete Fragen"
          v-model="feedbackForm.message">
        </el-input>
      </el-form-item>
    </el-form>
    <el-alert
      title="Diese Nachricht wird über den FIASCO Chat gesendet."
      type="warning"
      show-icon>
    </el-alert>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="feedbackDialogVisible = false">Abbrechen</el-button>
        <el-button type="primary" @click="feedbackDialogVisible = false">Senden</el-button>
      </span>
    </template>
  </el-dialog-->
</template>

<script>
import { mapGetters } from "vuex";

import RequestChat from "./RequestChat.vue";
import EditCarPopup from "./EditCarPopup.vue";
import ScanRegistrationPopup from "./ScanRegistrationPopup.vue";

import dateDisplayMixin from "../mixins/dateDisplay";

export default {
  name: "RequestDetails",
  data() {
    return {
      hsn: null,
      tsn: null,
      editRequest: null,
      editDamages: null,
      damageDialogVisible: false,
      feedbackDialogVisible: false,
      imagesDialogVisible: false,
      uploading: false,
      feedbackForm: {
        needs: [],
        message:
          "Hallo [KUNDE]! Danke für deine Anfrage. Um ein zuverlässiges Angebot zu erstellen, benötige ich noch: ... Danke und Grüße, [ANSPRECHPARTNER] von [WERKSTATT]",
      },
    };
  },
  components: {
    RequestChat,
    EditCarPopup,
    ScanRegistrationPopup,
  },
  mixins: [dateDisplayMixin],
  methods: {
    async updateRequest() {
      this.$store.commit("SET_BUSY", true);

      console.log("updateRequest");

      if (this.hsn) {
        this.editRequest.car.hsnTsn = (
          this.hsn.padEnd(4, " ") + this.tsn
        ).toUpperCase();
        console.log("post HSN TSN");
      }

      await this.$store.dispatch("requests/update", this.editRequest);
      await this.$store.dispatch("cars/update", this.editRequest.car);
      await this.$store.dispatch("requests/getActive");

      if (!this.uploading) {
        // this.$router.go(-1);
      }

      this.uploading = false;
      this.$store.commit("SET_BUSY", false);
    },
    deleteRequest() {
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/delete", this.editRequest._id).then(() => {
        this.$store.dispatch("requests/getActive").then(() => {
          this.$router.push({ name: "DirectRequestsInbox" });
          this.$store.commit("SET_BUSY", false);
        });
      });
    },
    onUploadPhotoSuccess(response) {
      this.uploading = true;
      this.editRequest.s3Images.push(response);
      this.updateRequest();
    },
    deletePhoto(event, item) {
      this.uploading = true;
      this.editRequest.images = this.editRequest.images.filter(
        (o) => o !== item
      );
      this.editRequest.s3Images = this.editRequest.s3Images.filter(
        (o) => o !== item
      );
      this.updateRequest();
      // TODO: delete s3
      /*
      let payload = {
        file: item
      } 
      this.$store.commit('SET_BUSY', true)
      this.$store.dispatch('requests/deletePhoto', payload).then((res) => { 
        console.log(res)
      })
      */
    },
    onDamageSelect() {
      console.log("onDamageSelect");
      this.syncDamages();
    },
    syncDamages() {
      this.editRequest.damages = [];
      this.editDamages.forEach((damage) => {
        if (damage.type !== "") {
          this.editRequest.damages.push(damage);
        }
      });
    },
    getEditData() {
      console.log("getEditData");
      // this.$store.commit('requests/SET_SELECTED_REQUEST', this.requests.find(o => o._id === this.$route.params.id))
      this.editRequest = JSON.parse(JSON.stringify(this.selectedRequest));
      this.editDamages = JSON.parse(JSON.stringify(this.damageLocations));
      this.editRequest.damages.forEach((damage) => {
        this.editDamages.find((o) => o.location === damage.location).type =
          damage.type;
      });
      if (!this.editRequest.carLocation) {
        this.editRequest.carLocation = {
          zip: "UNBEKANNT",
        };
      }
      if (!this.editRequest.car.owner) {
        this.editRequest.car.owner = {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          zip: "",
        };
      }

      this.hsn = null;
      this.tsn = null;
      if (this.editRequest.car.hsnTsn) {
        // Officially the HSN and TSN can only contain characters and digits [a-z0-9]. Since a user
        // can enter anything, we just take the first four characters.
        let hsnTsnRe = /^(?<hsn>.{0,4})(?<tsn>.{0,3})/gim;
        let matches = hsnTsnRe.exec(this.editRequest.car.hsnTsn.toUpperCase());

        if (matches) {
          this.hsn = matches.groups.hsn.trim();
          this.tsn = matches.groups.tsn.trim();
        }
      }

      if (this.editRequest?.car?.vin) {
        this.vin = this.editRequest.car.vin;
      }

      console.log("REQUEST DATA", this.editRequest);
    },
    onSmallImageClick(index) {
      this.imagesDialogVisible = true;
      this.targetCarouselIndex = index;
    },
    onCarouselOpened() {
      this.$refs.carousel.setActiveItem(this.targetCarouselIndex);
    },
    showEditCarPopup() {
      this.$store.commit("cars/SET_EDIT_CAR_VISIBLE", true);
    },
    startRegistrationScan(event, item) {
      console.log(event);
      console.log("startRegistrationScan");
      console.log(item);
      this.$store.commit("cars/SET_SCAN_IMAGE", item);
      this.$store.commit("cars/SET_SCAN_REGISTRATION_VISIBLE", true);
    },
    onCarUpdated() {
      console.log("onCarUpdated");
      this.$store.commit("SET_BUSY", true);
      this.$store.dispatch("requests/getActive").then(() => {
        this.getEditData();
        this.$store.commit("SET_BUSY", false);
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      axiosConfig: "axiosConfig",
      editCarVisible: "cars/editCarVisible",
      scanRegistrationVisible: "cars/scanRegistrationVisible",
      scanImage: "cars/scanImage",
      user: "users/user",
      userId: "users/userId",
      activeOffers: "offers/activeOffers",
      requests: "requests/requests",
      selectedRequest: "requests/selectedRequest",
      requestsByStatus: "requests/requestsByStatus",
      requestStates: "requests/requestStates",
      requestStatusName: "requests/requestStatusName",
      damageLocations: "requests/damageLocations",
      damageTypes: "requests/damageTypes",
      damageTypeName: "requests/damageTypeName",
      damageLocationName: "requests/damageLocationName",
    }),
    vin: {
      get: function () {
        return this.editRequest.car.vin?.toUpperCase();
      },
      set: function (newValue) {
        if (this.editRequest?.car) {
          this.editRequest.car.vin = newValue.toUpperCase();
        }
      },
    },
  },
  watch: {
    selectedRequest: {
      handler: function () {
        console.log("WATCH selectedRequest");
        this.getEditData();
      },
      deep: true,
    },
  },
  mounted() {
    console.log("REQUEST DETAILS MOUNTED");
    this.getEditData();
  },
};
</script>
