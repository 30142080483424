import axios from 'axios'

const state = {
  selectedRequest: null,
  lastSelectedRequest: null,
  audatexFiascoTasks: null,
  customerRequests: [],
  lastUpdate: null,
  requests: [],
  fixedRequests: [],
  requestStates: [],
  damageLocations: [
    {
      location: 'FENDER_F_R',
      type: '',
      displayName: 'Kotflügel vorne rechts'
    },
    {
      location: 'FENDER_F_L',
      type: '',
      displayName: 'Kotflügel vorne links'
    },
    {
      location: 'FENDER_B_R',
      type: '',
      displayName: 'Kotflügel hinten rechts'
    },
    {
      location: 'FENDER_B_L',
      type: '',
      displayName: 'Kotflügel hinten links'
    },
    {
      location: 'DOOR_F_R',
      type: '',
      displayName: 'Tür vorne rechts'
    },
    {
      location: 'DOOR_F_L',
      type: '',
      displayName: 'Tür vorne links'
    },
    {
      location: 'DOOR_B_R',
      type: '',
      displayName: 'Tür hinten rechts'
    },
    {
      location: 'DOOR_B_L',
      type: '',
      displayName: 'Tür hinten links'
    },
    {
      location: 'SIDE_B_R',
      type: '',
      displayName: 'Seitenwand hinten rechts'
    },
    {
      location: 'SIDE_B_L',
      type: '',
      displayName: 'Seitenwand hinten links'
    },
    {
      location: 'ENGINE_HOOD',
      type: '',
      displayName: 'Motorhaube'
    },
    {
      location: 'TAILGATE',
      type: '',
      displayName: 'Heckklappe'
    },
    {
      location: 'BUMPER_F',
      type: '',
      displayName: 'Stoßstange vorne'
    },
    {
      location: 'BUMPER_B',
      type: '',
      displayName: 'Stoßstange hinten'
    },
    {
      location: 'A_PILLAR_R',
      type: '',
      displayName: 'A-Säule rechts'
    },
    {
      location: 'A_PILLAR_L',
      type: '',
      displayName: 'A-Säule links'
    },
    {
      location: 'SILL_L',
      type: '',
      displayName: 'Einstieg links'
    },
    {
      location: 'SILL_R',
      type: '',
      displayName: 'Einstieg rechts'
    },
    {
      location: 'ROOF',
      type: '',
      displayName: 'Dach'
    },
    {
      location: 'Rim_F_R',
      type: '',
      displayName: 'Felge vorne rechts'
    },
    {
      location: 'RIM_F_L',
      type: '',
      displayName: 'Felge vorne links'
    },
    {
      location: 'RIM_B_R',
      type: '',
      displayName: 'Felge hinten rechts'
    },
    {
      location: 'RIM_B_L',
      type: '',
      displayName: 'Felge hinten links'
    },
    {
      location: 'FUEL_FILLER_CAP',
      type: '',
      displayName: 'Tankklappe'
    },
    {
      location: 'REAR_MIRROR_L',
      type: '',
      displayName: 'Spiegel links'
    },
    {
      location: 'REAR_MIRROR_R',
      type: '',
      displayName: 'Spiegel rechts'
    },
    {
      location: 'WINDSCHIELD',
      type: '',
      displayName: 'Windschutzscheibe'
    },
    {
      location: 'REAR_WINDOW',
      type: '',
      displayName: 'Heckscheibe'
    },
    {
      location: 'HEADLIGHT_R',
      type: '',
      displayName: 'Scheinwerfer rechts'
    },
    {
      location: 'HEADLIGHT_L',
      type: '',
      displayName: 'Scheinwerfer links'
    },
    {
      location: 'TAILLIGHT_R',
      type: '',
      displayName: 'Rücklicht rechts'
    },
    {
      location: 'TAILLIGHT_L',
      type: '',
      displayName: 'Rücklicht links'
    }
  ],
  damageTypes: [
    {
      type: 'PAINT',
      displayName: 'Lackschaden'
    },
    {
      type: 'DENT',
      displayName: 'Delle'
    },
    {
      type: 'PAINT_DENT',
      displayName: 'Lackschaden mit Delle'
    },
    {
      type: 'CRACK',
      displayName: 'Bruch oder Riss'
    }
  ]
}

// getters
const getters = {
  requests: state => state.requests,
  lastUpdate: state => state.lastUpdate,
  customerRequests: state => state.customerRequests,
  audatexFiascoTasks: state => state.audatexFiascoTasks,
  activeRequests: state => state.activeRequests,
  openRequests: state => state.requests.filter(o => o.status !== 'FIXED' && o.status !== 'INVOICE_SENT' & o.status !== 'INVOICE_PAID'),
  fixedAndReadyRequests: state => state.fixedRequests.filter(o => o.status === 'FIXED'),
  fixedRequests: state => state.fixedRequests,
  selectedRequest: state => state.selectedRequest,
  lastSelectedRequest: state => state.lastSelectedRequest,
  requestStates: state => state.requestStates,
  damageLocations: state => state.damageLocations,
  requestsByStatus: state => status => {
    return state.requests.filter(o => o.status === status && o.type === 'DIRECT')
  },
  poolRequestsByStatus: state => status => {
    return state.requests.filter(o => o.status === status && o.type === 'POOL')
  },
  directRequestsCalculations: state => {
    return state.requests.filter(o => o.status === 'QUALIFIED' || o.status === 'FIASCO_CALCULATION_REQUESTED' || o.status === 'FIASCO_CALCULATION_FEEDBACK' || o.status === 'FIASCO_CALCULATING' || o.status === 'FIASCO_CALCULATION_QUALIFIED' || o.status === 'CALCULATION_DONE' || o.status === 'FIASCO_CALCULATION_SENT' || o.status === 'OFFERED')
  },
  directRequestsFiascoCalculations: state => {
    return state.requests.filter(o => o.status === 'FIASCO_CALCULATION_REQUESTED' || o.status === 'FIASCO_CALCULATION_FEEDBACK' || o.status === 'FIASCO_CALCULATING' || o.status === 'FIASCO_CALCULATION_QUALIFIED')
  },
  directRequestsRepairs: state => {
    return state.requests.filter(o => (o.status === 'ACCEPTED' || o.status === 'REPAIR_REQUESTED' || o.status === 'INSPECTION_REQUESTED' || o.status === 'REPAIR_AGREED' || o.status === 'INSPECTION_AGREED' || o.status === 'INSPECTED' || o.status === 'IN_REPAIR' || o.status === 'FIXED') && o.type === 'DIRECT')
  },
  directRequestsFixedRepairs: state => {
    return state.fixedRequests.filter(o => o.type === 'DIRECT')
  },
  poolRequestsRepairs: state => {
    return state.requests.filter(o => (o.status === 'ACCEPTED' || o.status === 'INSPECTED' || o.status === 'IN_REPAIR' || o.status === 'FIXED') && o.type === 'POOL')
  },
  requestStatusName: state => status => state.requestStates.find(o => o.status === status).displayName,
  requestStatusActionName: state => status => state.requestStates.find(o => o.status === status).actionName,
  requestTargetRoute: state => status => state.requestStates.find(o => o.status === status).targetRoute,
  damageTypes: state => state.damageTypes,
  damageTypeName: state => damageType => damageType ? state.damageTypes.find(o => o.type === damageType).displayName : '',
  damageLocationName: state => damageLocation => damageLocation ? state.damageLocations.find(o => o.location === damageLocation).displayName : '',
  calculationOpen: () => request => {
    // todo: rework
    return request.status === 'QUALIFIED' ||
      request.status === 'FIASCO_CALCULATION_REQUESTED' ||
      request.status === 'FIASCO_CALCULATION_FEEDBACK' ||
      request.status === 'FIASCO_CALCULATING' ||
      request.status === 'FIASCO_CALCULATION_SENT'
  },
  hasCalculation: () => request => {
    return request.offer
    /*
    return request.status === 'QUALIFIED' ||
      request.status === 'FIASCO_CALCULATION_REQUESTED' ||
      request.status === 'FIASCO_CALCULATION_FEEDBACK' ||
      request.status === 'FIASCO_CALCULATING' ||
      request.status === 'CALCULATION_DONE' ||
      request.status === 'FIASCO_CALCULATION_SENT'
    */
  },
  readyForFiasco: () => request => {
    let ready = true
    if (!request.car.owner.firstName) ready = false
    if (!request.car.owner.lastName) ready = false
    if (!request.car.vin) ready = false
    return ready
  },
  menuLinkByRequest: (state, getters, rootState, rootGetters) => request => {
    if (request.status === 'REQUESTED') {
      return '/direkt-anfragen/eingang'
    }
    if (request.status === 'FEEDBACK') {
      return '/direkt-anfragen/rueckfragen'
    }
    if (request.status === 'QUALIFIED' || request.status === 'FIASCO_CALCULATION_REQUESTED' || request.status === 'FIASCO_CALCULATION_FEEDBACK' || request.status === 'FIASCO_CALCULATING' || request.status === 'FIASCO_CALCULATION_QUALIFIED' || request.status === 'CALCULATION_DONE' || request.status === 'FIASCO_CALCULATION_SENT' || request.status === 'OFFERED') {
      if (rootGetters['users/user'].isFiasco) {
        if (request.status === 'FIASCO_CALCULATION_REQUESTED' || request.status === 'FIASCO_CALCULATION_FEEDBACK' || request.status === 'FIASCO_CALCULATING' || request.status === 'FIASCO_CALCULATION_QUALIFIED') {
          return '/direkt-anfragen/fiasco-kalkulationen'
        }
      }
      return '/direkt-anfragen/angebote'
    }
    if (request.status === 'ACCEPTED' || request.status === 'REPAIR_REQUESTED' || request.status === 'INSPECTION_REQUESTED' || request.status === 'REPAIR_AGREED' || request.status === 'INSPECTION_AGREED' || request.status === 'INSPECTED' || request.status === 'IN_REPAIR' || request.status === 'FIXED') {
      return '/direkt-anfragen/reparaturen'
    }
    return '/'
  },
  nextSteps: () => status => {
    switch (status) {
      case 'REQUESTED':
        return ['FEEDBACK', 'QUALIFIED']
      case 'FEEDBACK':
        return ['REQUESTED', 'QUALIFIED']
      case 'QUALIFIED':
        return ['FIASCO_CALCULATION_REQUESTED', 'FIASCO_CALCULATION_FEEDBACK', 'FIASCO_CALCULATION_QUALIFIED', 'CALCULATION_DONE']
      case 'FIASCO_CALCULATION_REQUESTED':
        return ['FIASCO_CALCULATING', 'FIASCO_CALCULATION_QUALIFIED']
      case 'FIASCO_CALCULATION_FEEDBACK':
        return ['QUALIFIED', 'FIASCO_CALCULATING', 'FIASCO_CALCULATION_QUALIFIED', 'FIASCO_CALCULATION_SENT']
      case 'FIASCO_CALCULATING':
        return ['FIASCO_CALCULATION_FEEDBACK', 'FIASCO_CALCULATION_SENT']
      case 'FIASCO_CALCULATION_QUALIFIED':
        return ['FIASCO_CALCULATION_FEEDBACK', 'FIASCO_CALCULATING']
      case 'FIASCO_CALCULATION_SENT':
        return ['OFFERED']
      case 'CALCULATION_DONE':
        return ['OFFERED']
      case 'OFFERED':
        return ['ACCEPTED', 'DECLINED']
      case 'ACCEPTED':
        return ['INSPECTION_REQUESTED', 'INSPECTION_AGREED', 'REPAIR_REQUESTED', 'REPAIR_AGREED']
      case 'INSPECTION_REQUESTED':
        return ['INSPECTION_REQUESTED', 'INSPECTION_AGREED', 'INSPECTED']
      case 'INSPECTION_AGREED':
        return ['INSPECTION_AGREED', 'INSPECTED']
      case 'INSPECTED':
        return ['REPAIR_REQUESTED', 'REPAIR_AGREED']
      case 'REPAIR_REQUESTED':
        return ['REPAIR_AGREED', 'IN_REPAIR']
      case 'REPAIR_AGREED':
        return ['IN_REPAIR']
      case 'IN_REPAIR':
        return ['FIXED']
      case 'FIXED':
        return ['INVOICE_SENT', 'INVOICE_PAID']
      case 'INVOICE_SENT':
        return ['INVOICE_PAID']
    }
  }
}

// actions
const actions = {
  getLastUpdate(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/last-update', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_LAST_UPDATE', response.data[0].updatedAt))
      .catch((error) => console.log(error))
  },
  getRequestStates(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/request-states', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_REQUEST_STATES', response.data))
      .catch((error) => console.log(error))
  },
  getActive(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/active', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_ALL', response.data))
      .catch((error) => console.log(error))
  },
  getFixed(context) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/fixed', context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_FIXED_REQUESTS', response.data))
      .catch((error) => console.log(error))
  },
  getById(context, id) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/' + id, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_SELECTED_REQUEST', response.data))
      .catch((error) => console.log(error))
  },
  getByCustomer(context, id) {
    return axios.get(context.rootGetters.API.baseUrl + 'requests/customer/' + id, context.rootGetters.axiosConfig)
      .then((response) => context.commit('SET_CUSTOMER_REQUESTS', response.data))
      .catch((error) => console.log(error))
  },
  create(context, payload) {
    console.log('create request')
    return axios.put(context.rootGetters.API.baseURL + 'requests/', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  update(context, payload) {
    console.log('update request')
    delete payload.messages
    return axios.patch(context.rootGetters.API.baseURL + 'requests/' + payload._id, payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  delete(context, payload) {
    console.log('delete request')
    return axios.delete(context.rootGetters.API.baseURL + 'requests/' + payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  sendMessage(context, payload) {
    console.log('sendMessage request')
    return axios.put(context.rootGetters.API.baseURL + 'requests/' + payload.requestId + '/message', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response
      })
      .catch((error) => console.log(error))
  },
  deletePhoto(context, payload) {
    console.log('deletePhoto')
    return axios.patch(
      context.rootGetters.API.baseURL + 'requests/photo/delete',
      payload,
      context.rootGetters.axiosConfig
    ).then(response => {
      // success callback
      console.log(response)
      console.log('DELETED')
    }).catch(error => {
      console.log(error)
    })
  },
  getFiascoAudatexTasks(context) {
    console.log('getFiascoAudatexTasks')
    return axios.get(context.rootGetters.API.baseURL + 'requests/audatex/fiasco', context.rootGetters.axiosConfig)
      .then((response) => {
        let tasks = response.data.TaskProxyList.TaskProxy
        context.commit('SET_AUDATEX_FIASCO_TASKS', tasks)
        return tasks
      })
      .catch((error) => console.log(error))
  },
  getAudatexTaskById(context, payload) {
    console.log('getAudatexTaskById')
    let taskId = payload.taskId
    // taskId = '317D5211-DFE7-90BF-BD63-8AE583D01AC7'
    let axiosConfig = JSON.parse(JSON.stringify(context.rootGetters.axiosConfig))

    // TODO: Do not send credentials. Send the type of request (fiasco or station) instead.
    axiosConfig.headers.user = payload.user
    axiosConfig.headers.password = payload.password

    return axios.get(context.rootGetters.API.baseURL + 'requests/audatex/task/' + taskId, axiosConfig)
      .then((response) => {
        return response.data
      })
      .catch((error) => { console.log(error) })
  },
  audatexFiascoToStation(context, payload) {
    console.log('audatexFiascoToStation')
    return axios.post(context.rootGetters.API.baseURL + 'requests/audatex/fiasco-to-station', payload, context.rootGetters.axiosConfig)
      .then((response) => {
        return response.data
      })
      .catch((error) => console.log(error))
  },
  createAudatexTask(context, payload) {
    console.log('createAudatexTask')
    let axiosConfig = JSON.parse(JSON.stringify(context.rootGetters.axiosConfig))
    axiosConfig.headers.user = payload.user
    axiosConfig.headers.password = payload.password
    return axios.post(context.rootGetters.API.baseURL + 'requests/audatex/task', payload.task, axiosConfig)
      .then((response) => {
        return response.data
      })
      .catch((error) => console.log(error))
  },
}

// mutations
const mutations = {
  SET_ALL(state, data) {
    state.requests = data
  },
  SET_LAST_UPDATE(state, data) {
    state.lastUpdate = data
  },
  SET_REQUEST_STATES(state, data) {
    state.requestStates = data
  },
  SET_CUSTOMER_REQUESTS(state, data) {
    state.customerRequests = data
  },
  SET_ACTIVE_REQUESTS(state, data) {
    state.activeRequests = data
  },
  SET_FIXED_REQUESTS(state, data) {
    state.fixedRequests = data
  },
  SET_SELECTED_REQUEST(state, data) {
    state.selectedRequest = data
  },
  SET_LAST_SELECTED_REQUEST(state, data) {
    console.log('SET_LAST_SELECTED_REQUEST')
    state.lastSelectedRequest = data
  },
  SET_AUDATEX_FIASCO_TASKS(state, data) {
    state.audatexFiascoTasks = data
  },
  /*
  UPDATE_REQUEST (state, data) {
    state.requests = state.requests.filter(o => o.fiascoId !== data.fiascoId)
    state.requests.push(data)
    console.log('UPDATE_REQUEST')
    console.log(state.requests)
  }
  */
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
