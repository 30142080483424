<template>
  <div class="users main-content">
    <el-row :gutter="40">
      <el-col :span="18">
        <h2 class="page-title">Mitarbeiter</h2>
      </el-col>
      <el-col :span="6" class="page-header-tools">
        <div class="page-header-tools">
          <!--el-popover
            placement="left"
            :width="200"
            trigger="click"
            v-model="actionsMenuVisible"
          >
            <template #reference>
              <el-button icon="el-icon-more" circle type="primary"></el-button>
            </template>
            <div class="header-tools-profile-menu">
              <div class="header-tools-profile-item" @click="createDialogVisible = true">
                <span>Neuer Mitarbeiter</span>
              </div>
            </div>
          </el-popover-->
          <el-button
            icon="el-icon-plus"
            circle
            @click="createDialogVisible = true"
          ></el-button>
        </div>
      </el-col>
    </el-row>
    <el-table :data="users" @row-click="onRowClick">
      <el-table-column label="Bild" width="120">
        <template #default="scope">
          <el-avatar
            v-if="!scope.row.s3Image"
            :src="API.imageUrl + 'users/' + scope.row.image"
          ></el-avatar>
          <S3Avatar
            v-if="scope.row.s3Image"
            :image="scope.row.s3Image"
            :size="40"
          />
        </template>
      </el-table-column>
      <el-table-column prop="firstName" label="Vorname" width="180">
      </el-table-column>
      <el-table-column prop="lastName" label="Nachname" width="160">
      </el-table-column>
      <el-table-column prop="email" label="E-Mail"> </el-table-column>
      <el-table-column label="FIASCO-Team">
        <template #default="scope">
          <i class="el-icon-check" v-if="scope.row.isFiasco"></i>
        </template>
      </el-table-column>
      <el-table-column prop="station.name" label="Werkstatt"> </el-table-column>
      <el-table-column prop="role" label="Rolle" width="180"> </el-table-column>
      <el-table-column prop="id" label="Id" width="100"> </el-table-column>
    </el-table>

    <el-dialog
      title="Neuer Mitarbeiter"
      v-model="createDialogVisible"
      width="560px"
    >
      <el-form
        class="create-form"
        ref="form"
        :model="editUser"
        label-width="160px"
      >
        <el-form-item label="Vorname">
          <el-input v-model="editUser.firstName"></el-input>
        </el-form-item>
        <el-form-item label="Nachname">
          <el-input v-model="editUser.lastName"></el-input>
        </el-form-item>
        <el-form-item label="E-Mail">
          <el-input v-model="editUser.email"></el-input>
        </el-form-item>
        <el-form-item label="Passwort">
          <el-input type="password" v-model="editUser.password"></el-input>
        </el-form-item>
        <el-form-item label="FIASCO-Team" v-if="user.isFiasco">
          <el-checkbox v-model="editUser.isFiasco"></el-checkbox>
        </el-form-item>
        <el-form-item label="Werkstatt" v-if="user.isFiasco">
          <el-select
            v-model="editUser.station"
            class="wide"
            placeholder="Wählen"
          >
            <el-option label="keine Werkstatt" value="0"> </el-option>
            <el-option
              v-for="item in stations"
              :key="item._id"
              :label="item.name"
              :value="item._id"
            >
              <div>{{ item.name }}</div>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createDialogVisible = false">Abbrechen</el-button>
          <el-button type="primary" @click="createUser()">Erzeugen</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Users",
  data() {
    return {
      actionsMenuVisible: false,
      createDialogVisible: false,
      editUser: {
        firstName: "",
        lastName: "",
        email: "",
        station: "",
        password: "",
        isFiasco: false,
      },
    };
  },
  methods: {
    onRowClick(row, col) {
      console.log(row);
      console.log(col);
      this.$router.push({ name: "User", params: { id: row._id } });
    },
    createUser() {
      console.log("createUser");
      this.$store.commit("SET_BUSY", true);
      if (!this.user.isFiasco) {
        this.editUser.station = this.user.station;
      }
      this.$store.dispatch("users/create", this.editUser).then(() => {
        this.$store.commit("SET_BUSY", false);
        this.createDialogVisible = false;
        this.$store.dispatch("users/getAll");
      });
    },
  },
  computed: {
    ...mapGetters({
      API: "API",
      user: "users/user",
      users: "users/users",
      stations: "stations/stations",
    }),
  },
};
</script>
