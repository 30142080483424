<template>
  <div class="customer-password" v-if="editCustomer">

    <el-form class="details-form" ref="form" :model="editCustomer" label-position="top" label-width="60px">
      <el-alert
        v-if="passwordSaved"
        title="Passwort gespeichert!"
        type="success"
        show-icon>
      </el-alert>

      <el-form-item label="Neues Passwort">
        <el-input v-model="editPassword" show-password></el-input>
      </el-form-item>
      <el-form-item label="Passwort bestätigen">
        <el-input v-model="editPasswordCopy" show-password></el-input>
      </el-form-item>

      <el-button @click="updatePassword()" :disabled="!saveAvailable">Speichern</el-button>

    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerPassword',
  data() {
    return {
      editCustomer: null,
      passwordSaved: false,
      editPassword: '',
      editPasswordCopy: ''
    }
  },
  methods: {
    updatePassword () {
      let payload = {
        _id: this.editCustomer._id,
        password: this.editPassword
      }
      this.passwordSaved = false
      this.$store.dispatch('customers/updatePassword', payload).then(() => {
        this.passwordSaved = true
        this.editPassword = ''
        this.editPasswordCopy = ''
      })
    },
    getEditData () {
      console.log('getEditData')
      this.editCustomer = JSON.parse(JSON.stringify(this.customers.find(o => o._id === this.$route.params.id)))
    }
  },
  computed: {
    saveAvailable () {
      return this.editPassword.length >= 3 && this.editPassword === this.editPasswordCopy 
    },
    ...mapGetters({
      stations: 'stations/stations',
      selectedUser: 'users/selectedUser',
      customers: 'customers/customers',
      userRights: 'users/rights',
      userRightsByGroup: 'users/rightsByGroup',
    }),
  },
  mounted () {
    console.log('CUSTOMER PASSWORD MOUNTED')
    this.getEditData()
  },
}
</script>
